import styles from "./staffSalaryForm.module.scss";
import Select from "shared/ui/Inputs/Select";
import { Formik } from "formik";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import NumberFormat from "react-number-format";
import { Input } from "shared/ui/Inputs/Input";
import validation from "./validation";
import FormWindow from "shared/ui/FormWindow";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import Textarea from "shared/ui/Inputs/Textarea";
import { useStores } from "stores";
import { useParams } from "react-router-dom";
import ErrorMessage from "shared/ui/ErrorMessage";
import { Errors } from "stores/utils/types/ErrorsType";
import { OptionWithTitle } from "stores/utils/types/OptionWithTitle";

// хардкод
const cols = {
  date: { title: "Дата" },
  date_start: { title: "Дата начала" },
  status: { title: "Статус" },
  building: { title: "Объект" },
  type: { title: "Тип операции" },
  money: { title: "Сумма" },
  comment: { title: "Комментарий" },
  ws_num: { title: "Количество смен" },
  initiator: { title: "Инициатор" },
  uid: { title: "Сотрудник" },
  property: { title: "Причина" }
};

const requiredCols = [
  "building",
  "type",
  "money",
  "ws_num",
  "date",
  "date_start",
  "uid"
];

type StaffSalaryFormProps = {
  setIsOpenModal: () => void;
  clearErrorsMessage?: () => void;
  saveBtnOnClickFunc?: (
    values: {
      [key: string]: string | number;
    },
    uid?: string
  ) => void;
  fields: string[];
  disabledFields?: string[];
  dataForEdit?: { [key: string]: string | number };
  title: string;
  nameOneOfStaff?: string;
  type: "add" | "edit";
  isHoldingEdu?: boolean;
  staffID?: string;
  errorsMessage?: Errors["message"];
  successMessage?: Errors["message"];
  ws_num?: number;
  money?: number;
};

const StaffSalaryForm = ({
  setIsOpenModal,
  clearErrorsMessage,
  fields,
  disabledFields,
  title,
  nameOneOfStaff,
  type,
  dataForEdit,
  isHoldingEdu,
  saveBtnOnClickFunc,
  staffID,
  errorsMessage,
  successMessage,
  ws_num,
  money
}: StaffSalaryFormProps) => {
  const { staffRetentionListStore, staffOneSalaryStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");

  // стейт показывает была ли нажата кнопка "Сохранить"
  // нужен для отображения ошибки при добавлении/редактировании
  const [isSaveBtnPressed, setIsSaveBtnPressed] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    clearErrorsMessage();
    !Object.keys(staffRetentionListStore.statusList).length &&
      staffRetentionListStore.getStatusList();
    staffRetentionListStore.setSearchValueStaff("");
    staffRetentionListStore.getStaffList();
  }, []);

  useEffect(() => {
    if (
      staffRetentionListStore.pageStaff !== 1 &&
      staffRetentionListStore.pageStaff <=
        staffRetentionListStore.maxPageStaff &&
      staffRetentionListStore.pageStaff !==
        staffRetentionListStore.prevPageStaff
    ) {
      staffRetentionListStore.getMoreStaff();
    }
  }, [staffRetentionListStore.pageStaff, staffRetentionListStore.maxPageStaff]);

  // эту строчку пока оставить, без нее список сотрудников неправильно отображается
  // баг связанный с mobx, исправить в будущем
  staffRetentionListStore.isLoadingForStaffList;

  useEffect(() => {
    if (
      (isSaveBtnPressed &&
        errorsMessage &&
        !Object.values(errorsMessage).length) ||
      (isSaveBtnPressed && !errorsMessage)
    ) {
      successMessage
        ? setTimeout(() => setIsOpenModal(), 1000)
        : setIsOpenModal();
    }
  }, [errorsMessage]);

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };
  const getInitialValuesForAdd = () => {
    const values: { [key: string]: string | number } = {};
    fields.forEach((key) => {
      switch (key) {
        case "type":
          values[key] = staffOneSalaryStore.selectedOperation;
          break;
        case "date":
        case "date_start":
          values[key] = format(new Date(), "yyyy-MM-dd");
          break;
        case "ws_num":
          values[key] = isHoldingEdu ? ws_num : null;
          break;
        case "money":
          values[key] = isHoldingEdu ? money : null;
          break;
        default:
          values[key] = null;
      }
    });
    if (
      staffOneSalaryStore.selectedOperation &&
      staffOneSalaryStore.operationProperty[
        staffOneSalaryStore.selectedOperation
      ]
    ) {
      values["property"] = null;
    }
    return values;
  };

  const getInitialValuesForEdit = () => {
    const values: { [key: string]: string | number } = {};
    fields.forEach((key) => {
      switch (key) {
        case "date_start":
          values[key] = (dataForEdit[key] as string)
            .split(".")
            .reverse()
            .join("-");
          break;
        default:
          values[key] = dataForEdit[key];
      }
    });
    return values;
  };

  if (
    (type === "add" && fields.length) ||
    (type === "edit" &&
      dataForEdit &&
      Object.keys(dataForEdit).length &&
      fields.length)
  ) {
    return (
      <div className={styles.background}>
        <div className={styles.modalWindow}>
          <Formik
            initialValues={
              type === "add"
                ? getInitialValuesForAdd()
                : getInitialValuesForEdit()
            }
            enableReinitialize
            onSubmit={() => {
              return;
            }}
            validateOnMount
            validateOnBlur
            validateOnChange
            validationSchema={validation(fields, requiredCols)}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              isValid,
              dirty
            }) => {
              const handleGetBuildingForStaff = (date: string) => {
                if ("building" in values) {
                  staffOneSalaryStore.getBuildingForStaff(id, date);
                  setFieldValue("building", null);
                }
              };
              return (
                <>
                  <FormWindow
                    title={title}
                    subtitle={nameOneOfStaff}
                    setOpenWindow={() => {
                      setIsOpenModal();
                      clearErrorsMessage();
                    }}
                    saveBtnTitle={type === "add" ? "Добавить" : "Сохранить"}
                    saveBtnOnClickFunc={() => {
                      saveBtnOnClickFunc(
                        values,
                        type === "add"
                          ? id ?? staffID
                          : (dataForEdit["id"] as string)
                      );
                      setIsSaveBtnPressed(true);
                    }}
                    saveBtnDisabledValue={
                      !isValid || (type === "edit" ? !dirty : false)
                    }
                    addBtnTitle="Отмена"
                    addBtnImg={<IconClose />}
                    errors={
                      errorsMessage &&
                      Object.values(errorsMessage).length && [errorsMessage]
                    }
                    isScroll
                  >
                    <div className={styles.inputs}>
                      {Object.keys(values).map((field) => {
                        const disabled =
                          disabledFields && disabledFields.includes(field);
                        const handleChangeOption = (
                          option: OptionWithTitle
                        ) => {
                          field === "type" &&
                            staffOneSalaryStore.setSelectedOperation(
                              option.id as string
                            );
                        };
                        switch (field) {
                          case "date":
                          case "date_start":
                            return (
                              <React.Fragment key={field}>
                                <DatePickerField
                                  name={field}
                                  title={cols[field]["title"]}
                                  isCalendarOpened={openedListName === field}
                                  setIsCalendarOpened={() => {
                                    changeOpenedListName(field);
                                  }}
                                  disabled={disabled}
                                  required={requiredCols.includes(field)}
                                  onChange={handleGetBuildingForStaff}
                                />
                              </React.Fragment>
                            );

                          case "building":
                            return (
                              <React.Fragment key={field}>
                                <Select
                                  name={field}
                                  isFloating
                                  title={cols[field]["title"]}
                                  options={staffOneSalaryStore.buildingForStaff}
                                  valueName="id"
                                  required={requiredCols.includes(field)}
                                  disabled={disabled}
                                />
                              </React.Fragment>
                            );

                          case "type":
                          case "property":
                            return (
                              <React.Fragment key={field}>
                                <Select
                                  name={field}
                                  isFloating
                                  title={cols[field]["title"]}
                                  options={
                                    field === "type"
                                      ? staffOneSalaryStore
                                          .availableOperationTypes[id]
                                      : staffOneSalaryStore.operationProperty[
                                          staffOneSalaryStore.selectedOperation
                                        ]
                                  }
                                  valueName="id"
                                  required={requiredCols.includes(field)}
                                  disabled={disabled}
                                  onClick={handleChangeOption}
                                />
                              </React.Fragment>
                            );

                          case "ws_num":
                            return (
                              <React.Fragment key={field}>
                                <NumberFormat
                                  name={field}
                                  value={values[field]}
                                  label={cols[field]["title"]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  customInput={Input}
                                  disabled={disabled}
                                  allowNegative={false}
                                  required={requiredCols.includes(field)}
                                />
                              </React.Fragment>
                            );
                          case "money":
                            return (
                              <div className={styles.sumField} key={field}>
                                <NumberFormat
                                  name={field}
                                  value={values[field]}
                                  label={cols[field]["title"]}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  customInput={Input}
                                  disabled={disabled}
                                  thousandSeparator=" "
                                  decimalSeparator=","
                                  allowedDecimalSeparators={["."]}
                                  decimalScale={2}
                                  fixedDecimalScale
                                  allowNegative={false}
                                  required={requiredCols.includes(field)}
                                />
                                {isHoldingEdu ? (
                                  <p>{`Стоимость одной смены: ${
                                    money / ws_num
                                  }`}</p>
                                ) : (
                                  ""
                                )}
                              </div>
                            );

                          case "comment":
                            return (
                              <React.Fragment key={field}>
                                <Textarea
                                  name={field}
                                  value={(values[field] as string) || ""}
                                  label={cols[field]["title"]}
                                  disabled={disabled}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  placeholder={cols[field]["title"]}
                                  commentCol={cols[field]}
                                />
                              </React.Fragment>
                            );

                          case "initiator":
                          case "uid":
                            return (
                              <React.Fragment key={field}>
                                <Select
                                  name={field}
                                  isFloating
                                  title={cols[field]["title"]}
                                  options={staffRetentionListStore.staffList}
                                  isSearchable
                                  isSearchWithPagination
                                  page={staffRetentionListStore.pageStaff}
                                  prevPage={
                                    staffRetentionListStore.prevPageStaff
                                  }
                                  maxPage={staffRetentionListStore.maxPageStaff}
                                  setPage={staffRetentionListStore.setPageStaff}
                                  getList={staffRetentionListStore.getStaffList}
                                  setSearchValue={
                                    staffRetentionListStore.setSearchValueStaff
                                  }
                                  searchValue={
                                    staffRetentionListStore.searchValueStaff
                                  }
                                  isLoading={
                                    staffRetentionListStore.isLoadingForStaffList
                                  }
                                  valueName="id"
                                  disabled={disabled}
                                  required={requiredCols.includes(field)}
                                />
                              </React.Fragment>
                            );
                          case "status":
                            return;
                          default:
                            return (
                              <React.Fragment key={field}>
                                <Input
                                  name={field}
                                  label={cols[field]["title"]}
                                  onChange={(event) => {
                                    handleChange(event);
                                  }}
                                  required={requiredCols.includes(field)}
                                  disabled={disabled}
                                />
                              </React.Fragment>
                            );
                        }
                      })}
                      {successMessage ? (
                        <ErrorMessage errors={successMessage} />
                      ) : null}
                    </div>
                  </FormWindow>
                </>
              );
            }}
          </Formik>
        </div>
      </div>
    );
  } else return;
};

export default observer(StaffSalaryForm);
