import styles from "./staffOneSalaryHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import { fileUrl } from "stores/utils/consts";
import Avatar from "shared/assets/images/mainIcons/iconAvatar/Avatar.svg";
import iconExpand from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";
import { RefObject, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import StatusIcon from "shared/ui/StatusIcon";
import StaffSalaryForm from "features/StaffSalaryForm";
import { format } from "date-fns";
import { Button, ButtonTheme } from "shared/ui/Button";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneSalaryHeaderProps = {
  componentRef: RefObject<null>;
};

const FIELDS_LIST = [
  "date",
  "building",
  "type",
  "initiator",
  "money",
  "comment"
];

const StaffOneSalaryHeader = ({ componentRef }: StaffOneSalaryHeaderProps) => {
  const { staffOneStore, staffOneSalaryStore } = useStores();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    staffOneSalaryStore.setNameOneOfStaff(
      id,
      `${staffOneStore.info["surname"]} ${staffOneStore.info["name"]}
    ${staffOneStore.info["patronymic"]}`
    );
  }, [staffOneStore.info["surname"]]);

  useEffect(() => {
    staffOneSalaryStore.setIsOpenModal(false);
  }, [id]);

  const openWindowForm = () => {
    staffOneSalaryStore.getBuildingForStaff(
      id,
      format(new Date(), "yyyy-MM-dd")
    );
    staffOneSalaryStore.getOperationTypesForUid(id);
    setIsMenuOpened(!isMenuOpened);
  };

  return (
    <div className={styles.headerBlock}>
      <div className={styles.staffInfo}>
        <img
          src={
            staffOneStore.info["photo"]?.["src"]
              ? `${fileUrl}${staffOneStore.info["photo"]["src"]}?${Math.floor(
                  Math.random() * 100000
                )}`
              : Avatar
          }
        />
        <div className={styles.fio}>
          <p>
            {staffOneStore.info["surname"]} {staffOneStore.info["name"]}{" "}
            {staffOneStore.info["patronymic"]}
          </p>
          <div className={styles.TNPrintBlock}>
            <div className={styles.TNBlock}>
              <p className={styles.TNBlockTitle}>ТН</p>
              <p className={styles.TNBlockValue}>{staffOneStore.info["uid"]}</p>
            </div>
            <ReactToPrint
              trigger={() => (
                <div className={styles.printBtn}>
                  <StatusIcon icon="iconprinter" color="bw-gray5" />
                  <p>Распечатать</p>
                </div>
              )}
              content={() => componentRef.current}
            />
          </div>
        </div>
      </div>
      <div className="position-relative">
        <Button
          className={styles.tab}
          onClick={openWindowForm}
          id="StaffOneSalaryHeader_openWindow"
          theme={ButtonTheme.SECONDARY}
        >
          <img
            src={iconExpand}
            className={classNames("", { [styles.transformed]: isMenuOpened })}
          />
          Добавить операцию
        </Button>
        {isMenuOpened ? (
          <StaffSalaryForm
            title="Добавление операции"
            setIsOpenModal={() => setIsMenuOpened(!isMenuOpened)}
            clearErrorsMessage={() =>
              staffOneSalaryStore.clearErrorsMessage(id)
            }
            saveBtnOnClickFunc={staffOneSalaryStore.addOperation}
            fields={FIELDS_LIST}
            nameOneOfStaff={staffOneSalaryStore.nameOneOfStaff[id]}
            type="add"
            errorsMessage={staffOneSalaryStore.errorsMessage[id]}
            successMessage={staffOneSalaryStore.successMessage[id]}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default observer(StaffOneSalaryHeader);
