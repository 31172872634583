import styles from "./buildingOneTimesheetHeader.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";

import { Formik } from "formik";
import DatePickerField from "shared/ui/Inputs/DatePickerField";

import { getYear } from "date-fns";
import { Chips, ChipsVariation } from "shared/ui/Chips";

const staffFilter = {
  all: { id: "all", title: "Все" },
  itr: { id: "itr", title: "Итр" },
  non_itr: { id: "non_itr", title: "не ИТР" }
};

const BuildingOneTimesheetHeader = () => {
  const { id } = useParams();
  const { buildingOneTimesheetStore } = useStores();
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const filterParams: { [key: string]: string } = {};

    for (const [key, value] of searchParams.entries()) {
      filterParams[key] =
        key === "month"
          ? `${buildingOneTimesheetStore.month[id]}`
          : key === "year"
          ? `${buildingOneTimesheetStore.year[id]}`
          : value;
    }

    setSearchParams(filterParams);
  }, [buildingOneTimesheetStore.month[id]]);

  return (
    <div className={styles.buttons}>
      {buildingOneTimesheetStore.selectedMode[id] === "timesheet" ? (
        <div className={styles.buttons_group}>
          <p>Сотрудники</p>
          <div>
            {Object.entries(staffFilter).map(([key, value]) => {
              const handleSelectedStaffFilter = () =>
                buildingOneTimesheetStore.setSelectedStaffFilter(value.id);
              return (
                <Chips
                  variation={ChipsVariation.FILTER}
                  id={`BuildingOneTimesheetHeader_setSelectedStaffFilter_${value.id}`}
                  key={`staffFilter_${key}`}
                  className={
                    buildingOneTimesheetStore.selectedStaffFilter === value.id
                      ? styles.selectedGrayBtn
                      : styles.grayBtn
                  }
                  onClick={handleSelectedStaffFilter}
                  title={value.title}
                />
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={styles.buttons_group}>
        <p>Год</p>
        <Formik
          initialValues={{ year: buildingOneTimesheetStore.year[id] }}
          onSubmit={() => {
            return;
          }}
        >
          {() => {
            return (
              <div>
                <DatePickerField
                  name="year"
                  isCalendarOpened={isCalendarOpened}
                  setIsCalendarOpened={() =>
                    setIsCalendarOpened(!isCalendarOpened)
                  }
                  dateFormat="yyyy"
                  title=""
                  isButton
                  minDate={
                    new Date(
                      Object.values(
                        buildingOneTimesheetStore.allYearsAndMonth[id].years
                      ).sort((a, b) => (a > b ? 1 : -1))[0] - 1,
                      1
                    )
                  }
                  maxDate={
                    new Date(
                      Object.values(
                        buildingOneTimesheetStore.allYearsAndMonth[id].years
                      ).sort((a, b) => (a > b ? -1 : 1))[0],
                      1
                    )
                  }
                  onChange={(value) => {
                    const filterParams: { [key: string]: string } = {};

                    buildingOneTimesheetStore.setSearchValue(id, "");

                    for (const [key, value] of searchParams.entries()) {
                      filterParams[key] =
                        key === "year" ? `${getYear(new Date(value))}` : value;
                    }

                    setSearchParams(filterParams);
                    buildingOneTimesheetStore.setSelectedYear(
                      getYear(new Date(value)),
                      id
                    );
                  }}
                />
              </div>
            );
          }}
        </Formik>
      </div>

      <div className={styles.buttons_group}>
        <p>Месяц</p>
        <div>
          {Object.entries(buildingOneTimesheetStore.months).map(
            ([month, title]) => {
              const handleSelectedMonth = () => {
                buildingOneTimesheetStore.setSearchValue(id, "");
                buildingOneTimesheetStore.setSelectedMonth(+month, id);
              };
              return (
                <Chips
                  variation={ChipsVariation.FILTER}
                  id={`BuildingOneTimesheetHeader_setSelectedMonth_${title}`}
                  key={`months_${month}`}
                  className={
                    buildingOneTimesheetStore.month[id] === +month
                      ? styles.selectedGrayBtn
                      : styles.grayBtn
                  }
                  onClick={handleSelectedMonth}
                  disabled={
                    buildingOneTimesheetStore.allYearsAndMonth[id].months[
                      buildingOneTimesheetStore.year[id]
                    ] &&
                    !Object.values(
                      buildingOneTimesheetStore.allYearsAndMonth[id].months[
                        buildingOneTimesheetStore.year[id]
                      ]
                    ).includes(+month)
                  }
                  title={title}
                />
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default observer(BuildingOneTimesheetHeader);
