import styles from "./staffOneEditFormPhone.module.scss";
import { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import addIcon from "shared/assets/images/mainIcons/iconAdd/iconAdd.svg";

import { Input } from "shared/ui/Inputs/Input";
import { PhoneInput } from "shared/ui/Inputs/PhoneInput";
import Checkbox from "shared/ui/Inputs/Checkbox";
import ButtonsGroupForEdit from "shared/ui/ButtonsGroup/ButtonsGroupForEdit";
import ClarificationModal from "shared/ui/Modals/ClarificationModal/index";
import { ViewField } from "shared/ui/ViewField";
import { useParams } from "react-router-dom";
import { diff } from "deep-object-diff";
import { getValues } from "shared/utils/helpers/getValues";
import { getEntries } from "shared/utils/helpers/getEntries";
import { IRecordsForEdit } from "stores/StaffModule/types/RecordsForEdit";

type PhoneType = {
  number?: number | string | null;
  comment?: string | null;
  confirm?: number | null;
};

type InfoType = { action?: string; idx?: number; prev_idx?: number | string };

type StaffOneEditFormPhoneProps = {
  disabled: boolean;
};

const StaffOneEditFormPhone = ({ disabled }: StaffOneEditFormPhoneProps) => {
  const { staffOneEditStore } = useStores();
  const { id } = useParams();

  const [selectedPhone, setSelectedPhone] = useState<string | number>(null);
  const [newPhoneIndex, setNewPhoneIndex] = useState(0);
  const [isAddPhone, setIsAddPhone] = useState(false);

  const [isEmpty, setEmpty] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [info, setInfo] = useState<InfoType>({});

  const {
    setFieldValue,
    handleChange,
    handleBlur,
    setFieldTouched,
    errors,
    dirty,
    values,
    initialValues
  } = useFormikContext<IRecordsForEdit>();

  useEffect(() => {
    // при handleReset() обнуляются значения в полях, но не меняется состояние формы добавления нового номера
    // поэтому если нет изменений в форме и состояние формы добавления номера "открыта"
    if (!dirty && isAddPhone) {
      // изменяем состояние формы на "закрыто" и кнопка "Добавить +" будет отображаться
      setIsAddPhone(false);
    }
  }, [dirty]);

  useEffect(() => {
    setTimeout(() => {
      setFieldTouched(`phone.${selectedPhone}.number`, isAddPhone);
    }, 200);
  }, [isAddPhone]);

  const newContact = (action: string) => {
    const array: PhoneType[] = getValues(values["phone"]);

    switch (action) {
      case "addPhone":
        {
          array.push({
            number: "",
            comment: "",
            confirm: 0
          });
          setNewPhoneIndex(array.length - 1);
          setSelectedPhone(array.length - 1);
          setEmpty(false);
        }
        break;
      case "cancelAddPhone":
        {
          array.pop();
        }
        break;
      default:
        break;
    }

    setFieldValue("phone", array);
  };

  const editPhone = ({ action, idx }: InfoType) => {
    setSelectedPhone(null);
    const phones: PhoneType[] = getValues(values["phone"]);
    const changes = getEntries(
      diff(initialValues["phone"]?.[idx] || {}, phones[idx])
    );

    switch (action) {
      case "add":
        setIsAddPhone(true);
        newContact("addPhone");
        break;
      case "submit":
        if (changes?.length > 1) {
          staffOneEditStore.editPhone(
            "phone",
            id,
            idx,
            "number",
            phones[idx]?.number,
            phones[idx]?.comment
          );
        } else if (changes?.length === 1) {
          staffOneEditStore.editPhone(
            "phone",
            id,
            idx,
            changes[0]?.[0],
            changes[0]?.[1]
          );
        }
        break;
      case "edit":
        setFieldValue("phone", initialValues["phone"]);
        setSelectedPhone(idx);
        break;
      case "delete":
        if (isAddPhone) {
          phones.pop();
        }
        staffOneEditStore.deletePhone(id, idx);
        break;
      case "cancel":
        setFieldValue("phone", initialValues["phone"]);
        break;
      default:
        break;
    }
  };

  const addPhone = (...data: Array<string | number>) => {
    const [action] = data;
    const phones: PhoneType[] = getValues(values["phone"]);
    const newPhone = phones[phones.length - 1];

    if (action === "submit") {
      setSelectedPhone(null);
      staffOneEditStore.addPhone(id, newPhone.number, newPhone.comment);
    } else {
      newContact("cancelAddPhone");
    }
    setIsAddPhone(false);
  };

  return !staffOneEditStore.isLoadingPhone ? (
    <div className={`${styles.form} ${disabled ? styles.disabled : ""}`}>
      <>
        <div className={styles.subtitle}>Телефон</div>
        {
          <div className={styles.containerForm}>
            {Object.values(values["phone"]).map((contact, i) => {
              const order = i + 1;

              return (
                <ul
                  key={`contact_${i}`}
                  className={
                    isAddPhone && i === newPhoneIndex
                      ? `${styles.confirmRowAddPhone} ${styles.confirmRowAddPhone_selected}`
                      : selectedPhone === i
                      ? `${styles.confirmRowPhone} ${styles.confirmRowPhone_selected}`
                      : styles.confirmRowPhone
                  }
                >
                  {(isAddPhone && i === newPhoneIndex) || isEmpty ? null : (
                    <li>
                      <label
                        htmlFor={`phone[${i}].confirm`}
                        className={styles.confirmLabel}
                      >
                        Подтвержд.
                      </label>
                      <Checkbox name={`phone[${i}].confirm`} disabled />
                    </li>
                  )}
                  <li>
                    <ViewField
                      title="№"
                      value={contact["order"] || order}
                      className={{ container: styles.numBorder }}
                    />
                  </li>
                  <li>
                    <PhoneInput
                      name={`phone[${i}][number]`}
                      path={+i}
                      label={staffOneEditStore.staffTableCols["phone"]["title"]}
                      notEditable={selectedPhone !== i}
                      required={selectedPhone === i}
                    />
                  </li>
                  <li>
                    <Input
                      name={`phone.${i}.comment`}
                      label={
                        staffOneEditStore.staffTableCols["comment"]["title"]
                      }
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      disabled={selectedPhone !== i}
                    />
                  </li>
                  <li>
                    {(isAddPhone && newPhoneIndex === i) || isEmpty ? (
                      <ButtonsGroupForEdit
                        id={`contact_${i}`}
                        idx={i}
                        btns={[
                          {
                            action: "submit",
                            type: "button",
                            icon: "bigcheck",
                            color:
                              errors.phone?.[i] || !dirty
                                ? "bw-gray3"
                                : "blue-lazure",
                            disabled: Boolean(errors.phone?.[i]) || !dirty
                          },
                          {
                            action: "cancel",
                            type: "button",
                            icon: "iconclose",
                            color: "blue-lazure",
                            disabled: false
                          }
                        ]}
                        onClick={addPhone}
                      />
                    ) : (
                      <ButtonsGroupForEdit
                        id={`contact_${i}`}
                        idx={i}
                        btns={
                          selectedPhone === i
                            ? [
                                {
                                  action: "submit",
                                  type: "button",
                                  icon: "bigcheck",
                                  color:
                                    errors["phone"]?.[i] || !dirty
                                      ? "bw-gray3"
                                      : "blue-lazure",
                                  disabled:
                                    Boolean(errors["phone"]?.[i]) || !dirty
                                },
                                {
                                  action: "cancel",
                                  type: "button",
                                  icon: "iconclose",
                                  color: "blue-lazure",
                                  disabled: false
                                },
                                {
                                  action: "delete",
                                  type: "button",
                                  icon: "iconbasket",
                                  color: "bw-gray5",
                                  disabled: true
                                }
                              ]
                            : [
                                {
                                  action: "edit",
                                  type: "button",
                                  icon: "iconedit",
                                  color: "bw-gray5",
                                  disabled: false
                                },
                                {
                                  action: "delete",
                                  type: "button",
                                  icon: "iconbasket",
                                  color: "bw-gray5",
                                  disabled: false
                                }
                              ]
                        }
                        onClick={(action, idx) => {
                          if (
                            isAddPhone &&
                            (action == "edit" || action == "delete")
                          ) {
                            setInfo({
                              action,
                              idx,
                              prev_idx: selectedPhone
                            });
                            setShowDeleteModal(true);
                          } else
                            editPhone({
                              action,
                              idx,
                              prev_idx: selectedPhone
                            });
                        }}
                      />
                    )}
                  </li>
                </ul>
              );
            })}
            {!isAddPhone && !isEmpty ? (
              <button
                id="StaffOneEditContacts_addPhoneFormButton"
                type="button"
                className={styles.buttonAddNumber}
                onClick={() => {
                  setIsAddPhone(true);
                  newContact("addPhone");
                }}
              >
                <p className={styles.buttonAddNumber__text}>Добавить</p>
                <img src={addIcon} className={styles.buttonAddNumber__icon} />
              </button>
            ) : (
              ""
            )}
          </div>
        }
      </>

      <ClarificationModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
        }}
        title="Закрыть окно без сохранения данных?"
        btnWithCrossTitle="Закрыть"
        btnWithCrossOnClick={() => {
          setShowDeleteModal(false);

          if (info.prev_idx !== null) {
            newContact("cancelAddPhone");
            setIsAddPhone(false);
          }

          if (
            info.action == "edit" ||
            info.action == "add" ||
            info.action == "delete"
          ) {
            if (info.idx !== null) {
              editPhone(info);
            }
          }
        }}
        btnBlueOnClick={() => {
          setShowDeleteModal(false);
        }}
        btnBlueTitle="Отмена"
        btnWithCrossBtnImg
      />
    </div>
  ) : (
    <p className={styles.loading}>Пожалуйста, подождите...</p>
  );
};

export default observer(StaffOneEditFormPhone);
