import { classNames } from "shared/utils/helpers/classNames";
import styles from "./viewField.module.scss";
import StatusIcon from "../StatusIcon";

export enum ViewFieldTheme {
  DEFAULT = "default"
  //   TODO: доработать поле просмотра для таблиц
  // TABLE = "table"
}

export enum ViewFieldIcon {
  //   TODO: доработать поле просмотра для других вариантов - select, date и т.д.
  NONE = "",
  EDIT = "iconedit",
  SELECT = "iconExpand"
}

type ViewFieldProps = {
  /**
   * Заголовок поля
   */
  title: string;
  /**
   * Значение поля
   */
  value: string | number;
  /**
   * Тема из enum ViewFieldTheme, по умолчанию ViewFieldTheme.DEFAULT
   */
  theme?: ViewFieldTheme;
  /**
   * Иконка из enum ViewFieldIcon, по умолчанию ViewFieldTheme.NONE
   */
  icon?: ViewFieldIcon;
  /**
   * Является ли поле обязательным
   */
  required?: boolean;
  /**
   * Является ли поле заблокированным
   */
  disabled?: boolean;
  /**
   * Стили,которые задаются из родителя
   */
  className?: Partial<{
    container: string;
    title: string;
    value: string;
  }>;
};

export const ViewField = ({
  title,
  value,
  theme = ViewFieldTheme.DEFAULT,
  icon = ViewFieldIcon.NONE,
  disabled,
  required,
  className
}: ViewFieldProps) => {
  return (
    <div
      className={classNames(
        styles[`container-${theme}`],
        { [styles.disabled]: disabled },
        [className?.container || ""]
      )}
    >
      <div
        className={classNames(
          styles[`title-${theme}`],
          {
            [styles[`title-${theme}-withData`]]:
              value && String(value)?.length > 0,
            [styles[`required-${theme}`]]: required
          },
          [className?.title || ""]
        )}
      >
        {title}
      </div>
      {value && String(value)?.length > 0 && (
        <div
          className={classNames(styles[`value-${theme}`], {}, [
            className?.value || ""
          ])}
        >
          {value}
        </div>
      )}
      {icon.length > 0 && (
        <StatusIcon icon={icon} color="bw-gray6" className={styles[icon]} />
      )}
    </div>
  );
};
