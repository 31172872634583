import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";

import { getYear, getMonth, getDaysInMonth, getDate, parse } from "date-fns";

import { OneOfLegend } from "stores/BuildingModule/types/OneOfLegend";
import { TimesheetSelects } from "../types/TimesheetSelects";
import { YearsAndMonths } from "../types/YearsAndMonths";
import { Tabel } from "../types/Tabel";
import { TabelChart } from "../types/TabelChart";

import { dateFormats } from "stores/utils/consts";
import { ApiResponse } from "stores/utils/types/ApiResponse";

type TabelChartArray = {
  name: number;
  day: number;
  hours: number;
  staff: number;
  date: string;
}[];

export default class BuildingOneTimesheetStore {
  error = false;
  isLoading = false;
  isLoadingForTabel = false;
  isLoadingForChart = false;

  legend: OneOfLegend[] = [];
  selects: Partial<TimesheetSelects> = {};
  allYearsAndMonth: { [building_id: string]: YearsAndMonths } = {};
  allOpenedTabels: { [building_id: string]: Tabel } = {};
  allOpenedTabelCharts: {
    [building_id: string]: { [month: string]: TabelChartArray };
  } = {};

  searchValue: { [building_id: string]: string } = {};

  year: { [key: string]: number } = {};
  month: { [key: string]: number } = {};

  openedWindow = "";
  selectedDate = "";
  selectedStaffFilter = "all";
  selectedMode: { [building_id: string]: string } = {};

  disabledConditions: string[] = [];

  months = {
    1: "Январь",
    2: "Февраль",
    3: "Март",
    4: "Апрель",
    5: "Май",
    6: "Июнь",
    7: "Июль",
    8: "Август",
    9: "Сентябрь",
    10: "Октябрь",
    11: "Ноябрь",
    12: "Декабрь"
  };

  daysInMonth: { [year: number]: { [month: number]: number[] } } = {};

  setSearchValue = (id: string, value: string) => {
    this.searchValue[id] = value;
  };

  setSelectedBuildingForTimesheet = (id: string) => {
    this.isLoading = true;
    this.error = false;

    this.rootStore.buildingOneStore.selectedOne = {};

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/building", "Объекты");
    }
    if (!this.rootStore.menuStore.allWindows[`/building/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(
        `/building/id=${id}`,
        "Загрузка..."
      );
      delete this.allOpenedTabels[id];
      delete this.allYearsAndMonth[id];
    }

    if (this.allOpenedTabels[id]) {
      runInAction(() => {
        this.isLoading = false;
      });
    } else {
      Promise.all([
        !this.legend.length && this.getTabelLegend(),
        !Object.values(this.selects).length && this.getSelects(),
        this.rootStore.buildingOneStore.getBuildingOne(id),
        this.getYearsMonths(id)
      ]).then(() => {
        runInAction(() => {
          this.selectedMode[id] = "timesheet";
          this.isLoading = false;
        });

        if (
          this.allYearsAndMonth[id]?.years &&
          Object.values(this.allYearsAndMonth[id].years).length &&
          this.allYearsAndMonth[id]?.months &&
          Object.values(this.allYearsAndMonth[id].months).length
        ) {
          const year = this.allYearsAndMonth[id]?.years
            ? getYear(new Date()) >
              Object.values(this.allYearsAndMonth[id].years).slice(-1)[0]
              ? Object.values(this.allYearsAndMonth[id].years).slice(-1)[0]
              : getYear(new Date())
            : null;
          const month =
            year && this.allYearsAndMonth[id]?.months?.[year]
              ? year === getYear(new Date()) &&
                Object.values(this.allYearsAndMonth[id].months[year]).slice(
                  -1
                )[0] >
                  getMonth(new Date()) + 1
                ? getMonth(new Date()) + 1
                : Object.values(this.allYearsAndMonth[id].months[year]).slice(
                    -1
                  )[0]
              : null;

          !this.year[id] ? (this.year[id] = year) : "";
          !this.month[id] ? (this.month[id] = month) : "";

          if (!this.daysInMonth[this.year[id]]) {
            this.daysInMonth[this.year[id]] = {};
          }

          if (!this.daysInMonth[this.year[id]][this.month[id]]) {
            this.daysInMonth[this.year[id]][this.month[id]] =
              this.getMonthArray(id);
          }

          this.getTabel(id, this.year[id] || year, this.month[id] || month);
          this.getTabelChart(
            id,
            this.year[id] || year,
            this.month[id] || month
          );
        } else {
          runInAction(() => {
            this.isLoading = false;
          });
        }
      });
    }
  };

  // легенда для табеля
  getTabelLegend = async () => {
    try {
      const data: ApiResponse<{ [key: string]: OneOfLegend }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getTabelLegend"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.legend = Object.values(data.result);
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // списки для табеля
  getSelects = async () => {
    try {
      const data: ApiResponse<TimesheetSelects> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getTabelVariables"
        });

      runInAction(() => {
        if (data.code === 200) {
          this.selects = data.result;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // получение доступных годов и месяцев для объекта
  getYearsMonths = async (building: string) => {
    try {
      const data: ApiResponse<YearsAndMonths> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getYearsMonths",
          params: { building }
        });

      runInAction(() => {
        if (data.code === 200) {
          this.allYearsAndMonth[building] = data.result;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setSelectedYear = (year: number, id: string) => {
    this.allOpenedTabelCharts[id] = {};
    // для графика принудительно запускаем загрузку здесь, чтобы не отображался пустой график
    this.isLoadingForChart = true;

    this.year[id] = year;

    // если уже есть сохраненный табель
    // и год смен из табеля не совпадает с текущим
    if (
      this.allOpenedTabels[id] &&
      Object.values(this.allOpenedTabels[id]).length &&
      this.allOpenedTabels[id].ws_list &&
      getYear(
        parse(
          Object.keys(this.allOpenedTabels[id].ws_list)[0],
          "yyyy-MM-dd",
          new Date()
        )
      ) !== year
    ) {
      const currentYear = getYear(new Date());
      const month = this.allYearsAndMonth[id]?.months?.[year]
        ? currentYear !== year &&
          getMonth(new Date()) + 1 !==
            Object.values(this.allYearsAndMonth[id].months[year]).slice(-1)?.[0]
          ? Object.values(this.allYearsAndMonth[id].months[year]).slice(-1)?.[0]
          : getMonth(new Date()) + 1 !==
            Object.values(this.allYearsAndMonth[id].months[year]).slice(-1)?.[0]
          ? Object.values(this.allYearsAndMonth[id].months[year]).slice(-1)?.[0]
          : getMonth(new Date()) + 1
        : getMonth(new Date()) + 1;

      // запрашиваем новый табель
      Promise.all([this.getTabel(id, year, month)]).then(() => {
        // и выделяем последний месяц в году
        this.setSelectedMonth(month, id);
      });
    }
  };

  setSelectedMonth = (month: number, id: string) => {
    this.month[id] = month;

    if (!this.daysInMonth[this.year[id]]) {
      this.daysInMonth[this.year[id]] = {};
    }

    if (!this.daysInMonth[this.year[id]][this.month[id]]) {
      this.daysInMonth[this.year[id]][this.month[id]] = this.getMonthArray(id);
    }

    // если уже есть сохраненный табель со сменами
    if (
      this.allOpenedTabels[id] &&
      Object.values(this.allOpenedTabels[id]).length &&
      this.allOpenedTabels[id].ws_list
    ) {
      const monthFromTabel =
        getMonth(
          parse(
            Object.keys(this.allOpenedTabels[id].ws_list)[0],
            "yyyy-MM-dd",
            new Date()
          )
        ) + 1;

      // и выбранный месяц не совпадает с месяц из смен
      if (this.month[id] !== monthFromTabel) {
        // запрашиваем новый табель и график, если его нет
        this.getTabel(id, this.year[id], month);
        !this.allOpenedTabelCharts[id]?.[month] &&
          this.getTabelChart(id, this.year[id], month);
      }
    }
  };

  // получение доступных годов и месяцев для объекта
  getTabel = async (building: string, year: number, month: number) => {
    this.isLoadingForTabel = true;
    try {
      const data: ApiResponse<Tabel> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "getTabel",
        params: { building, year, month }
      });

      runInAction(() => {
        if (data.code === 200) {
          this.allOpenedTabels[building] = data.result;
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForTabel = false;
      });
    }
  };

  // получение графика
  getTabelChart = async (building: string, year: number, month: number) => {
    this.isLoadingForChart = true;
    try {
      const data: ApiResponse<TabelChart> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getTabelChart",
          params: { building, year, month }
        });

      runInAction(() => {
        if (data.code === 200) {
          const monthChartArray: TabelChartArray = [];

          Object.entries(data.result).forEach(([date, data]) => {
            monthChartArray.push({
              name: getDate(parse(date, dateFormats.date.format, new Date())),
              day: getDate(parse(date, dateFormats.date.format, new Date())),
              hours: data.hours,
              staff: data.staff,
              date: date
            });
          });
          this.allOpenedTabelCharts[building] = {
            [month]: monthChartArray,
            ...this.allOpenedTabelCharts[building]
          };
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoadingForChart = false));
    }
  };

  setOpenedWindow = (name: string) => {
    this.openedWindow = this.openedWindow === name ? "" : name;
  };

  setSelectedDate = (date: string) => {
    this.selectedDate = date;
  };

  setSelectedStaffFilter = (name: string) => {
    this.selectedStaffFilter = name;
  };

  // метод получения массива с днями месяца (для шапки табеля)
  getMonthArray = (id: string) => {
    if (this.year[id] && this.month[id]) {
      return [
        ...Array(
          getDaysInMonth(new Date(this.year[id], this.month[id] - 1))
        ).keys()
      ].map((i) => i + 1);
    } else {
      return [];
    }
  };

  // метод для получения внешних состояний, которые недоступны к смене текущего состояния
  getDisabledConditions = async (
    building: string,
    date: string,
    uid: string
  ) => {
    this.disabledConditions = [];
    try {
      const data: ApiResponse<boolean> & {
        records: { [key: string]: string };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "getDisabledConditions",
        params: { building, date, uid }
      });

      runInAction(() => {
        if (data.code === 200) {
          this.disabledConditions = Object.values(data.records);
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для смены внешний состояний
  setCondition = async (
    building_id: string,
    date: string,
    uid: string,
    condition: string
  ) => {
    try {
      const data: ApiResponse<boolean> & {
        cell: {
          title: string;
          color: string;
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "setCondition",
        body: {
          building: building_id,
          date: date,
          uid: uid,
          condition: condition
        }
      });

      runInAction(() => {
        if (data.code === 200 && data.result) {
          const cell = this.allOpenedTabels[building_id].tabel[uid][date];
          // после изменения внешнего состояния фронт сам визуально изменяет title и color ячейки в табеле
          // если знаение внешнего состояния NULL -- значит внешнее состояние было удалено, вместо него будет отображаться статус
          // данные статуса (title и color) возвращает сам метод при удалении внешнего состояния
          if (condition === "NULL") {
            cell.title = data.cell.title;
            cell.color = data.cell.color;
          } else {
            // если меняем внешнее состояние на другое внешнее состояние, то просто меняем title на переданные нам
            cell.title = condition;
            // и цвет берем и справоника внешних состояний
            this.selects?.condition_type?.[condition]?.custom?.color_api
              ? (cell.color =
                  this.selects.condition_type[condition].custom.color_api)
              : "";
          }
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для изменения состояния в смене
  setStaffObjectStatus = async (
    workshift_id: string,
    ws_staff_id: string, // id сотрудника в смене (отличается от обычного staff_id)
    condition: string,
    building_id: string,
    date: string,
    staff_id: string
  ) => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "workshift",
        method: "editWorkshiftStaff",
        body: {
          workshift_id: workshift_id,
          ws_staff_id: ws_staff_id,
          col: "objectstatus",
          value: condition
        }
      });

      runInAction(() => {
        if (data.code === 200 && data.result) {
          const cell = this.allOpenedTabels[building_id].tabel[staff_id][date];
          // после изменения состояния в смене фронт сам визуально изменяет title и color ячейки в табеле
          cell.title = condition;
          this.selects.workshift_staff_status?.[condition]?.custom?.color_api
            ? (cell.color =
                this.selects.workshift_staff_status[condition].custom.color_api)
            : "";
        } else this.error = true;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // метод для изменения выбранного режима просмотра - табель или график
  setSelectedMode = (mode: string, id: string) => {
    this.selectedMode[id] = mode;
  };

  clearBuildingData = (id: string) => {
    runInAction(() => {
      this.allOpenedTabels[id] && delete this.allOpenedTabels[id];
      this.allOpenedTabelCharts[id] && delete this.allOpenedTabelCharts[id];
      this.allYearsAndMonth[id] && delete this.allYearsAndMonth[id];
      this.year[id] && delete this.year[id];
      this.month[id] && delete this.month[id];
    });
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
