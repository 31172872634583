import { makeAutoObservable, runInAction } from "mobx";

import RootStore from "stores";
import { BuildingEventType } from "stores/StaffModule/types/BuildingEventType";
import { Widgets } from "stores/StaffModule/types/Widgets";

import { format } from "date-fns";
import { setDate, differenceInCalendarMonths } from "date-fns";
import { cloneDeep } from "lodash";

import { IColForStatuses } from "../types/IColForStatuses";
import { Selects } from "../types/Selects";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Status } from "../types/Status";
import { StaffList } from "../types/StaffList";
import { StaffListItem } from "../types/StaffListItem";
import { Retention } from "../types/Retention";

type StatusesGroups = {
  [key: string]: {
    bool_column?: {
      [key: string]: string[];
    };
    required_column: {
      [key: string]: number;
    };
  };
};

type InitialValuesForStatus = Omit<
  Status & {
    transferbuilding?: string;
    building_parent?: string;
    close_transfer?: number;
  },
  "author" | "future" | "months" | "level"
>;

type missingSelects = {
  selects: Selects;
  errors?: string;
};

// hardcode
const requiredCols = [
  "dismiss_type",
  "dismiss_reason",
  "dismiss_comment",
  "dismiss_object"
];

const dismiss_fields = [
  "dismiss_date",
  "dismiss_reason",
  "dismiss_type",
  "dismiss_comment",
  "dismiss_position",
  "black_list",
  "black_reason",
  "grey_list",
  "grey_reason"
];
export default class StaffOneStatusesStore {
  error = false;
  errorsMessage: { [key: string]: Errors["message"] } = {};
  isLoading = false;
  isLoadingForForm = false;
  isLoadingForStaffList = false;
  isLoadingAllowEdu = true;
  isReversedTable = false;

  statusesCols: {
    [key: string]: Partial<IColForStatuses>;
  } = {};
  showStatuses: string[] = [];
  missingDirectories: Partial<Selects> = {};
  statusesGroupsForAdd: StatusesGroups = {};
  requiredColumnForEdit: StatusesGroups = {};
  statusesParams: { [key: string]: BuildingEventType } = {};
  statusesList: {
    [key: string]: {
      [key: string]: { [key: string]: Status };
    };
  } = {};
  staffInfo: Partial<Widgets["staff"]> = {};

  staffList: { [key: string]: string }[] = [];
  searchValue = "";
  onPage = 40;
  page = 1;
  maxPage = 0;
  prevPage = 1;

  eventData: {
    [key: string]: Map<
      string,
      {
        isActiveStatus: boolean;
        isFirstSubVacation: boolean;
        isLastSubVaсation: boolean;
        title: string;
        firstStatus: Status;
        lastStatus: Status;
        months: {
          [key: string]: Status[];
        };
      }
    >;
  } = {};

  // статусы, которые входят по датам в соседние статусы
  includedStatuses: { [key: string]: Status[] } = {};

  monthList: string[] = [];
  // наибольшее возможное кол-во статусов в одном месяце
  monthStatusesCount: { [key: string]: number } = {};
  // объект содержит месяцы в которых есть хвост статуса начатого в предыдущем месяце
  monthStatusesTailsCount: { [key: string]: number } = {};
  // общее кол-во столбцов в таблице для colSpan
  monthTotalCols = 0;

  plannedObjectsList: {
    [key: string]: { [key: string]: { [key: string]: string } };
  } = {};
  plannedObjectComment: { [key: string]: string | null } = {};

  selectedOneForStatuses: Partial<Widgets> = {};
  openedAllStaffForStatuses: {
    [key: string]: Partial<Widgets>;
  } = {};

  openedAllEventAllowEdu: {
    [staff_id: string]: Retention;
  } = {};
  allUpdatedEventAllowEdu: {
    [staff_id: string]: Retention;
  } = {};

  companiesTabs: { id: string; title: string; topStatus: Status }[] = [];
  companiesList: { [company_id: string]: { id: string; title: string } } = {};
  actualPositions: { [staff_id: string]: { [company_id: string]: string } };

  //поле для фильтрации статусов по компании в истории статусов
  filteredCompany = "";

  selectedCompany = "";
  selectedStatus = "";
  selectedRow = "";
  lastStatus: Partial<{ type: string; building: string }> = {};
  initialValuesForAdd: Partial<InitialValuesForStatus> = {};
  initialValuesForEdit: Partial<InitialValuesForStatus> = {};

  openedWindow = "";

  rootStore: RootStore;

  // hardcode
  dismissStatus = "e329ed9b615763361b86d0d31cc3aac6b620c262";
  activeStatus = "";
  transferStatus = "";

  withHoldingEduFields = ["money", "date", "without_edu"];

  handleSetSelectedStatus = (status: Status) => () => {
    this.statusesParams[status.type]?.["custom"]?.["allow_edit_bool"] &&
      this.setSelectedRow(status["id"], status["type"]);
  };

  setStatusesForGraph = () => {
    this.eventData = {};
    const monthStatusesCount: { [key: string]: number } = {};
    const monthStatusesTailsCount: { [key: string]: number } = {};
    this.includedStatuses = {};

    //фильтруем по выбранным компаниям
    let statusesList = cloneDeep(this.statusesList);
    if (this.filteredCompany && this.filteredCompany !== "all") {
      statusesList = {};
      statusesList[this.filteredCompany] =
        this.statusesList[this.filteredCompany];
    }

    // обходим компании
    Object.entries(statusesList)
      .filter(([, company]) => Boolean(company))
      .map(([companyId, company]) => {
        this.eventData[companyId] = new Map();
        const buildings = Object.values(company);
        this.isReversedTable && buildings.reverse();

        //обходим объекты компании
        buildings.forEach((building, buildingIndex) => {
          const buildingId = Object.values(building)[0]["building"];

          const datesObj: { [key: string]: Status[] } = {};
          const buildingMonthsStatusesCount: { [key: string]: number } = {}; // кол-во ячеек в месяце (на уровне объекта)

          const buildingStatuses = Object.values(building);
          this.isReversedTable && buildingStatuses.reverse();

          // обходим статусы
          buildingStatuses.forEach((status, statusIndex) => {
            let dateEnd = setDate(
              new Date(status.event_end || status.event_start),
              1
            );
            let dateStart = setDate(new Date(status.event_start), 1);
            const statusLength =
              differenceInCalendarMonths(dateEnd, dateStart) + 1; // длина статуса в месяцах

            // проверка на includedStatuses
            // количество статусов внутрь которых попадает данный статус
            let level = 0;
            // статус родитель
            let parentStatus: Status = null;
            if (statusLength > 1) {
              // проверяем следующие статусы
              const prevStatuses = this.isReversedTable
                ? buildingStatuses.slice(0, statusIndex)
                : buildingStatuses.slice(statusIndex + 1);
              for (const prevStatus of prevStatuses) {
                if (
                  prevStatus.event_end &&
                  dateEnd <= new Date(prevStatus.event_end) &&
                  dateStart >= new Date(prevStatus.event_start) &&
                  // выкидываем все что полностью укладывается в месяц
                  differenceInCalendarMonths(
                    new Date(prevStatus.event_end),
                    new Date(prevStatus.event_start)
                  ) > 0
                ) {
                  level++;
                  parentStatus = prevStatus;
                }
              }
            }

            if (this.isReversedTable) {
              [dateStart, dateEnd] = [dateEnd, dateStart];
            }
            const dateEndStr = format(dateEnd, "yyyy-MM-dd");
            const dateStartStr = format(dateStart, "yyyy-MM-dd");

            const statusWithMonths = {
              ...status,
              months: statusLength,
              level: level
            };
            if (!level) {
              if (!buildingMonthsStatusesCount[dateEndStr]) {
                buildingMonthsStatusesCount[dateEndStr] = 0;
              }
              if (!buildingMonthsStatusesCount[dateStartStr]) {
                buildingMonthsStatusesCount[dateStartStr] = 0;
              }
              buildingMonthsStatusesCount[dateEndStr] += 1;
              if (dateEndStr !== dateStartStr) {
                buildingMonthsStatusesCount[dateStartStr] += 1;
                monthStatusesTailsCount[dateStartStr] = 1;
              }
              if (datesObj[dateEndStr] === undefined) {
                datesObj[dateEndStr] = [];
              }
              datesObj[dateEndStr].push(statusWithMonths);
            } else {
              if (!this.includedStatuses[parentStatus.id]) {
                this.includedStatuses[parentStatus.id] = [];
              }
              // добавляем includedStatuses в родителя
              this.includedStatuses[parentStatus.id].push(status);
            }
          });

          const firstStatus =
            Object.values(building)[Object.values(building).length - 1];
          const lastStatus = Object.values(building)[0];

          //переменная, которая проверяет является ли первая дата на объекте датой отпуска
          const isFirstSubVacation = Boolean(
            firstStatus?.sub_start_date &&
              firstStatus?.sub_end_date &&
              this.statusesParams[lastStatus?.type]?.custom.sub_date
          );

          //переменная, которая проверяет является ли последняя дата на объекте датой отпуска
          const isLastSubVaсation = Boolean(
            lastStatus?.sub_start_date &&
              lastStatus?.sub_end_date &&
              this.statusesParams[lastStatus?.type]?.custom.sub_date
          );

          //переменная, которая проверяет является ли последний статус у текущего объекта "Активен"
          const isActiveStatus =
            this.statusesParams[lastStatus?.type]?.custom.active &&
            buildingIndex === 0;

          const buildingTitle = lastStatus.building_title;

          if (!this.eventData[companyId].has(buildingId)) {
            this.eventData[companyId].set(buildingId, {
              title: buildingTitle,
              isActiveStatus: isActiveStatus,
              isFirstSubVacation: isFirstSubVacation,
              isLastSubVaсation: isLastSubVaсation,
              firstStatus: firstStatus,
              lastStatus: lastStatus,
              // список статусов сгруппированный по месяцам
              months: datesObj
            });
          } else {
            // склейка разорванного объекта
            for (const month in datesObj) {
              if (
                this.eventData[companyId].get(buildingId).months[month] !==
                undefined
              ) {
                this.eventData[companyId].get(buildingId).months[month] = this
                  .isReversedTable
                  ? [
                      ...datesObj[month],
                      ...this.eventData[companyId].get(buildingId).months[month]
                    ]
                  : [
                      ...this.eventData[companyId].get(buildingId).months[
                        month
                      ],
                      ...datesObj[month]
                    ];
              } else {
                this.eventData[companyId].get(buildingId).months[month] =
                  datesObj[month];
              }
              // учет хвостов
              buildingMonthsStatusesCount[month] =
                this.eventData[companyId].get(buildingId).months[month].length +
                (monthStatusesTailsCount[month] || 0);
            }
          }

          Object.entries(buildingMonthsStatusesCount).map(([month, length]) => {
            if (
              !monthStatusesCount[month] ||
              length > monthStatusesCount[month]
            ) {
              monthStatusesCount[month] = buildingMonthsStatusesCount[month];
            }
          });
        });

        if (this.isReversedTable) {
          this.eventData[companyId] = new Map(
            [...this.eventData[companyId]].reverse()
          );
        }
      });
    this.monthStatusesCount = monthStatusesCount;
    this.monthList = Object.keys(monthStatusesCount);
    this.monthList.sort();
    !this.isReversedTable && this.monthList.reverse();
    this.monthTotalCols = Object.values(monthStatusesCount).reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    this.monthStatusesTailsCount = monthStatusesTailsCount;
  };

  setDataOfStatuses = (data?: Partial<Widgets>) => {
    if (data) {
      this.statusesList = data.widgets.building_staff_events;
      this.plannedObjectsList = data.widgets.building_planned_object;
      this.staffInfo = data.staff;

      if (data.cols) {
        const cols = data.cols;
        for (const group in cols) {
          for (const field in cols[group]) {
            if (field !== "id") {
              this.statusesCols[field] = cols[group][field];
            }
          }
        }
      }
      this.setStatusesForGraph();

      if (Object.values(data.selects.building_events_type)) {
        Object.values(data.selects.building_events_type).map((event_type) => {
          this.statusesParams[event_type.id] = event_type;
        });
        Object.entries(data.selects.building_events_type).forEach(
          ([key, status]) => {
            const today = format(new Date(), "yyyy-MM-dd");

            !this.initialValuesForAdd[key]
              ? (this.initialValuesForAdd[key] = {
                  event_start: today
                })
              : "";

            !this.initialValuesForEdit[key]
              ? (this.initialValuesForEdit[key] = {
                  event_start: null,
                  type: key
                })
              : "";

            !this.statusesGroupsForAdd[key]
              ? (this.statusesGroupsForAdd[key] = {
                  bool_column: {},
                  required_column: {}
                })
              : "";

            !this.requiredColumnForEdit[key]
              ? (this.requiredColumnForEdit[key] = {
                  required_column: {}
                })
              : "";

            this.statusesGroupsForAdd[key].required_column.event_start = 1;
            this.requiredColumnForEdit[key].required_column.event_start = 1;

            if (Object.keys(data.staff.company).length) {
              this.setCompaniesTabs(data.staff.company);
              Object.entries(data.staff.company).forEach(([key, company]) => {
                key?.length &&
                  company?.employments &&
                  Object.values(company.employments).length &&
                  Object.values(company.employments).forEach((trud_dog) => {
                    if (trud_dog["position"]) {
                      !this.actualPositions[data.staff.id]
                        ? (this.actualPositions[data.staff.id] = {})
                        : "";
                      this.actualPositions[data.staff.id][key] =
                        trud_dog["position_title"] || "Должность не указана";
                    }
                  });
              });
            }

            if (status.custom && Object.values(status.custom)) {
              if (status.custom.date_end) {
                this.initialValuesForAdd[key]["event_end"] = null;
                this.initialValuesForEdit[key]["event_end"] = null;

                if (status.custom.date_end_required) {
                  this.statusesGroupsForAdd[key].required_column.event_end = 1;
                  this.requiredColumnForEdit[key].required_column.event_end = 1;
                }
              }

              if (status.custom.sub_date) {
                this.initialValuesForAdd[key]["sub_start_date"] = null;
                this.initialValuesForEdit[key]["sub_start_date"] = null;

                this.initialValuesForAdd[key]["sub_end_date"] = null;
                this.initialValuesForEdit[key]["sub_end_date"] = null;
              }

              if (status.custom.bage) {
                this.initialValuesForAdd[key].bage = null;
                this.initialValuesForEdit[key].bage = null;

                // hardcode - с бэка приходит некорректный title
                this.statusesCols.bage.title = "Дополнительная метка";

                this.statusesCols.bage.directory =
                  this.statusesCols.bage.directory ?? {};
                this.statusesCols.bage.directory[key] = {};
                Object.values(status.custom.bage).forEach((bage) => {
                  this.statusesCols.bage.directory[key][bage] = {
                    newname: bage,
                    title: bage
                  };
                });
              }

              if (Object.values(status.custom.staff_cols).length) {
                Object.values(status.custom.staff_cols).forEach((field) => {
                  if (this.statusesCols[field]) {
                    switch (field) {
                      case "transferbuilding":
                        this.initialValuesForAdd[key][field] = "";
                        this.statusesGroupsForAdd[key].required_column[
                          field
                        ] = 1;
                        return;
                      case "dismiss_object":
                        this.initialValuesForAdd[key][field] = null;
                        this.statusesCols[field].disabled = 1;
                        return;
                      case "dismiss_position":
                        this.initialValuesForAdd[key][field] = null;
                        return;
                      case "dismiss_date":
                        this.initialValuesForAdd[key][field] = today;
                        return;
                    }

                    if (requiredCols.includes(field)) {
                      this.statusesGroupsForAdd[key].required_column[field] = 1;
                    }

                    if (this.statusesCols[field].type === "bool") {
                      return (this.initialValuesForAdd[key][field] = 0);
                    }
                    if (field.includes("comment")) {
                      return (this.initialValuesForAdd[key][field] = "");
                    }

                    this.initialValuesForAdd[key][field] =
                      this.statusesCols[field].default === "NULL" ||
                      this.statusesCols[field].default === "CURRENT_TIMESTAMP"
                        ? null
                        : (this.statusesCols[field].default as string);
                  }
                });
              }

              if (Object.values(status.custom.bool_cols).length) {
                Object.entries(status.custom.bool_cols).forEach(
                  ([parentField, bool]) => {
                    Object.values(bool).forEach((field) => {
                      if (
                        this.statusesCols[field] &&
                        !Object.keys(
                          Object.values(status.custom.staff_cols)
                        ).includes(field)
                      ) {
                        !this.statusesGroupsForAdd[key].bool_column[parentField]
                          ? (this.statusesGroupsForAdd[key].bool_column[
                              parentField
                            ] = [])
                          : "";

                        !this.statusesGroupsForAdd[key].bool_column[
                          parentField
                        ].includes(field) &&
                          this.statusesGroupsForAdd[key].bool_column[
                            parentField
                          ].push(field);

                        this.initialValuesForAdd[key][field] =
                          this.statusesCols[field].default === "NULL" ||
                          this.statusesCols[field].default ===
                            "CURRENT_TIMESTAMP"
                            ? null
                            : (this.statusesCols[field].default as string);

                        if (requiredCols.includes(field)) {
                          this.statusesGroupsForAdd[key].required_column[
                            field
                          ] = 1;
                        }
                      }
                    });
                  }
                );
              }
            }
          }
        );
      }

      if (Object.keys(data.widgets.building_event_comment).length) {
        this.plannedObjectComment = data.widgets.building_event_comment;
      }

      if (Object.keys(data.selects).length) {
        Object.entries(data.selects).forEach(([key, value]) => {
          switch (key) {
            case "buildings":
              Object.entries(value as Widgets["selects"]["building"]).forEach(
                ([id, buildings]) => {
                  Object.values(buildings).forEach((building) => {
                    const addBuildingsDirectory = (fieldName: string) => {
                      !this.statusesCols[fieldName].directory
                        ? (this.statusesCols[fieldName].directory = {})
                        : "";
                      !this.statusesCols[fieldName].directory[id]
                        ? (this.statusesCols[fieldName].directory[id] = {})
                        : "";
                      this.statusesCols[fieldName]?.directory?.[id]
                        ? (this.statusesCols[fieldName].directory[id][
                            building.id
                          ] = {
                            newname: building.id,
                            title: building.title
                          })
                        : "";
                    };
                    if (this.statusesCols["building"]) {
                      addBuildingsDirectory("building");
                    }

                    if (this.statusesCols["transferbuilding"]) {
                      addBuildingsDirectory("transferbuilding");
                    }

                    if (this.statusesCols["dismiss_object"]) {
                      addBuildingsDirectory("dismiss_object");
                    }
                  });
                }
              );
              break;
            default:
              key !== "building_event_type" &&
                Object.values(value).forEach((item) => {
                  this.statusesCols[key] && !this.statusesCols[key]?.directory
                    ? (this.statusesCols[key].directory = {})
                    : "";
                  this.statusesCols[key]?.directory
                    ? (this.statusesCols[key].directory[
                        item.id ? item.id : item.title
                      ] = {
                        newname: item.id ? item.id : item.title,
                        title: item.title
                      })
                    : "";
                });
              break;
          }
        });
        if (Object.values(this.missingDirectories).length) {
          Object.entries(this.missingDirectories).forEach(([key, value]) => {
            if (!this.statusesCols[key]?.directory) {
              this.statusesCols[key] && !this.statusesCols[key]?.directory
                ? (this.statusesCols[key].directory = {})
                : "";
              Object.values(value).forEach((item) => {
                this.statusesCols[key]?.directory
                  ? (this.statusesCols[key].directory[
                      item.id ? item.id : item.title
                    ] = {
                      newname: item.id ? item.id : item.title,
                      title: item.title
                    })
                  : "";
              });
            }
          });
        }
      }
    } else {
      this.error = false;
      this.errorsMessage = {};
      this.selectedCompany = "";
      this.selectedStatus = "";
      this.selectedRow = "";
      this.lastStatus = {};
      this.statusesGroupsForAdd = {};
      this.statusesList = {};
      this.statusesParams = {};
      this.statusesCols = {};
      this.plannedObjectsList = {};
      this.plannedObjectComment = {};
      this.actualPositions = {};
      this.showStatuses = [];
      this.companiesTabs = [];
      this.initialValuesForAdd = {};
    }
  };

  setSelectedOneForStatuses = (id: string, fromStaffOneCard?: boolean) => {
    this.isLoading = true;
    this.error = false;

    this.setDataOfStatuses();
    if (Object.values(this.rootStore.menuStore.allWindows).length === 0) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }
    if (this.rootStore.menuStore.allWindows[`/staff/id=${id}`] === undefined) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForStatuses[id];
    }

    if (
      !fromStaffOneCard &&
      Object.keys(this.openedAllStaffForStatuses).length &&
      this.openedAllStaffForStatuses[id]
    ) {
      if (Object.values(this.openedAllStaffForStatuses[id]).length) {
        this.selectedOneForStatuses = this.openedAllStaffForStatuses[id];
        this.setDataOfStatuses(this.selectedOneForStatuses);
        this.errorsMessage = {};
      } else this.error = true;

      this.isLoading = false;
    } else {
      Promise.all([
        this.getStatusesForOneOfStaff(id),
        this.getMissingDirectories(id)
      ]).then(() => {
        runInAction(() => {
          this.isLoading = false;
        });
      });
    }
  };

  getStatusesForOneOfStaff = async (id: string) => {
    try {
      const data: Widgets = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          staff_fields:
            "surname, name, patronymic, id, company, uid, photo, position",
          widgets: [
            "building_staff_events",
            "building_planned_object",
            "building_event_comment"
          ],
          cols: "staff, building_staff_events, building_planned_object",
          selects:
            "dismiss_reason, type, status, dismiss_type, buildings, position, company, grazd, vakans, ist_inf, reason_off, size, education, soisk_status,place_of_study, manager_mass,region, building_events_type"
        }
      });

      if (!data["errors"]) {
        this.openedAllStaffForStatuses[id] = data;
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: `${data["staff"]["surname"]} ${data["staff"]["name"]}`
        });

        if (this.rootStore.menuStore.tabId === id) {
          this.selectedOneForStatuses = this.openedAllStaffForStatuses[id];
          this.setDataOfStatuses(this.selectedOneForStatuses);
        }
      } else {
        this.openedAllStaffForStatuses[id] = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });

        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  // справочники из метода getOne, удалить getMissingDirectories и missingDirectories, когда будут приходить в методу getWidgets
  // Недостающие справочники
  // grazd, vakans, ist_inf, reason_off, size, education, soisk_status,place_of_study, manager_mass,region

  getMissingDirectories = async (id: string) => {
    try {
      const data: missingSelects = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getOne",
        params: {
          staff_id: id,
          action: "edit"
        }
      });

      if (!data.errors) {
        if (Object.keys(data["selects"]).length) {
          Object.entries(data["selects"]).forEach(([key, value]) => {
            if (key == "position") return;
            this.missingDirectories[key] = {};
            Object.values(value).forEach((item) => {
              runInAction(() => {
                this.missingDirectories[key][item.id ? item.id : item.title] = {
                  newname: item.id ? item.id : item.title,
                  title: item.title
                };
              });
            });
          });
        }
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  setCompaniesTabs = (companiesData: Widgets["staff"]["company"]) => {
    this.companiesTabs = [];
    this.companiesList = {};
    Object.values(companiesData).forEach((company) => {
      const topStatus =
        this.statusesList[company.id] !== undefined &&
        Object.values(this.statusesList[company.id])
          .map((statuses, index) => {
            return (
              index < 2 &&
              Object.values(statuses)
                .filter(
                  (status) =>
                    status.future >= 0 ||
                    status.future ===
                      Math.min(
                        ...Object.values(statuses).map(
                          (status) => status?.["future"] as number
                        )
                      )
                )
                .find((status) => {
                  return status.future === 0;
                })
            );
          })
          .find((status) => status);

      const companiesTabs: { id: string; title: string; topStatus: Status } = {
        id: "",
        title: "",
        topStatus: topStatus
      };

      if (company.id && company.title) {
        companiesTabs["id"] = company.id;
        companiesTabs["title"] = company.title;
        this.companiesList[company.id] = {
          id: company.id,
          title: company.title
        };

        this.companiesTabs.push(companiesTabs);
        this.companiesList[company.id] = {
          id: company.id,
          title: company.title
        };
      }
    });
    this.companiesTabs.sort((a, b) => {
      if (
        this.statusesParams[a.topStatus?.type] ===
        this.statusesParams[b.topStatus?.type]
      ) {
        return 0;
      } else if (this.statusesParams[a.topStatus?.type]?.["custom"]["active"]) {
        return -1;
      } else {
        return 1;
      }
    });

    this.companiesTabs =
      Object.keys(companiesData).length -
        Number(Object.keys(companiesData).includes("")) >
      1
        ? (this.companiesTabs = [
            {
              id: "all",
              title: "Все",
              topStatus: null
            },
            ...this.companiesTabs
          ])
        : this.companiesTabs;

    if (!this.selectedCompany.length) {
      this.setSelectedCompany(this.companiesTabs[0].id);
      this.setFilteredCompany(this.companiesTabs[0].id);
    } else {
      this.setLastStatus(this.selectedCompany);
    }
  };

  checkEventAllowEdu = async (
    staff_id: string,
    event_id: string,
    date?: string
  ) => {
    this.isLoadingAllowEdu = true;
    this.openedAllEventAllowEdu[staff_id]
      ? delete this.openedAllEventAllowEdu[staff_id]
      : "";

    try {
      const data: Retention = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "checkEventAllowEdu",
        params: {
          uid: staff_id,
          event: event_id,
          date: date ? date : format(new Date(), "yyyy-MM-dd")
        }
      });

      runInAction(() => {
        if (
          data.code === 200 &&
          this.statusesParams[event_id]?.custom?.close_withholding
        ) {
          this.openedAllEventAllowEdu[staff_id] = {
            status: event_id,
            date: date ? date : format(new Date(), "yyyy-MM-dd"),
            ...data
          };
        } else {
          this.openedAllEventAllowEdu[staff_id]
            ? delete this.openedAllEventAllowEdu[staff_id]
            : "";
        }
        this.isLoadingAllowEdu = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingAllowEdu = false;
      });
    }
  };

  updateEventAllowEdu = async (
    staff_id: string,
    event_id: string,
    date?: string
  ) => {
    this.isLoadingAllowEdu = true;
    this.allUpdatedEventAllowEdu[staff_id]
      ? delete this.allUpdatedEventAllowEdu[staff_id]
      : "";

    try {
      const data: Retention = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "building",
        method: "checkEventAllowEdu",
        params: {
          uid: staff_id,
          event: event_id,
          date: date ? date : format(new Date(), "yyyy-MM-dd")
        }
      });

      runInAction(() => {
        if (
          data.code === 200 &&
          this.statusesParams[event_id]?.custom?.close_withholding
        ) {
          this.allUpdatedEventAllowEdu[staff_id] = {
            status: event_id,
            date: date ? date : format(new Date(), "yyyy-MM-dd"),
            ...data
          };
        } else {
          this.allUpdatedEventAllowEdu[staff_id]
            ? delete this.allUpdatedEventAllowEdu[staff_id]
            : "";
        }
        this.isLoadingAllowEdu = false;
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingAllowEdu = false;
      });
    }
  };

  dataPreparation = (data: StaffListItem[]) => {
    return data.map((oneOfStaff) => {
      const tempOneOfStaffObj: {
        [key: string]: string;
      } = {};
      Object.entries(oneOfStaff).forEach(([key, value]) => {
        if (typeof value === "string" || typeof value === "number") {
          switch (key) {
            case "id":
              tempOneOfStaffObj[key] = value as string;
              break;
            case "uid":
              tempOneOfStaffObj[key] = `ТН${value}`;
              break;
            case "name":
              tempOneOfStaffObj[key] = `${oneOfStaff["surname"]} ${value} ${
                oneOfStaff["patronymic"] || ""
              }`;
              break;
            default:
              break;
          }
        }
      });
      return tempOneOfStaffObj;
    });
  };

  getStaffList = async () => {
    this.isLoadingForStaffList = true;
    try {
      const data: StaffList = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          fast_search: this.searchValue
        }
      });

      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.staffList = this.dataPreparation(Object.values(data["records"]));
        } else {
          this.staffList = [];
          this.isLoadingForStaffList = false;
        }
        this.maxPage = data["nav"]["max_page"];
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForStaffList = false;
      });
    }
  };

  getMoreStaff = async () => {
    this.isLoadingForStaffList = true;
    try {
      const data: {
        records: { [key: string]: StaffListItem };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getPage",
        on_page: this.onPage,
        page: this.page,
        params: {
          fast_search: this.searchValue,
          only_records: 1
        }
      });

      runInAction(() => {
        if (Object.values(data["records"]).length) {
          this.staffList.push(
            ...this.dataPreparation(Object.values(data["records"]))
          );
        } else {
          this.staffList = [];
          this.isLoadingForStaffList = false;
        }
        this.prevPage = this.page;
      });
    } catch (error) {
      runInAction(() => {
        this.error = false;
      });
    } finally {
      runInAction(() => (this.isLoadingForStaffList = false));
    }
  };

  setSearchValue = (value: string) => {
    this.setPage(1);
    this.prevPage = 1;
    this.searchValue = value;
  };

  setPage = (value: number) => {
    if (!this.isLoadingForStaffList) {
      this.page = value;
    }
  };

  getReverseTable = () => {
    this.isReversedTable = !this.isReversedTable;
  };

  setSelectedCompany = (id: string) => {
    this.selectedCompany = id;

    this.setLastStatus(id);
  };

  setFilteredCompany = (id: string) => {
    this.filteredCompany = id;
  };

  setLastStatus = (id: string) => {
    if (this.statusesList[id] && Object.values(this.statusesList[id]).length) {
      if (this.statusesList[id]) {
        if (Object.values(this.statusesList[id])[0]) {
          if (Object.values(Object.values(this.statusesList[id])[0])[0]) {
            const status = Object.values(
              Object.values(this.statusesList[id])[0]
            )[0];

            this.lastStatus = {
              type: status["type"],
              building: status["building"]
            };
            this.showStatuses = Object.values(
              this.statusesParams[status["type"]].custom.transit_cols
            );
          }
        }
      }
    } else {
      this.showStatuses = [];
      this.lastStatus = {};
      Object.values(this.statusesParams).forEach((status) => {
        if (
          Object.values(status.custom.staff_cols).includes("transferbuilding")
        ) {
          this.showStatuses[0] = status.id;
        }
      });
    }
    this.setSelectedStatus(this.showStatuses[0]);
  };

  setOpenedWindow = (type: string) => {
    this.errorsMessage = {};
    this.openedWindow = type;
    this.setSelectedStatus(this.showStatuses[0]);
  };

  setSelectedStatus = (id: string) => {
    if (this.selectedStatus !== id) {
      this.selectedStatus = id;
      this.checkEventAllowEdu(this.staffInfo.id, id);
    }
  };

  setSelectedRow = (id: string, status?: string) => {
    if (this.selectedRow !== id) {
      this.openedWindow = "edit";
      this.selectedRow = id;
      this.selectedStatus = status;
    } else {
      this.openedWindow = "";
      this.selectedRow = "";
      this.selectedStatus = "";
    }
  };

  getInitialValuesForEdit = (values: { [key: string]: string | number }) => {
    const newValues = cloneDeep(this.initialValuesForEdit[this.selectedStatus]);
    Object.keys(values).forEach((field) => {
      if (field in newValues || field === "time_create") {
        newValues[field] = values[field];
      }
    });
    return newValues;
  };

  addNewPlannedObject = async (value: { [key: string]: string }) => {
    this.isLoading = true;

    try {
      const data: ApiResponse<boolean> & { id: string; message: string } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "building",
          currentClass: "building_planned_object",
          method: "add",
          body: { ...value }
        });

      if (data["result"]) {
        await Promise.all([this.getStatusesForOneOfStaff(value.staff_id)]).then(
          () => {
            runInAction(() => {
              this.isLoading = false;
            });
          }
        );
      } else {
        this.error = true;
        this.isLoading = false;
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  addNewStaffEvent = async (
    value: InitialValuesForStatus,
    staff_id: string
  ) => {
    this.isLoadingForForm = true;

    const formData: InitialValuesForStatus & { uid: string } = {
      uid: staff_id,
      ...cloneDeep(value)
    };

    Object.entries(formData).forEach(([key, fieldValue]) => {
      if (key === "without_edu") {
        if (formData["without_edu"] === 0) {
          formData["without_edu"] = 1;
        } else if (formData["without_edu"] === 1) {
          delete formData[key];
        }
      } else {
        !fieldValue ? delete formData[key] : "";
      }
    });

    delete formData["company"];

    if (value.transferbuilding) {
      // обязательно передавать при первом входящем трансфере parent: "new"
      !this.lastStatus.building
        ? (formData.parent = "new")
        : (formData.building_parent = this.lastStatus.building);

      formData.building = value.transferbuilding;
      delete formData.transferbuilding;
    } else {
      formData.building = this.lastStatus.building;
    }

    if (
      // тип нового статуса - Активен
      value.type === this.activeStatus &&
      // тип последнего статуса - Трансфер
      this.lastStatus.type === this.transferStatus
    ) {
      // обязательнай параметр при принятии из Трансфера с статусом "Активен"
      formData.close_transfer = 1;
    }

    // тип нового статуса - Уволен
    if (value.type === this.dismissStatus) {
      // для добавления статуса меняем имена параметров для dismiss-полей
      dismiss_fields.forEach((dismiss_field) => {
        if (dismiss_field in formData) {
          formData[`extra_col[${dismiss_field}]`] = formData[dismiss_field];
          delete formData[dismiss_field];
        }
      });
    }

    // если для статуса можно проводит фин.удержания и на выбранную дату удержания есть
    if (
      this.statusesParams[value.type].custom.close_withholding &&
      this.openedAllEventAllowEdu[staff_id].result
    ) {
      // а таже в форме заполнены поля удержаний
      if (!formData["without_edu"]) {
        this.withHoldingEduFields.forEach((edu_field) => {
          if (edu_field !== "without_edu" && edu_field in formData) {
            // то переименовываем параметр
            formData[`close_withholding_edu[${edu_field}]`] =
              edu_field === "money"
                ? formData[edu_field] && isNaN(formData[edu_field])
                  ? +formData[edu_field]
                      .slice(0, -2)
                      .replace(",", ".")
                      .replace(/\s/g, "")
                  : formData[edu_field]
                : formData[edu_field];
          }
        });
      }
    }

    // и удаляем все поля удержаний (в любом случае)
    this.withHoldingEduFields.forEach((field) => {
      field in formData && delete formData[field];
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "addStaffEvent",
        body: { ...formData }
      });

      if (data.result) {
        await Promise.all([this.getStatusesForOneOfStaff(staff_id)]).then(
          () => {
            runInAction(() => {
              this.rootStore.staffOneStore.setRebootStaff(staff_id, true);
              this.errorsMessage.add && delete this.errorsMessage.add;

              this.isLoadingForForm = false;
            });
          }
        );
      } else if (data.message) {
        runInAction(() => {
          this.errorsMessage.add = data.message;
          this.isLoadingForForm = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingForForm = false;
      });
    }
  };

  updateStaffEvent = async (
    values: {
      event_id: string;
    } & Status,
    staff_id: string,
    initialValues: { [key: string]: string | number }
  ) => {
    this.isLoadingForForm = true;

    const formData = {};

    Object.entries(values).forEach(([key, value]) => {
      if (key === "event_id") {
        formData[key] = value;
      } else {
        initialValues[key] !== value && key !== "company"
          ? (formData[`update[${key}]`] = value ?? "")
          : "";
      }
    });

    // Удаляем поля Удержаний УЦ
    this.withHoldingEduFields.forEach((field) => {
      field in formData && delete formData[field];
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "updateStaffEvent",
        body: { ...formData }
      });

      if (data.result) {
        await Promise.all([this.getStatusesForOneOfStaff(staff_id)]).then(
          () => {
            runInAction(() => {
              this.errorsMessage.update && delete this.errorsMessage.update;
              this.rootStore.staffOneStore.setRebootStaff(staff_id, true);

              this.isLoadingForForm = false;
            });
          }
        );
      } else {
        runInAction(() => {
          this.errorsMessage.update = data.message;
          this.isLoadingForForm = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingForForm = false;
      });
    }
  };

  deleteStaffEvent = async (
    values: {
      event_id: string;
    },
    staff_id: string
  ) => {
    this.isLoadingForForm = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        method: "deleteStaffEvent",
        body: { ...values }
      });

      if (data["result"]) {
        await Promise.all([this.getStatusesForOneOfStaff(staff_id)]).then(
          () => {
            runInAction(() => {
              this.errorsMessage.delete && delete this.errorsMessage.delete;
              this.rootStore.staffOneStore.setRebootStaff(staff_id, true);

              this.isLoadingForForm = false;
            });
          }
        );
      } else if (data.message) {
        runInAction(() => {
          this.errorsMessage.delete = data.message;
          this.isLoadingForForm = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingForForm = false;
      });
    }
  };

  updateStatusComment = async (
    value: {
      [key: string]: string;
    },
    id: string
  ) => {
    runInAction(() => (this.isLoading = true));

    try {
      const data: ApiResponse<never> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "building",
        currentClass: "building_events",
        method: "update",
        body: { ...value }
      });

      if (!data["errors"]) {
        await this.getStatusesForOneOfStaff(id);
        this.rootStore.staffOneStore.setRebootStaff(id, true);
      } else {
        runInAction(() => {
          this.error = true;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => (this.isLoading = false));
    }
  };

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this);
  }
}
