import styles from "./errorMessage.module.scss";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores";

import { Link } from "react-router-dom";

import { ReactComponent as IconAttention } from "shared/assets/images/iconStatus/Attention.svg";
import { ReactComponent as IconCircleCheck } from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as IconNotice } from "shared/assets/images/iconStatus/Notice.svg";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand/iconExpand.svg";

import { Errors } from "stores/utils/types/ErrorsType";

type ErrorMessageType = {
  /**
   * Ошибка, которую необходимо отобразить
   */
  errors: Partial<Errors["message"]>;
  /**
   * Иконка, если она отличается от стандартной
   */
  errorIcon?: JSX.Element;
  /**
   * Слово, по которому разбивается каждый элемент списка body
   */
  splitItemLi?: string;
};

const errorIcons = {
  danger: <IconAttention className={styles.errors__icon_danger} />,
  success: <IconCircleCheck />,
  warning: <IconNotice />
};

// в переменной задаем сколько элементов из поля list будут отображаться по умолчанию
const LIMITLIST = 5;

const ErrorMessage = ({ errors, errorIcon, splitItemLi }: ErrorMessageType) => {
  const { menuStore } = useStores();
  const [isOpenedPanel, setIsOpenedPanel] = useState(false);
  // стейт определяет раскрыты или скрыты поля из поля list ошибки
  const [isOpenedList, setIsOpenedList] = useState(false);

  const getErrorsList = (
    error: Errors["message"]["body"]["key"],
    id: string,
    item: string
  ) => {
    return (
      <li className={styles.error__item} key={id}>
        {error.list.type === "link" ? (
          menuStore.openedModule === error.list.dir ||
          (menuStore.tabId.length &&
            menuStore.allWindows[`/${error.list.dir}`]) ? (
            <Link
              className={styles.error__item__link}
              id={id}
              to={`/${error.list.dir}/id=${id}`}
            >
              {item}
            </Link>
          ) : (
            <Link
              className={styles.error__item__link}
              id={id}
              to={`/${error.list.dir}/id=${id}`}
              target="_blank"
            >
              {item}
            </Link>
          )
        ) : !splitItemLi ? (
          item
        ) : (
          <div className={styles.itemList}>
            <div>{item.split(splitItemLi)[0]}</div>
            <div>{`${splitItemLi} ${item.split(splitItemLi)[1]}`}</div>
          </div>
        )}
      </li>
    );
  };

  return (
    <div className={styles.errors} data-error={true}>
      <div
        className={
          styles[
            `errors_${errors.color !== "default" ? errors.color : "danger"}`
          ]
        }
      >
        {errors.head ? (
          <div
            className={`${styles.errors__heading} ${
              errors.body && Object.values(errors.body).length > 1
                ? styles.errors__heading_hover
                : ""
            }`}
            id="ErrorMessageButton"
            onClick={() =>
              errors.body &&
              Object.values(errors.body).length > 1 &&
              setIsOpenedPanel(!isOpenedPanel)
            }
          >
            <div className={styles.errors__heading__center}>
              {errorIcon
                ? errorIcon
                : errors.color in errorIcons
                ? errorIcons[errors.color]
                : ""}

              <div>{errors.head}</div>
            </div>
            {errors.body && Object.values(errors.body).length > 1 ? (
              <IconExpand
                className={`${styles.errorIcon__expand} ${
                  isOpenedPanel ? styles.errorIcon__expand_opened : ""
                } ${
                  errors.color !== "default"
                    ? styles[`errorIcon_${errors.color}`]
                    : styles.errorIcon_danger
                }`}
              />
            ) : null}
          </div>
        ) : null}
        {errors.body
          ? (Object.values(errors.body).length > 1 && isOpenedPanel) ||
            Object.values(errors.body).length === 1
            ? Object.entries(errors.body).map(([key, error], i) => {
                return (
                  <div
                    className={styles.errors__body}
                    key={`${errors.head}_${key}`}
                  >
                    <div
                      className={`${styles.error} ${
                        !(i === 0 && !errors.head) &&
                        i < Object.entries(errors.body).length
                          ? styles.error_withBorder
                          : ""
                      }`}
                    >
                      <div className={styles.error__heading}>
                        <div>{error.head}</div>
                      </div>
                      {error.list ? (
                        <>
                          <ol className={styles.error__list}>
                            {error.list.body &&
                              Object.entries(error.list.body).map(
                                ([id, item], index) => {
                                  if (index > LIMITLIST - 1) {
                                    return;
                                  }
                                  return getErrorsList(error, id, item);
                                }
                              )}
                          </ol>
                          {Object.entries(error.list.body).length >
                          LIMITLIST ? (
                            <div
                              onClick={() => setIsOpenedList(!isOpenedList)}
                              className={styles.openedBtn}
                            >
                              {isOpenedList ? "Cкрыть" : "Показать больше"}
                            </div>
                          ) : (
                            ""
                          )}
                          {isOpenedList ? (
                            <ol
                              className={styles.error__list}
                              start={LIMITLIST + 1}
                            >
                              {error.list.body &&
                                Object.entries(error.list.body).map(
                                  ([id, item], index) => {
                                    if (index < LIMITLIST) {
                                      return;
                                    }
                                    return getErrorsList(error, id, item);
                                  }
                                )}
                            </ol>
                          ) : (
                            ""
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                );
              })
            : null
          : null}
      </div>
    </div>
  );
};

// делаем именнованный экспорт для компонентов обернутых в компоненты высшего порядка
// необходимо для документации storybook, так как он не работает с оберткой компонента
export const Message = ErrorMessage;

export default observer(ErrorMessage);
