import styles from "./aregisterAllReport.module.scss";
import { useState, useEffect, useRef } from "react";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";

import { Form, Formik } from "formik";
import Select from "shared/ui/Inputs/Select";
import DragAndDropSelect from "./DragAndDropSelect/index";
import LoadedComponent from "widgets/LoadedComponent";
import DateRangePicker from "shared/ui/Inputs/DateRangePicker";
import AregisterAllReportNewReportTable from "./AregisterAllReportNewReportTable/index";
import iconSearch from "shared/assets/images/mainIcons/iconSearch.svg";
import iconSearchActive from "shared/assets/images/mainIcons/iconSearchActive.svg";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonTheme } from "shared/ui/Button";
import { Chips, ChipsVariation } from "shared/ui/Chips";

const AregisterAllReport = () => {
  const { aregisterReportStore, menuStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [isOpenedMastersList, setIsOpenedMastersList] = useState(false);

  const ref = useRef<HTMLDivElement>();

  const changeOpenedListName = (name: string) => {
    openedListName === name ? setOpenedListName(null) : setOpenedListName(name);
  };

  useOnClickOutside({
    ref,
    handler: () => changeOpenedListName(null)
  });

  useEffect(() => {
    if (
      !Object.values(aregisterReportStore.projectsList).length &&
      !Object.values(aregisterReportStore.companiesList).length
    ) {
      aregisterReportStore.getData();
    }
    menuStore.updateWindow({
      mainPath: "/aregister",
      path: "/aregister/report",
      title: "Реестр заявок строительных лесов"
    });
    menuStore.setOpenedModule("aregister");
    menuStore.setOpenedSubmodule("report");
    menuStore.setSavedScroll();
  }, []);

  useEffect(() => {
    aregisterReportStore.getBrigadierPresaveList(
      aregisterReportStore.selectedOptions.brigadierId
    );
  }, [
    aregisterReportStore.selectedOptions.brigadierId,
    aregisterReportStore.brigadiersOfMaster
  ]);

  //отмена перерендера на Enter в окне поиска и при добавлении часов работникам
  const onKeyDown = (keyEvent: {
    charCode: number;
    keyCode: number;
    preventDefault: () => void;
  }) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const getPresaveList = (id: string) => {
    aregisterReportStore.resetAll();
    aregisterReportStore.setIsShowTable(false);
    aregisterReportStore.setSelectedOptions({
      masterId: id
    }),
      aregisterReportStore.setSelectedOptions({ brigadierId: "" }),
      aregisterReportStore.getMastersData();
    aregisterReportStore.setIsShowTable(true);
  };

  const filteredMastersList = Object.entries(
    aregisterReportStore.mastersList
  ).filter(([, master]) => master.has_presave);
  const mastersHasPresaveList = Object.fromEntries(filteredMastersList);

  return Object.values(aregisterReportStore.projectsList).length &&
    Object.values(aregisterReportStore.companiesList).length ? (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoadedComponent isLoading={aregisterReportStore.isLoading}>
        <Formik
          initialValues={{
            date_start: aregisterReportStore.selectedOptions.date_start,
            date_end: aregisterReportStore.selectedOptions.date_end,
            project_id: aregisterReportStore.selectedOptions.project_id,
            masters_list: Object.keys(mastersHasPresaveList)
          }}
          onSubmit={() => {
            return;
          }}
        >
          {({ values, setFieldTouched }) => {
            const showMastersList = () => {
              aregisterReportStore.setIsShowTable(false);
              aregisterReportStore.resetAll();
              aregisterReportStore.setSelectedOptions({
                masterId: ""
              });
              aregisterReportStore.setIsShowMasters(true);
              aregisterReportStore.getPresaveMasters(
                values.project_id,
                values.date_start,
                values.date_end
              );
            };
            return (
              <div className={styles.container}>
                <Form onKeyDown={onKeyDown}>
                  <div className={styles.topRowWrapper}>
                    <div className={styles.buildingDateContainer}>
                      <Select
                        required
                        name="project_id"
                        options={
                          aregisterReportStore.projectsList as {
                            [key: string]: {
                              title: string;
                              [key: string]: string | boolean;
                            };
                          }
                        }
                        onClick={() => {
                          setFieldTouched("project_id");
                        }}
                        onChange={(value) => {
                          aregisterReportStore.setSelectedOptions({
                            project_id: value
                          });
                        }}
                        title="Проект"
                        isSelectForTable
                        placeholderVisible
                        placeholder="Выберите проект"
                        valueName="id"
                        placement="bottom-start"
                      />
                      <div ref={ref}>
                        <DateRangePicker
                          monthsShown={2}
                          name="date_start"
                          title="Интервал дат"
                          isCalendarOpened={openedListName === "date_start"}
                          setIsCalendarOpened={() => {
                            changeOpenedListName("date_start");
                          }}
                          onChange={(value) => {
                            aregisterReportStore.setSelectedOptions(
                              value as { [key: string]: string }
                            );
                          }}
                          placeholderVisible
                          siblingDateName="date_end"
                          className={styles.datePickerCustom}
                          withInitialValue
                        />
                      </div>
                      <Button
                        type="submit"
                        theme={ButtonTheme.SECONDARY}
                        disabled={
                          !(
                            values.project_id &&
                            values.date_start &&
                            values.date_end
                          )
                        }
                        onClick={showMastersList}
                        id="AregisterAllReportNewReport_openFormBtn"
                      >
                        <img
                          src={
                            values.project_id &&
                            values.date_start &&
                            values.date_end
                              ? iconSearchActive
                              : iconSearch
                          }
                          alt="search"
                        />
                        Показать
                      </Button>
                    </div>
                    {aregisterReportStore.isShowTable && (
                      <div className={styles.filter}>
                        <div className={styles.filterCaption}>Заявки</div>
                        <div className="d-flex">
                          <Chips
                            variation={ChipsVariation.FILTER}
                            className={styles.filterBtnSelected}
                            title="Все"
                          />
                          <Chips
                            variation={ChipsVariation.FILTER}
                            className={styles.filterBtn}
                            title="Привязанные"
                          />
                          <Chips
                            variation={ChipsVariation.FILTER}
                            className={styles.filterBtn}
                            title="Не привязанные"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {(aregisterReportStore.isShowMasters ||
                    aregisterReportStore.selectedOptions.brigadierId) && (
                    <div className={styles.masters}>
                      <ErrorBoundary FallbackComponent={ErrorFallback}>
                        <DragAndDropSelect
                          name="masters_list"
                          options={
                            aregisterReportStore.mastersList as {
                              [key: string]: {
                                title: string;
                                id: string;
                              };
                            }
                          }
                          subOptions={aregisterReportStore.brigadiersOfMaster}
                          isMenuOpened={isOpenedMastersList}
                          setIsMenuOpened={() => {
                            setIsOpenedMastersList(!isOpenedMastersList);
                          }}
                          valueName="id"
                          onClickSelectedOption={getPresaveList}
                          onClickSelectedSubOption={(id) => {
                            aregisterReportStore.setSelectedOptions({
                              brigadierId: id
                            });
                          }}
                          setSubOptions={(subOptions) => {
                            aregisterReportStore.setBrigadiers(subOptions);
                          }}
                        />
                      </ErrorBoundary>
                    </div>
                  )}
                  {aregisterReportStore.isShowTable && (
                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                      <AregisterAllReportNewReportTable
                        date_start={values.date_start}
                        date_end={values.date_end}
                        project_id={values.project_id}
                      />
                    </ErrorBoundary>
                  )}
                  {!aregisterReportStore.isShowTable && (
                    <div className={styles.notification}>
                      <p className={styles.notification__text}>
                        Для просмотра отчёта мастера выберите проект, даты и
                        мастера
                      </p>
                    </div>
                  )}
                </Form>
              </div>
            );
          }}
        </Formik>
      </LoadedComponent>
    </ErrorBoundary>
  ) : null;
};

export default observer(AregisterAllReport);
