import styles from "./staffOneInfoHistoryOne.module.scss";
import { useStores } from "stores";
import { observer } from "mobx-react-lite";

import icon1C from "shared/assets/images/mainIcons/icon1C.svg";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Chips } from "shared/ui/Chips";

type StaffOneInfoHistoryOneProps = {
  time: string;
  user_name: string | number;
  historyItems: Array<{
    [key: string]: string | null | number;
  }>;
};

const StaffOneInfoHistoryOne = ({
  time,
  user_name,
  historyItems
}: StaffOneInfoHistoryOneProps) => {
  const { staffOneStore } = useStores();

  return (
    <div className={styles.storyWrapper}>
      <div className={styles.storyData}>
        <div className={styles.date}>
          {getFormattedDate(time).replace(/ /g, ", в ")}
        </div>
        <div className={styles.name}>
          {user_name === "crm_bot" ? (
            <img src={icon1C} alt="icon" />
          ) : (
            user_name
          )}
        </div>
      </div>
      {historyItems.map((item) => {
        const { id, text, company } = item;
        return (
          <div
            className="d-flex justify-content-start align-items-start"
            key={id}
          >
            {company && (
              <Chips
                className={
                  styles[
                    `company_${
                      Object.values(staffOneStore.companiesList).length
                        ? Object.values(staffOneStore.companiesList).find(
                            (companyData) => companyData.title === company
                          )?.color
                        : "primary"
                    }`
                  ]
                }
                title={company as string}
              />
            )}
            <div className={styles.actions}>{text}</div>
          </div>
        );
      })}
    </div>
  );
};

export default observer(StaffOneInfoHistoryOne);
