import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Col } from "stores/utils/types/Col";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { Salary } from "../types/Salary";
import { SelectModule } from "../types/Selects";
import { WithholdingEdu } from "../types/WithholdingEdu";
import dataJSON from "./staffOneSalary.json";
import { Company } from "../types/Company";
import { difference, without } from "lodash";

type BuildingType = {
  building_id: string;
  building_title: string;
  title: string;
  id: string;
  company_id: string;
  company_title: string;
};

type PaymentListItem = {
  uid: number;
  date_start: string;
  date_end: string;
  status: string;
  type: string;
  id: string;
  money: number;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
};

type PaymentListItemForTable = {
  id: string;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
  type: string;
  paymentlist: {
    uid: number;
    date_start: string;
    date_end: string;
    status: string;
    id: string;
    money: number;
  }[];
};

type PremiumType = {
  last_update: string;
  calculation_period: string;
  status: string;
  id: string;
  object: {
    id: string;
    title: string;
    company_id: string;
    company_title: string;
  };
  staff: {
    [key: string]: {
      sum_premium: number;
      fixed: number;
    };
  };
};

type LastOperationType = {
  id: string;
  type: string;
  author: {
    id: string;
    title: string;
  };
  money: number;
  time_spending: string;
  object: {
    id: string;
    title: string;
  };
  paymentlist: string;
};

type Selects = { title: string; id: string };

type FinanceMetrics = {
  metrics: {
    [key: string]: {
      title: string;
      value: number | string;
      money: boolean;
      table_title: string;
    };
  };
  color: string;
};

export default class StaffOneSalaryStore {
  error: { [key: string]: boolean } = {};
  isLoading: { [key: string]: boolean } = {};

  isLoadingForHoildingEdu: { [key: string]: boolean } = {};
  isLoadingForFinanceMetrics: { [key: string]: boolean } = {};
  errorsMessage: { [key: string]: Errors["message"] } = {};
  successMessage: { [key: string]: Errors["message"] } = {};
  onPage = 10;

  // список блоков с их состоянием (раскрыт/скрыт)
  conditionBlockList: { [key: string]: { [key: string]: boolean } } = {};

  // список метрик по финансам
  financeMetricsList: { [key: string]: { [key: string]: FinanceMetrics } } = {};
  salary: Partial<Salary["table"]> = {};
  staffRetention: { [id: string]: Partial<WithholdingEdu> } = {};

  // поля для таблицы ведомостей
  paymentList: {
    [key: string]: { [key: string]: PaymentListItemForTable };
  } = {};
  currentTitlesForPaymentList: string[] = [];
  colsForPaymentList: { [key: string]: Col } = {};
  paymentListTypes: SelectModule = {};
  paymentListStatuses: SelectModule = {};
  selectedCompanyForPaymentList: { [key: string]: string } = {};
  selectedTypeForPaymentList: { [key: string]: string } = {};
  isLoadingForPaymentList: { [key: string]: boolean } = {};
  // в этой переменной задаем порядок чередования колонок для таблицы ведомости
  orderTitlesForPaymentList = [
    "object",
    "type",
    "uid",
    "date_start",
    "date_end",
    "status",
    "money"
  ];

  // поля для таблицы премий
  premium: {
    [key: string]: { [key: string]: PremiumType };
  } = {};
  selectedCompanyForPremium: { [key: string]: string } = {};
  selectedStatusForPremium: { [key: string]: string } = {};
  currentTitlesForPremium: string[] = [];
  colsForPremium: { [key: string]: Col } = {};
  isLoadingForPremium: { [key: string]: boolean } = {};

  // поля для таблицы операций
  lastOperationList: { [key: string]: LastOperationType[] } = {};
  lastOperationCols: { [key: string]: Col } = {};
  selectedTypeFromOperations: { [key: string]: string } = {};
  isLoadingForOperations: { [key: string]: boolean } = {};
  currentTitlesForOperations: string[] = [];

  // список таблиц, которые могут сворачиваться
  // при разработке добавлять в этом массив в том порядке, что и на макете
  tableList = ["withholding_edu", "paymentlist", "premium", "operations"];
  fields: {
    retentionPPE?: { [key: string]: { [key: string]: string | number } };
    lastOperations?: {
      [key: string]: {
        [key: string]: string | number | { [key: string]: string };
      };
    };
  } = {};

  isOpenModal = false;
  scrollToEdu = false;

  // справочник типов операций
  operationTypesSelects: SelectModule = {};
  // справочник компаний для окрашивания плашек с названием
  companiesDict: { [key: string]: Company } = {};

  // справочник статусов для премий
  premiumStatusSelects: SelectModule = {};

  // доступные операции для сотрудника
  availableOperationTypes: {
    [key: string]: { [key: string]: Selects };
  } = {};
  //выбранная операция для добавления/редактирования
  selectedOperation = "";
  // список для поля Причина
  operationProperty: {
    [key: string]: { [key: string]: Selects };
  } = {};

  //список доступных объектов для сотрудников на опрееделенную дату
  buildingForStaff: { [key: string]: BuildingType } = {};

  // сумма для добавления вычета УЦ, приходит в методе salary/getWEduSettings
  money = 0;
  // количество смен для добавления вычета УЦ, приходит в методе salary/getWEduSettings
  ws_num = 0;

  nameOneOfStaff: { [key: string]: string } = {};

  getData = (uid: string) => {
    // скрываем все блоки по умолчанию
    if (!(uid in this.conditionBlockList)) {
      this.tableList.forEach((block) => {
        this.setConditionBlockList(uid, block, false);
      });
    }

    this.isLoading[uid] = true;
    Promise.all([
      !Object.keys(this.operationTypesSelects).length && this.getSelects(),
      !Object.values(this.companiesDict).length && this.getCompaniesList(),
      !Object.values(this.paymentListStatuses).length &&
        this.getPaymentListStatuses(),
      !Object.values(this.paymentListTypes).length &&
        this.getPaymentListTypes(),
      this.rootStore.staffOneStore.setSelectedOneForInfo(uid),
      this.setData(),
      this.getSettings()
    ]).then(() => {
      runInAction(() => {
        this.isLoading[uid] = false;
      });
    });
  };

  setData = () => {
    runInAction(() => {
      this.salary = dataJSON["table"];
      this.fields["lastOperations"] = dataJSON["lastOperations"];
    });
  };

  setIsOpenModal = (val: boolean) => {
    this.isOpenModal = val;
  };

  setNameOneOfStaff = (id: string, val: string) => {
    if (Object.keys(this.nameOneOfStaff).length) {
      this.nameOneOfStaff[id] = val;
    } else {
      this.nameOneOfStaff = {};
      this.nameOneOfStaff[id] = val;
    }
  };

  getFinanceMetrics = async (uid: string) => {
    this.isLoadingForFinanceMetrics[uid] = true;

    try {
      const data: ApiResponse<{ [key: string]: FinanceMetrics }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getFinanceMetrics",
          params: {
            uid: uid
          }
        });

      runInAction(() => {
        if (data["code"] === 200) {
          this.financeMetricsList[uid] = data["result"];
        } else {
          this.financeMetricsList[uid] = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.financeMetricsList[uid] = {};
      });
    } finally {
      runInAction(() => {
        this.isLoadingForFinanceMetrics[uid] = false;
      });
    }
  };

  getWithholdingEdu = async (uid: string) => {
    this.isLoadingForHoildingEdu[uid] = true;

    try {
      const data: {
        code: number;
        hash: string;
        ok: boolean;
        without_edu: boolean;
        widget: WithholdingEdu["widget"];
        statusList: WithholdingEdu["statusList"];
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWithholdingEduWidget",
        params: {
          uid: uid
        }
      });

      runInAction(() => {
        if (data["ok"]) {
          this.staffRetention[uid] = data;
        } else {
          this.staffRetention[uid] = { without_edu: data["without_edu"] };
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
      });
    } finally {
      runInAction(() => {
        this.isLoadingForHoildingEdu[uid] = false;
      });
    }
  };

  getPaymentList = async (uid: string) => {
    this.isLoadingForPaymentList[uid] = true;
    this.paymentList[uid] = {};
    try {
      const data: ApiResponse<{ [key: string]: PaymentListItem }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getPaymentList",
          on_page: this.onPage,
          params: {
            uid
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.setSelectedTypeForPaymentList(uid, "all");
          this.setSelectedCompanyForPaymentList(uid, "all");

          // получаем массив уникальных значений в виде "объект_тип ведомости"
          const objectAndTypeArray = Array.from(
            new Set(
              Object.values(data["result"]).map(
                (payment) => `${payment["object"]["id"]}_${payment["type"]}`
              )
            )
          );

          objectAndTypeArray.forEach((key) => {
            // выделяем отдельно объект и тип из строки вида "объект_тип ведомости"
            const [object, type] = key.split("_");
            // фильтруем массив значений data["records"], по объекту и по типу
            const filteredArray: PaymentListItem[] = Object.values(
              data["result"]
            ).filter(
              (payment: PaymentListItem) =>
                payment["object"]["id"] === object && payment["type"] === type
            );
            // записываем в удобный для таблицы вид
            this.paymentList[uid][key] = {
              id: filteredArray[0]["id"],
              object: filteredArray[0]["object"],
              type: filteredArray[0]["type"],
              paymentlist: filteredArray
            };
          });
        } else {
          this.paymentList[uid] = {};
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitlesForPaymentList = without(
            this.orderTitlesForPaymentList,
            ...difference(
              this.orderTitlesForPaymentList,
              Object.values(data["show_fields"])
            )
          );
          this.currentTitlesForPaymentList =
            this.currentTitlesForPaymentList.concat(
              difference(
                Object.values(data["show_fields"]),
                this.orderTitlesForPaymentList
              )
            );
        } else {
          this.currentTitlesForPaymentList = [];
        }
        if (Object.values(data["cols"]).length) {
          this.colsForPaymentList = data["cols"];
        } else {
          this.colsForPaymentList = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.paymentList[uid] = {};
        this.currentTitlesForPaymentList = [];
        this.colsForPaymentList = {};
      });
    } finally {
      runInAction(() => {
        this.isLoadingForPaymentList[uid] = false;
      });
    }
  };

  getPremium = async (uid: string) => {
    this.isLoadingForPremium[uid] = true;

    try {
      const data: ApiResponse<{ [key: string]: PremiumType }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getPremium",
          on_page: this.onPage,
          params: {
            uid
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.setSelectedStatusForPremium(uid, "all");
          this.setSelectedCompanyForPremium(uid, "all");
          this.premium[uid] = data["result"];
        } else {
          this.premium[uid] = {};
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitlesForPremium = Object.values(data["show_fields"]);
        } else {
          this.currentTitlesForPremium = [];
        }
        if (Object.values(data["cols"]).length) {
          this.colsForPremium = data["cols"];
        } else {
          this.colsForPremium = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.premium[uid] = {};
        this.currentTitlesForPremium = [];
        this.colsForPremium = {};
      });
    } finally {
      runInAction(() => {
        this.isLoadingForPremium[uid] = false;
      });
    }
  };

  getLastOperations = async (uid: string) => {
    this.isLoadingForOperations[uid] = true;
    this.error[uid] = false;

    try {
      const data: ApiResponse<{
        [key: string]: { salary_list: { [key: string]: LastOperationType } };
      }> = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getSalary",
        on_page: this.onPage,
        params: {
          uid
        }
      });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.setSelectedTypeFromOperations(uid, "all");
          this.lastOperationList[uid] = Object.values(
            data["result"][uid]["salary_list"]
          );
        } else {
          this.lastOperationList[uid] = [];
        }
        if (Object.values(data["cols"]).length) {
          this.lastOperationCols = data["cols"];
        } else {
          this.lastOperationCols = {};
        }
        if (Object.values(data["show_fields"]).length) {
          this.currentTitlesForOperations = Object.values(data["show_fields"]);
        } else {
          this.currentTitlesForOperations = [];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.lastOperationList[uid] = [];
        this.lastOperationCols = {};
        this.currentTitlesForOperations = [];
      });
    } finally {
      runInAction(() => {
        this.isLoadingForOperations[uid] = false;
      });
    }
  };

  getSelects = async () => {
    try {
      const data: ApiResponse<{ [key: string]: SelectModule }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getSelects"
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.operationTypesSelects = data["result"]["operation_type"];
          this.premiumStatusSelects = data["result"]["status_premium"];
          Object.values(this.operationTypesSelects)
            .filter(
              (operation) =>
                "property" in operation["custom"] &&
                Object.values(operation["custom"]["property"]).length
            )
            .forEach((operation) => {
              this.operationProperty[operation["id"]] = {};
              Object.entries(
                operation["custom"]["property"] as { [key: string]: string }
              ).forEach(([key, property]) => {
                this.operationProperty[operation["id"]][key] = {
                  title: property,
                  id: property
                };
              });
            });
        } else {
          this.operationTypesSelects = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  getPaymentListStatuses = async () => {
    try {
      const data: ApiResponse<SelectModule> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "paymentlist_status",
          method: "getList"
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.paymentListStatuses = data["result"];
        } else {
          this.paymentListStatuses = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  getPaymentListTypes = async () => {
    try {
      const data: ApiResponse<SelectModule> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          currentClass: "paymentlist_types",
          method: "getList"
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.paymentListTypes = data["result"];
        } else {
          this.paymentListTypes = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  getOperationTypesForUid = async (uid: string) => {
    try {
      const data: ApiResponse<{ [key: string]: string }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "salary",
          method: "getOperationTypesForUid",
          params: {
            uid
          }
        });

      runInAction(() => {
        if (Object.values(data["result"]).length) {
          this.availableOperationTypes[uid] = {};
          Object.values(data["result"]).forEach((operationID) => {
            this.availableOperationTypes[uid][operationID] = {
              title: this.operationTypesSelects[operationID]["title"],
              id: operationID
            };
          });
        } else {
          this.availableOperationTypes[uid] = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
      });
    }
  };

  getBuildingForStaff = async (uid: string, date: string) => {
    try {
      const data: ApiResponse<{ [key: string]: BuildingType } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "building",
          method: "getStaffBuildings",
          params: {
            uid,
            date
          }
        });

      runInAction(() => {
        if (data["result"] !== -1) {
          this.buildingForStaff = {};
          Object.entries(data["result"]).forEach(([id, building]) => {
            const tempObjectBuilding = {} as BuildingType;
            Object.entries(building).forEach(([key, value]) => {
              switch (key) {
                case "building_title":
                  tempObjectBuilding["title"] = value;
                  break;
                case "building_id":
                  tempObjectBuilding["id"] = value;
                  break;
                default:
                  tempObjectBuilding[key] = value;
              }
            });
            this.buildingForStaff[id] = tempObjectBuilding;
          });
        } else {
          this.buildingForStaff = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
      });
    }
  };

  addOperation = async (
    values: {
      [key: string]: string | number;
    },
    uid: string
  ) => {
    const formData: {
      [key: string]: string | number | string[];
    } = {};

    Object.entries(values).forEach(([key, value]) => {
      switch (key) {
        case "initiator":
          value ? (formData[key] = value) : delete values[key];
          break;
        case "money":
          formData[key] = +(value as string).replace(" ", "").replace(",", ".");
          break;
        default:
          formData[key] = value ?? "";
      }
    });

    try {
      const data: { ok: boolean; message: Errors["message"] } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "salary",
          method: "addOperation",
          body: { ...formData, uid }
        });

      runInAction(() => {
        if (data["ok"]) {
          this.getLastOperations(uid);
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.errorsMessage[uid]
            ? this.clearErrorsMessage(uid)
            : (this.errorsMessage[uid] = {} as Errors["message"]);
          this.successMessage[uid] = data["message"];
        } else {
          this.isLoadingForOperations[uid] = false;
          this.errorsMessage[uid] = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.isLoadingForOperations[uid] = false;
      });
    }
  };

  addHoldingEdu = async (
    values: {
      [key: string]: string | number;
    },
    uid: string
  ) => {
    const formData: {
      [key: string]: string | number | string[];
    } = {};

    Object.entries(values).forEach(([key, value]) => {
      switch (value) {
        case null:
          formData[key] = "";
          break;
        default:
          formData[key] = value;
      }
    });

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "addWEduRecord",
        body: { ...formData, uid }
      });

      runInAction(() => {
        if (data["result"]) {
          this.getWithholdingEdu(uid);
          this.rootStore.staffOneStore.setRebootStaff(uid, true);
          this.errorsMessage[uid]
            ? this.clearErrorsMessage(uid)
            : (this.errorsMessage[uid] = {} as Errors["message"]);
        } else {
          this.isLoadingForHoildingEdu[uid] = false;
          this.errorsMessage[uid] = data["message"];
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
        this.isLoadingForHoildingEdu[uid] = false;
      });
    }
  };

  setWithoutEdu = async (uid: string, act: "set" | "unset") => {
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "salary",
        method: "setWithoutEdu",
        body: { uid, act }
      });

      if (data["result"]) {
        this.getWithholdingEdu(uid);
        this.rootStore.staffOneStore.setRebootStaff(uid, true);
      } else {
        runInAction(() => {
          this.isLoadingForHoildingEdu[uid] = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error[uid] = true;
        this.isLoadingForHoildingEdu[uid] = false;
      });
    }
  };

  getSettings = async () => {
    try {
      const data: ApiResponse<boolean> & {
        default: {
          money: number;
          ws_num: number;
        };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "salary",
        method: "getWEduSettings"
      });

      runInAction(() => {
        if (data.result) {
          this.money = data.default.money;
          this.ws_num = data.default.ws_num;
        } else {
          this.error[this.rootStore.menuStore.tabId] = true;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  setSelectedOperation = (value: string) => {
    this.selectedOperation = value;
  };

  clearErrorsMessage = (id: string) => {
    delete this.errorsMessage[id];
    delete this.successMessage[id];
  };

  setScrollToEdu = (value: boolean) => {
    this.scrollToEdu = value;
  };

  getCompaniesList = async () => {
    try {
      const data: ApiResponse<{ [key: string]: Company } | -1> =
        await this.rootStore.apiStore.getData({
          requestMethod: "GET",
          baseClass: "company",
          currentClass: "company",
          method: "getList",
          select: ["id", "title", "color"]
        });

      runInAction(() => {
        if (data.result !== -1) {
          Object.values(data.result).forEach((company) => {
            this.companiesDict[company.id] = company;
          });
        } else {
          this.companiesDict = {};
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error[this.rootStore.menuStore.tabId] = true;
      });
    }
  };

  setSelectedTypeFromOperations = (uid: string, type: string) => {
    this.selectedTypeFromOperations[uid] = type;
  };

  setSelectedStatusForPremium = (uid: string, status: string) => {
    this.selectedStatusForPremium[uid] = status;
  };

  setSelectedCompanyForPaymentList = (uid: string, company: string) => {
    this.selectedCompanyForPaymentList[uid] = company;
  };

  setSelectedTypeForPaymentList = (uid: string, type: string) => {
    this.selectedTypeForPaymentList[uid] = type;
  };

  setSelectedCompanyForPremium = (uid: string, company: string) => {
    this.selectedCompanyForPremium[uid] = company;
  };

  setConditionBlockList = (uid: string, block: string, value: boolean) => {
    if (!(uid in this.conditionBlockList)) {
      this.conditionBlockList[uid] = {};
    }
    this.conditionBlockList[uid][block] = value;
  };

  rootStore: RootStore;
  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this, {});
  }
}
