import { observer } from "mobx-react-lite";
import { useStores } from "stores";
import styles from "./staffImportButtons.module.scss";
import { ReactComponent as IconAttention } from "shared/assets/images/iconStatus/Attention.svg";
import { ReactComponent as IconCircleCheck } from "shared/assets/images/iconStatus/CircleCheck.svg";
import { ReactComponent as IconNotice } from "shared/assets/images/iconStatus/Notice.svg";
import { ReactComponent as IconLeft } from "shared/assets/images/mainIcons/iconLeft.svg";
import { useEffect, useState } from "react";
import StatusIcon from "shared/ui/StatusIcon";
import StaffImportButtonsMessageWindow from "./StaffImportButtonsMessageWindow";
import StaffImportButtonsDuplicateWindow from "./StaffImportButtonsDuplicateWindow";
import { FormikErrors, useFormikContext } from "formik";
import {
  StaffImportTableForFormik,
  StaffImportTableType
} from "stores/StaffModule/types/StaffImportTable";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";
import { classNames } from "shared/utils/helpers/classNames";
import { getUnits } from "shared/utils/helpers/getUnits";
import { Errors } from "stores/utils/types/ErrorsType";
import { ErrorDictTitle } from "stores/StaffModule/staffImport";
import { Button, ButtonTheme } from "shared/ui/Button";

const errorIcons = {
  danger: <IconAttention className={styles.icon__danger} />,
  success: <IconCircleCheck />,
  warning: <IconNotice />
};

const StaffImportButtons = () => {
  const { staffImportStore } = useStores();
  const [isOpenedMessagePanel, setIsOpenedMessagePanel] = useState(false);
  const [isOpenedDuplicatePanel, setIsOpenedDuplicatePanel] = useState(false);
  const [isClarificationModal, setIsClarificationModal] = useState(false);

  const { isValid, values, errors } =
    useFormikContext<StaffImportTableForFormik>();

  const getErrorsText = (
    titleDict: ErrorDictTitle,
    row: number,
    cols: string
  ) => {
    const dict: Record<ErrorDictTitle, string> = {
      requiredFields: `В строке ${row} присутствуют незаполненные поля (${cols})`,
      validationError: `В строке ${row} присутствуют неверно заполненные поля (${cols})`,
      invalideResponse: `В строке ${row} найдены дублирующиеся поля (${cols})`,
      duplicateRow: ""
    };

    return dict[titleDict];
  };

  const getErrors = (titleDict: ErrorDictTitle, errors: string[][]) => {
    staffImportStore.setErrorsList(
      staffImportStore.errorsDict[titleDict],
      "delete"
    );

    const bodyError: Errors["message"]["body"]["key"]["list"]["body"] = {};

    errors?.forEach((error, index) => {
      if (error) {
        const fieldsWithTitle = error
          .map((title) => staffImportStore.cols[title])
          .join(error.length > 1 ? ", " : "");
        bodyError[index] = getErrorsText(titleDict, index + 1, fieldsWithTitle);
      }
    });

    const resultError: Errors["message"] = {
      ...staffImportStore.errorsDict[titleDict],
      body: {
        value: {
          head: "",
          list: {
            type: "text",
            body: bodyError
          }
        }
      }
    };

    return resultError;
  };

  const gerResponseErrors = () => {
    staffImportStore.setErrorsList(
      staffImportStore.errorsDict["invalideResponse"],
      "delete"
    );

    const bodyError: Errors["message"]["body"]["key"]["list"]["body"] = {};

    Object.entries(staffImportStore.staffStatus).forEach(([row, status]) => {
      if (status === "incorrectDouble") {
        const arrayOfTitles = staffImportStore.duplicateColsResponse[row];
        const fieldsWithTitle = arrayOfTitles
          .map((title) => staffImportStore.cols[title])
          .join(arrayOfTitles.length > 1 ? ", " : "");
        bodyError[row] = getErrorsText(
          "invalideResponse",
          Number(row) + 1,
          fieldsWithTitle
        );
      }
    });

    const resultError: Errors["message"] = {
      ...staffImportStore.errorsDict["invalideResponse"],
      body: {
        value: {
          head: "",
          list: {
            type: "text",
            body: bodyError
          }
        }
      }
    };

    return resultError;
  };

  // useEffect для вывода ошибок незаполненных обязательных полей
  useEffect(() => {
    const requiredFieldErrors = (
      errors["staff"] as FormikErrors<StaffImportTableType>[]
    )?.map((errorRow) => {
      const fieldWithError: string[] = [];
      Object.entries(errorRow || {}).forEach(([key, value]) => {
        if (value === "Обязательное поле") {
          fieldWithError.push(key);
        }
      });
      return fieldWithError.length ? fieldWithError : null;
    });

    if (requiredFieldErrors?.filter(Boolean).length) {
      // если есть незаполненные обязательные поля и при этом соответствующей ошибки в списке ошибок errorsList нет,
      // то добавляем эту ошибку в errorsList
      staffImportStore.setErrorsList(
        getErrors("requiredFields", requiredFieldErrors),
        "add"
      );
    } else {
      // если обязательные поля заполнены, то удаляем ошибку из errorsList
      staffImportStore.setErrorsList(
        staffImportStore.errorsDict["requiredFields"],
        "delete"
      );
    }
  }, [errors]);

  // useEffect для вывода ошибок валидации
  useEffect(() => {
    const errorsList = (
      errors["staff"] as FormikErrors<StaffImportTableType>[]
    )?.map((errorRow) => {
      const fieldWithError: string[] = [];
      Object.entries(errorRow || {}).forEach(([key, value]) => {
        if (value !== "Обязательное поле") {
          fieldWithError.push(key);
        }
      });
      return fieldWithError.length ? fieldWithError : null;
    });

    if (errorsList?.filter(Boolean).length) {
      // если есть незаполненные обязательные поля и при этом соответствующей ошибки в списке ошибок errorsList нет,
      // то добавляем эту ошибку в errorsList
      staffImportStore.setErrorsList(
        getErrors("validationError", errorsList),
        "add"
      );
    } else {
      // если обязательные поля заполнены, то удаляем ошибку из errorsList
      staffImportStore.setErrorsList(
        staffImportStore.errorsDict["validationError"],
        "delete"
      );
    }
  }, [errors]);

  // useEffect для вывода ошибок дубля с бэка
  useEffect(() => {
    if (
      Object.values(staffImportStore.staffStatus)?.filter(
        (status) => status === "incorrectDouble"
      ).length
    ) {
      // если есть в staffImportStore.staffStatus присутствует хоть у одной строки статус incorrectDouble,
      // то добавляем эту ошибку в errorsList
      staffImportStore.setErrorsList(gerResponseErrors(), "add");
    } else {
      // если таких статусов нет, то удаляем ошибку
      staffImportStore.setErrorsList(
        staffImportStore.errorsDict["invalideResponse"],
        "delete"
      );
    }
  }, [
    staffImportStore.staffStatus,
    staffImportStore.isLoadingDuplicateCheck,
    values
  ]);

  const toggleMessageWindow = () => {
    setIsOpenedMessagePanel(!isOpenedMessagePanel);
  };

  const checkDuplicateStaff = () => {
    staffImportStore.checkDuplicateStaff(values["staff"]);
  };

  const toggleDuplicatesWindow = () => {
    setIsOpenedDuplicatePanel(!isOpenedDuplicatePanel);
  };

  const addNewStaff = () => {
    staffImportStore.addNewStaff(values["staff"]);
  };

  const resetCurrentTable = () => {
    setIsClarificationModal(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.stickyContainer}>
        {staffImportStore.errorsList?.length ? (
          <div
            className={classNames(styles.errorsBlock, {}, [
              styles[`errorsBlock_${staffImportStore.errorsList[0]["color"]}`]
            ])}
            onClick={toggleMessageWindow}
            id="StaffImportButtons_show_message"
          >
            {errorIcons[staffImportStore.errorsList[0]["color"]]}
            <div className={styles.errorText}>
              {staffImportStore.errorsList.length > 1
                ? `${staffImportStore.errorsList.length} ${getUnits(
                    staffImportStore.errorsList.length,
                    "сообщение",
                    "соообщения",
                    "сообщений"
                  )}`
                : staffImportStore.errorsList[0]["head"]}
            </div>
            <IconLeft
              className={classNames(
                styles.icon__expand,
                { [styles.icon__expand_opened]: isOpenedMessagePanel },
                [
                  styles[
                    `expandIcon_${staffImportStore.errorsList[0]["color"]}`
                  ]
                ]
              )}
            />
          </div>
        ) : (
          ""
        )}
        <div className={styles.duplicateBlock}>
          <Button
            id="StaffImportButtons_check_duplicate"
            type="button"
            theme={ButtonTheme.SECONDARY}
            onClick={checkDuplicateStaff}
            disabled={
              !staffImportStore.colsForDuplicateCheck ||
              !isValid ||
              staffImportStore.isLoadingDuplicateCheck
            }
          >
            Проверить на дубли
          </Button>
          <Button
            id="StaffImportButtons_check_options"
            type="button"
            theme={ButtonTheme.SECONDARY}
            disabled={!staffImportStore.colsForDuplicateCheck}
            onClick={toggleDuplicatesWindow}
          >
            <StatusIcon
              icon="iconsettings"
              color={
                !staffImportStore.colsForDuplicateCheck
                  ? "bw-gray4"
                  : "blue-lazure"
              }
            />
          </Button>
        </div>
        <Button
          id="StaffImportButtons_add"
          type="button"
          onClick={addNewStaff}
          disabled={
            !Object.values(staffImportStore.staffStatus).some(
              (status) => status === "correct"
            ) ||
            !values["staff"].some((item) => item["select"]) ||
            staffImportStore.isLoadingDuplicateCheck
          }
        >
          Добавить выбранных
        </Button>
        <Button
          id="StaffImportButtons_reset"
          type="button"
          theme={ButtonTheme.SECONDARY}
          onClick={resetCurrentTable}
        >
          Новая загрузка
        </Button>
        <ClarificationModal
          show={isClarificationModal}
          onHide={() => {
            setIsClarificationModal(false);
          }}
          title="Вы уверены, что хотите загрузить новый файл?"
          subtitle="Новая загрузка приведёт к удалению текущих данных."
          btnWithCrossTitle="Загрузить новый файл"
          btnWithCrossOnClick={() => {
            setIsClarificationModal(false);
            staffImportStore.setDataForTable([]);
          }}
          btnBlueOnClick={() => setIsClarificationModal(false)}
          btnBlueTitle="Отмена"
          successBtn
        />
        {isOpenedMessagePanel ? (
          <StaffImportButtonsMessageWindow
            setIsOpenedPanel={setIsOpenedMessagePanel}
          />
        ) : (
          ""
        )}
        {isOpenedDuplicatePanel ? (
          <StaffImportButtonsDuplicateWindow
            setIsOpenedPanel={setIsOpenedDuplicatePanel}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default observer(StaffImportButtons);
