import styles from "./staffOneStatusesOnObject.module.scss";
import { observer } from "mobx-react-lite";

import { useStores } from "stores/index";

import StaffOneStatusesTable from "./StaffOneStatusesTable";
import StaffOneStatusesWindow from "./StaffOneStatusesWindow";
import StaffOneStatusesGraph from "./StaffOneStatusesGraph";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";

type StaffOneStatusesOnObjectProps = {
  selectedTab: string;
};

const StaffOneStatusesOnObject = ({
  selectedTab
}: StaffOneStatusesOnObjectProps) => {
  const { staffOneStatusesStore } = useStores();

  return staffOneStatusesStore.companiesTabs.length &&
    Object.values(staffOneStatusesStore.statusesCols).length ? (
    <div className={styles.wrapper}>
      {staffOneStatusesStore.selectedCompany ? (
        staffOneStatusesStore.openedWindow === "add" ? (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <div className={styles.windowContainer}>
              <div className={styles.window}>
                <StaffOneStatusesWindow />
              </div>
            </div>
          </ErrorBoundary>
        ) : null
      ) : null}
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        resetKeys={[selectedTab]}
      >
        <>
          {Object.values(staffOneStatusesStore.statusesList).length ? (
            selectedTab === "table" ? (
              <StaffOneStatusesTable />
            ) : (
              <StaffOneStatusesGraph />
            )
          ) : (
            <div className={styles.withoutStatuses}>
              Сотрудника не было ни на одном объекте
            </div>
          )}
        </>
      </ErrorBoundary>
    </div>
  ) : (
    <div className={styles.withoutStatuses}>
      Сотрудника не было ни на одном объекте
    </div>
  );
};

export default observer(StaffOneStatusesOnObject);
