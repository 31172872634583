import styles from "./staffOneEditFormObjectVersionWindow.module.scss";
import Select from "shared/ui/Inputs/Select";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useStores } from "stores/index";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import NumberFormat from "react-number-format";
import { Input } from "shared/ui/Inputs/Input";
import validation from "./validation";
import FormWindow from "shared/ui/FormWindow";
import { useState } from "react";
import icon1C from "shared/assets/images/mainIcons/icon1C.svg";
import { ReactComponent as IconBasket } from "shared/assets/images/mainIcons/iconBasket/iconBasketWithoutFill.svg";
import { useParams } from "react-router-dom";
import { EmploymentsItem } from "stores/StaffModule/types/RecordsForEdit";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

type StaffOneEditFormObjectVersionWindowProps = {
  setOpenWindow: () => void;
  field: string;
  title: string;
};

const StaffOneEditFormObjectVersionWindow = ({
  field,
  setOpenWindow,
  title
}: StaffOneEditFormObjectVersionWindowProps) => {
  const { staffOneEditStore, userStore } = useStores();
  const { id } = useParams();
  const [openedListName, setOpenedListName] = useState("");

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName(null) : setOpenedListName(name);
  };

  return (
    <div>
      <Formik
        initialValues={{
          start_date: null,
          start_time: "12:00",
          value: "",
          history:
            staffOneEditStore.selectedOneForEdit["company"][
              staffOneEditStore.selectedCompanyId[id]
            ]?.["employments"]?.[staffOneEditStore.selectedTypeOfJob[id]][
              `${field}_history`
            ] &&
            Object.values(
              staffOneEditStore.selectedOneForEdit["company"][
                staffOneEditStore.selectedCompanyId[id]
              ]["employments"][staffOneEditStore.selectedTypeOfJob[id]][
                `${field}_history`
              ] as EmploymentsItem
            ).sort((a, b) => {
              if (
                new Date(
                  `${b["start_date"]} ${b["start_time"] ? b["start_time"] : ""}`
                ).valueOf() !==
                new Date(
                  `${a["start_date"]} ${a["start_time"] ? a["start_time"] : ""}`
                ).valueOf()
              ) {
                return (
                  new Date(
                    `${b["start_date"]} ${
                      b["start_time"] ? b["start_time"] : ""
                    }`
                  ).valueOf() -
                  new Date(
                    `${a["start_date"]} ${
                      a["start_time"] ? a["start_time"] : ""
                    }`
                  ).valueOf()
                );
              }
              return (
                new Date(b["time_create"]).valueOf() -
                new Date(a["time_create"]).valueOf()
              );
            })
        }}
        enableReinitialize
        validationSchema={validation}
        onSubmit={() => {
          return;
        }}
      >
        {({
          dirty,
          values,
          initialValues,
          isValid,
          handleChange,
          handleBlur,
          setFieldValue
        }) => {
          const deleteItem = (itemId: string) => {
            setFieldValue(
              "history",
              values["history"].filter((item) => item.id !== itemId)
            );
          };

          const getInputs = (
            name: string,
            value: string | number,
            disabled?: boolean
          ) => {
            return Object.values(
              staffOneEditStore.staffTableParams[field]["variable"]
            ).length ? (
              <Select
                name={name}
                title={title}
                isFloating
                options={
                  field === "position"
                    ? (staffOneEditStore.staffTableParams[field]["variable"][
                        staffOneEditStore.selectedCompanyId[id]
                      ] as {
                        [key: string]: { newname: string; title: string };
                      })
                    : (staffOneEditStore.staffTableParams[field][
                        "variable"
                      ] as {
                        [key: string]: { newname: string; title: string };
                      })
                }
                valueName={field === "position" ? "id" : "newname"}
                disabled={disabled}
              />
            ) : staffOneEditStore.staffTableCols[field]["type"] === "float" ? (
              <NumberFormat
                name={name}
                value={value}
                label="Значение"
                onChange={handleChange}
                onBlur={handleBlur}
                customInput={Input}
                disabled={disabled}
              />
            ) : (
              <Input
                name={name}
                label="Значение"
                onChange={(event) => {
                  handleChange(event);
                }}
                disabled={disabled}
              />
            );
          };

          return (
            <FormWindow
              title={`${title} (${
                staffOneEditStore.allCompanies[
                  staffOneEditStore.selectedCompanyId[id]
                ]
              }) ${
                staffOneEditStore.selectedTypeOfJob[id]
                  ? `(${staffOneEditStore.selectedTypeOfJob[id]})`
                  : ""
              }`}
              setOpenWindow={() => setOpenWindow()}
              saveBtnTitle="Сохранить"
              saveBtnOnClickFunc={async () => {
                await staffOneEditStore.updateField(
                  staffOneEditStore.selectedOneForEdit["id"],
                  {
                    company: Object.keys(
                      staffOneEditStore.selectedOneForEdit["company"]
                    )
                  }
                );
                if (values["value"] && values["start_date"]) {
                  await staffOneEditStore.addOVData(
                    id,
                    field,
                    values.history
                      ? values.history[values.history?.length - 1]?.[
                          "staff_guid"
                        ] || ""
                      : "",
                    values["start_date"],
                    values["value"],
                    field === "position" ? values["start_time"] : null
                  );
                }
                values["history"]?.forEach((item) => {
                  const initialItem = initialValues["history"].filter(
                    (value) => value.id === item.id
                  )?.[0];
                  if (initialItem && initialItem.value !== item.value) {
                    staffOneEditStore.updateOVData(
                      id,
                      item["id"],
                      item["value"] as string
                    );
                  }
                });
                initialValues["history"]?.forEach((item) => {
                  if (
                    !values["history"]
                      .map((element) => element["id"])
                      .includes(item["id"])
                  ) {
                    staffOneEditStore.deleteOVData(id, item["id"]);
                  }
                });
              }}
              saveBtnDisabledValue={!dirty || !isValid}
              addBtnTitle="Отмена"
              isScroll
              fromOverlayWindow
            >
              <Form>
                <div className={styles.inputs}>
                  {getInputs("value", values.value)}
                  <DatePickerField
                    name="start_date"
                    title="Дата начала"
                    isCalendarOpened={openedListName === "start_date"}
                    setIsCalendarOpened={() => {
                      changeOpenedWindows("start_date");
                    }}
                  />
                  {field === "position" ? (
                    <NumberFormat
                      format="##:##"
                      name="start_time"
                      value={values["start_time"]}
                      label="Время начала"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      customInput={Input}
                    />
                  ) : (
                    ""
                  )}

                  <div className={styles.history__title}>История</div>
                  {values.history?.length ? (
                    <>
                      {Object.values(values.history).map((item, i) => {
                        const isDisabled =
                          !staffOneEditStore.allData.is_tech_access &&
                          userStore.user_id !== item.user?.toString();
                        return (
                          <div key={item.id} className={styles.storyData}>
                            <div className={styles.storyData__item}>
                              <div
                                className={`${styles.storyData__date} ${styles.storyData__date_withMargin}`}
                              >
                                Дата и время начала:
                              </div>
                              <div className={styles.storyData__date}>
                                {getFormattedDate(item.start_date)}
                              </div>
                              <div className={styles.storyData__date}>
                                {item.start_time}
                              </div>
                            </div>
                            <div
                              className={`${styles.storyData__item} ${styles.storyData__item_withMargin}`}
                            >
                              <div
                                className={`${styles.storyData__date} ${styles.storyData__date_withMargin}`}
                              >
                                Создано:
                              </div>
                              <div className={styles.storyData__date}>
                                {getFormattedDate(item.time_create)}
                              </div>
                              <div className={styles.storyData__name}>
                                {item.user_name === "crm_bot" ? (
                                  <img src={icon1C} alt="icon" />
                                ) : (
                                  item.user_name
                                )}
                              </div>
                            </div>
                            <div className={styles.storyData__inputBlock}>
                              <div className={styles.storyData__input}>
                                {getInputs(
                                  `history.${i}.value`,
                                  item.value,
                                  isDisabled
                                )}
                              </div>
                              {!isDisabled ? (
                                <Button
                                  type="button"
                                  className={styles.basketBtn}
                                  theme={ButtonTheme.SECONDARY}
                                  size={ButtonSize.S}
                                  id={`StaffOneEditFormObjectVersionWindow_deleteButton_${item.id}`}
                                  onClick={() => deleteItem(item.id)}
                                >
                                  <IconBasket className={styles.basketBtnImg} />
                                </Button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div className={styles.noHistory}>Нет данных</div>
                  )}
                </div>
              </Form>
            </FormWindow>
          );
        }}
      </Formik>
    </div>
  );
};

export default StaffOneEditFormObjectVersionWindow;
