import styles from "./aregisterFormFieldsGroupOneField.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";

import { useFormikContext } from "formik";

import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Switch from "shared/ui/Inputs/Switch";
import { Input } from "shared/ui/Inputs/Input";
import Select from "shared/ui/Inputs/Select";
import Textarea from "shared/ui/Inputs/Textarea";
import DateRangePicker from "shared/ui/Inputs/DateRangePicker";

import { Col } from "stores/StaffModule/types/Col";
import { parse } from "date-fns";

interface IColAppcreate extends Omit<Col, "directory"> {
  id?: string;
  required?: number;
  allow_manual_size?: number;
  allow_manual_hours?: number;
}

type AregisterFormFieldsGroupOneFieldProps = {
  name: string;
  values: { [key: string]: string | number };
  openedListName: string;
  changeOpenedWindows: (arg: string) => void;
  blockedFields: string[];
  type: string;
};

const AregisterFormFieldsGroupOneField = ({
  name,
  values,
  openedListName,
  changeOpenedWindows,
  blockedFields,
  type
}: AregisterFormFieldsGroupOneFieldProps) => {
  const { aregisterAppcreateStore, aregisterOneEditStore } = useStores();
  const { setFieldValue, handleChange, setFieldTouched, initialValues } =
    useFormikContext();

  const { id } = useParams();

  if (name.includes("custom_fields")) {
    const fieldName = name.slice(14, name.length);
    const fieldParam =
      aregisterAppcreateStore.projects[values.project]?.custom?.custom_fields?.[
        fieldName
      ];

    if (fieldParam) {
      switch (fieldParam.type) {
        case "select":
          return (
            <div className={styles.field}>
              <Select
                name={name}
                label={values[name] as string}
                title={fieldName}
                options={fieldParam.data}
                valueName="id"
                isFloating
              />
            </div>
          );

        case "date":
          return (
            <div className={styles.field}>
              <DatePickerField
                name={name}
                isCalendarOpened={openedListName === name}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(name);
                }}
                title={fieldName}
                bottom
              />
            </div>
          );

        case "text":
        default:
          return (
            <div className={styles.field}>
              <Input
                name={name}
                label={fieldName}
                onChange={handleChange}
                onClick={() => {
                  setFieldTouched(name);
                }}
              />
            </div>
          );
      }
    } else {
      return (
        <div className={styles.field}>
          <Input
            name={name}
            label={fieldName}
            onChange={handleChange}
            onClick={() => {
              setFieldTouched(name);
            }}
          />
        </div>
      );
    }
  } else {
    const field = aregisterAppcreateStore.cols[name];
    const params = aregisterAppcreateStore.params[name];

    if (field) {
      switch (name) {
        case "internal_num":
          return (
            <div className={styles.field}>
              <Input
                name={name}
                label={field.title}
                required={params.required}
                disabled={
                  params.editable !== "on" ||
                  (blockedFields.includes(name) && !values.project)
                }
                onChange={(e) => {
                  handleChange(e);
                  if (values.project) {
                    e.target.value?.length &&
                    `${initialValues[name]}` !== e.target.value
                      ? aregisterAppcreateStore.checkInternalNum(
                          type === "add" ? type : id,
                          values.project as string,
                          e.target.value
                        )
                      : aregisterAppcreateStore.deleteErrors(
                          type === "add" ? type : id,
                          name
                        );
                  }
                }}
                onClick={() => {
                  setFieldTouched(name);
                }}
              />
            </div>
          );
        case "project":
          return type === "add" ||
            (type === "edit" &&
              aregisterOneEditStore.editableFields[id].includes(name)) ? (
            <div className={styles.field}>
              <Select
                name={name}
                label={
                  values[name]
                    ? aregisterAppcreateStore.projects[values[name]]?.title
                    : field.title
                }
                title={field.title}
                options={
                  aregisterAppcreateStore.projects as {
                    [key: string]: { title: string };
                  }
                }
                valueName="id"
                isFloating
                required={params.required}
                disabled={
                  params.editable !== "on" ||
                  (blockedFields.includes(name) && !values.project)
                }
                onClick={(option: IColAppcreate) => {
                  setFieldValue(name, option.id);
                  setFieldValue("custom_fields", {});

                  Promise.all([
                    aregisterAppcreateStore.getCustomFields(option.id),
                    values.date_start &&
                      aregisterAppcreateStore.getAddRawCalcData(
                        option.id,
                        values.date_start as string
                      )
                  ]).then(() => {
                    if (
                      values.project !== option.id &&
                      aregisterAppcreateStore.projects[option.id]?.custom
                        ?.custom_fields &&
                      Object.values(
                        aregisterAppcreateStore.projects[option.id].custom
                          .custom_fields
                      ).length
                    ) {
                      const custom: { [key: string]: string | number } = {};

                      Object.keys(
                        aregisterAppcreateStore.projects[option.id].custom
                          .custom_fields
                      ).forEach((title) => {
                        custom[title] = null;
                      });

                      setFieldValue("custom_fields", custom);
                    }
                  });
                  if (
                    values.project !== option.id &&
                    values.date_start &&
                    values.date_end
                  ) {
                    setFieldValue("master", "masterless");
                    setFieldValue("brigadier", null);
                    new Promise(() =>
                      aregisterAppcreateStore.getMastersAndGangers(
                        option.id,
                        values.date_start as string,
                        values.date_end as string,
                        type === "add" ? type : id
                      )
                    ).then(() => {
                      setTimeout(() => {
                        setFieldTouched("master");
                        setFieldTouched("brigadier");
                      }, 500);
                    });
                  }

                  blockedFields.forEach((key) => {
                    switch (key) {
                      case "species":
                        setFieldValue(
                          key,
                          (option["scaffold_species"] !== "NULL" &&
                            option["scaffold_species"]?.[0]) ||
                            null
                        );
                        setTimeout(() => setFieldTouched(key), 200);
                        break;
                      case "type":
                        if (option["scaffold_type"] !== "NULL") {
                          option["scaffold_type"]?.[0] &&
                          aregisterAppcreateStore.params[key].variable[
                            option["scaffold_type"][0]
                          ]?.dismantling
                            ? setFieldValue("dismantling", 0)
                            : null;
                        }
                        setFieldValue(
                          key,
                          (option["scaffold_type"] !== "NULL" &&
                            option["scaffold_type"]?.[0]) ||
                            null
                        );
                        setTimeout(() => setFieldTouched(key), 200);
                        break;
                      case "type_work":
                      case "sub_work":
                      case "coodrinates":
                        setFieldValue(
                          key,
                          (option[key] !== "NULL" && option[key]?.[0]) || null
                        );
                        setTimeout(() => setFieldTouched(key), 200);
                        break;
                    }
                  });

                  if (option.allow_manual_size === 1) {
                    setFieldValue("manual_size", 0);
                  } else {
                    "manual_size" in values && delete values.manual_size;
                  }
                  if (
                    option["type_work"]?.[0] &&
                    aregisterAppcreateStore.params["type_work"].variable[
                      option["type_work"]?.[0]
                    ]?.custom?.block_size
                  ) {
                    setFieldValue("manual_size", 1);
                    setFieldTouched("manual_size");
                  }

                  if (option.allow_manual_hours === 1) {
                    setFieldValue("allow_manual_hours", 0);
                    setFieldValue("manual_hours", null);
                  } else {
                    "allow_manual_hours" in values &&
                      delete values.allow_manual_hours;
                    "manual_hours" in values && delete values.manual_hours;
                  }
                }}
              />
            </div>
          ) : null;
      }

      if (
        params?.isvariable === "on" ||
        name === "brigadier" ||
        name === "master"
      ) {
        return (
          <div className={styles.field}>
            <Select
              name={name}
              label={
                values[name]
                  ? params.variable[values[name]]?.title
                  : name === "coordinates"
                  ? "Не заполнено"
                  : field.title
              }
              title={field.title}
              options={aregisterAppcreateStore.setDirectory(
                values.project as string,
                name,
                type === "add" ? type : id
              )}
              valueName="id"
              isFloating
              required={params.required}
              disabled={
                params.editable !== "on" ||
                (blockedFields.includes(name) && !values.project)
              }
              onClick={(option: IColAppcreate) => {
                if (name === "type_work") {
                  if (!option["custom"].dismantling) {
                    setFieldValue("has_mount_reg", 0);
                    setFieldValue("mount", null);
                  }
                  if (
                    aregisterAppcreateStore.projects[values.project]
                      .allow_manual_size === 1
                  ) {
                    if (option["custom"]?.block_size) {
                      setFieldValue("manual_size", 1);
                      setFieldTouched("manual_size");
                    } else if (values.manual_size) {
                      setFieldValue("manual_size", 0);
                      setFieldTouched("manual_size");
                    }
                  } else if ("manual_size" in values) {
                    delete values.manual_size;
                  }

                  setFieldValue("size", null);
                }
              }}
              onChange={(value) => {
                if (
                  name === "type_work" &&
                  value &&
                  aregisterAppcreateStore.params[name].variable?.[value]?.custom
                    ?.dismantling
                ) {
                  aregisterAppcreateStore.getRecordToPair(
                    values.project as string
                  );
                }

                if (
                  aregisterAppcreateStore.projects[values.project]
                    ?.allow_manual_hours &&
                  name === "type_work" &&
                  value &&
                  aregisterAppcreateStore.params["type_work"].variable[value]
                    ?.custom?.is_hours &&
                  !("manual_hours" in values)
                ) {
                  setFieldValue("manual_hours", null);
                  setFieldValue("allow_manual_hours", 0);
                } else if (
                  !aregisterAppcreateStore.projects[values.project]
                    ?.allow_manual_hours &&
                  !aregisterAppcreateStore.params["type_work"].variable?.[
                    values.type_work
                  ]?.custom?.is_hours &&
                  "manual_hours" in values
                ) {
                  delete values.manual_hours;
                  delete values.allow_manual_hours;
                }
              }}
            />
          </div>
        );
      }

      switch (field.type) {
        case "date":
          if (name === "date_start" && "date_end" in values) {
            return (
              <div className={styles.field}>
                <DateRangePicker
                  name={name}
                  isCalendarOpened={openedListName === name}
                  setIsCalendarOpened={() => {
                    changeOpenedWindows(name);
                  }}
                  title={`${field.title} - ${aregisterAppcreateStore.cols["date_end"]?.title}`}
                  placeholderVisible
                  required={params.required}
                  disabled={
                    params.editable !== "on" ||
                    (blockedFields.includes(name) && !values.project)
                  }
                  monthsShown={2}
                  siblingDateName="date_end"
                  labelForMainField={field.title}
                  labelForSiblingField={
                    aregisterAppcreateStore.cols["date_end"]?.title
                  }
                  onChange={(value) => {
                    if (
                      ["date_start", "date_end"].includes(name) &&
                      values.project
                    ) {
                      setFieldValue("master", "masterless");
                      setFieldValue("brigadier", null);
                      new Promise(() =>
                        aregisterAppcreateStore.getMastersAndGangers(
                          values.project as string,
                          value["date_start"],
                          value["date_end"],
                          type === "add" ? type : id
                        )
                      ).then(() => {
                        setTimeout(() => {
                          setFieldTouched("master");
                          setFieldTouched("brigadier");
                        }, 500);
                      });
                    }

                    if (name === "date_start") {
                      values.project &&
                        aregisterAppcreateStore.getAddRawCalcData(
                          value["date_start"],
                          values.project as string
                        );
                    }
                  }}
                />
              </div>
            );
          }

          if (name === "date_end" && "date_start" in values) {
            return;
          }
          return (
            <div className={styles.field}>
              <DatePickerField
                name={name}
                isCalendarOpened={openedListName === name}
                setIsCalendarOpened={() => {
                  changeOpenedWindows(name);
                }}
                title={field.title}
                required={params.required}
                disabled={
                  params.editable !== "on" ||
                  (blockedFields.includes(name) && !values.project)
                }
                onChange={(value) => {
                  if (name === "date_start") {
                    (values.date_end &&
                      parse(value, "yyyy-MM-dd", new Date()) >
                        parse(
                          values.date_end as string,
                          "yyyy-MM-dd",
                          new Date()
                        )) ||
                    !values.date_end
                      ? setFieldValue("date_end", value)
                      : "";
                  }
                }}
                dateFormat={name === "kc" ? "MM.yyyy" : null}
                minDate={
                  name === "date_end" && values.date_start
                    ? parse(
                        values.date_start as string,
                        "yyyy-MM-dd",
                        new Date()
                      )
                    : null
                }
              />
            </div>
          );
        case "bool":
          return (
            <label className={styles.switch}>
              <Switch
                name={name}
                onChange={() => {
                  if (name === "no_kc")
                    setFieldValue("kc", initialValues["kc"]);
                }}
              />
              {field.title}
            </label>
          );
        default:
          return name.includes("comment") ? (
            <div className={styles.field}>
              <Textarea
                name={name}
                value={values[name] as string}
                label={field.title}
                placeholder={field.title}
                required={params.required}
                disabled={
                  params.editable !== "on" ||
                  (blockedFields.includes(name) && !values.project)
                }
                commentCol={aregisterAppcreateStore.cols[name]}
              />
            </div>
          ) : (
            <div className={styles.field}>
              <Input
                name={name}
                label={field.title}
                required={params.required}
                disabled={
                  params.editable !== "on" ||
                  (blockedFields.includes(name) && !values.project)
                }
                onChange={handleChange}
                onClick={() => {
                  setFieldTouched(name);
                }}
              />
            </div>
          );
      }
    }
  }
};
export default observer(AregisterFormFieldsGroupOneField);
