import { makeAutoObservable, runInAction } from "mobx";
import RootStore from "stores";
import { Selects } from "stores/StaffModule/types/Selects";
import { Col } from "stores/StaffModule/types/Col";
import { History } from "stores/StaffModule/types/History";
import {
  ICompany,
  IRecordsForEdit
} from "stores/StaffModule/types/RecordsForEdit";
import { Errors } from "stores/utils/types/ErrorsType";
import { ApiResponse } from "stores/utils/types/ApiResponse";
import { TableParams } from "../types/TableParams";
import { parse } from "date-fns";
import { startCase, without } from "lodash";
import { Status } from "../types/Status";

export type LoadingTypes = "phone" | "email" | "settings";
interface IColForEdit extends Col {
  disabled?: string;
}

interface IErrorStaffEdit extends Errors {
  from?: string;
}

type AllDataForEdit = {
  action: string;
  columns: { [key: string]: IColForEdit };
  company: { [key: string]: ICompany };
  company_fields: { [key: string]: string };
  groups: StaffGroups;
  record: IRecordsForEdit;
  selects: Selects;
  ov_cols: {
    [key: string]: {
      withHistory: number;
    };
  };
  table_params: { [key: string]: TableParams };
  is_tech_access: boolean;
  errors?: string;
  message?: Errors["message"];
};

type StaffGroups = {
  [key: string]: {
    columns?: { [key: string]: string };
    dadata?: unknown;
    icon?: string;
    id?: string;
    sort?: number;
    title: string;
  };
};

type HistoryData = {
  [key: string]: History;
};

export default class staffOneEditStore {
  error = false;
  errorText: { [key: string]: string } = {};
  isLoading = false;
  isLoadingSettings = false;
  isLoadingPhone = false;
  isLoadingEmail = false;
  isLoadingField = "";
  errorsMessage: { [key: string]: Partial<IErrorStaffEdit> } = {};

  allData: Partial<AllDataForEdit> = {};
  historyData: HistoryData = {};
  building_staff_events: {
    [key: string]: {
      [key: string]: { [key: string]: { [key: string]: Status } };
    };
  } = {};

  staffTableCols: { [key: string]: IColForEdit } = {};
  staffTableParams: {
    [key: string]: TableParams;
  };
  staffGroups: StaffGroups = {};

  selectedOneForEdit: Partial<IRecordsForEdit> = {};
  openedAllStaffForEdit: { [key: string]: Partial<IRecordsForEdit> } = {};

  allCompanies: { [key: string]: string } = {};

  // в этом поле хранится массив компаний после манипуляций с ними
  // для каждого сотрудника свой массив (ключ-id сотрудника, значение-массив компаний)
  currentCompanyList: { [key: string]: string[] } = {};

  // в этом поле хранится массив компаний, которые отдает метод getOne
  initialCompanyList: { [key: string]: string[] } = {};

  selectedCompanyId: { [key: string]: string } = {};
  lastStatus = "";
  selectedTypeOfJob: { [key: string]: string } = {};
  objectVersionColumns: AllDataForEdit["ov_cols"] = {};
  rootStore: RootStore;

  constructor(instance: RootStore) {
    this.rootStore = instance;
    makeAutoObservable(this, {});
  }

  setCurrentCompanyList = (uid: string, value: string[]) => {
    this.currentCompanyList[uid] = value;
  };

  setNewCompanyData = (key: string, value: ICompany) => {
    Object.keys(value["employments"][0]).forEach((field) => {
      if (Object.keys(this.selectedOneForEdit).includes(field)) {
        this.selectedOneForEdit[field] = value["employments"][0][field];
      }
    });
    this.selectedOneForEdit["company"] = {
      ...this.selectedOneForEdit["company"],
      ...{ [key]: value }
    };
  };

  setDeleteNewCompanyData = (id: string) => {
    this.selectedTypeOfJob[id] = "";
    Object.keys(this.selectedOneForEdit["company"])
      .filter((companyId) => !this.initialCompanyList[id].includes(companyId))
      .forEach((companyId) => {
        delete this.selectedOneForEdit["company"][companyId];
      });
    this.setSelectedCompanyId(
      id,
      Object.keys(this.selectedOneForEdit["company"])[0]
    );
  };

  setSelectedCompanyId = (
    uid: string,
    id: string,
    withEmployment?: boolean
  ) => {
    this.selectedCompanyId[uid] = id;

    const staffCode =
      this.selectedOneForEdit?.["company"] &&
      "employments" in this.selectedOneForEdit["company"][id] &&
      Object.entries(
        (
          this.selectedOneForEdit["company"] as {
            [key: string]: ICompany;
          }
        )[id]["employments"]
      ).sort((a, b) => {
        if (a[1].trud_dog.start_date === b[1].trud_dog.start_date) {
          return 0;
        } else if (a[1].trud_dog.start_date < b[1].trud_dog.start_date) {
          return 1;
        } else return -1;
      })[0][0];

    if (this.selectedOneForEdit["company"][id]) {
      if (this.selectedOneForEdit["company"][id]["employments"])
        this.setSelectedTypeOfJob(
          uid,
          !withEmployment
            ? this.selectedTypeOfJob[uid]
              ? this.selectedTypeOfJob[uid]
              : staffCode
            : staffCode
        );
    }
  };

  setSelectedTypeOfJob = (uid: string, id: string) => {
    this.selectedTypeOfJob[uid] = id;

    this.selectedOneForEdit["company"][this.selectedCompanyId[uid]]?.[
      "employments"
    ]?.[id] &&
      Object.entries(
        this.selectedOneForEdit["company"][this.selectedCompanyId[uid]][
          "employments"
        ][id]
      ).forEach(([key, value]) => {
        if (Object.keys(this.selectedOneForEdit).includes(key)) {
          this.selectedOneForEdit[key] =
            typeof value !== "object" ? value : value?.["value"];
        }
      });
  };

  setSelectedOneForEdit = (id: string) => {
    this.isLoading = true;
    this.error = false;

    if (!Object.values(this.rootStore.menuStore.allWindows).length) {
      this.rootStore.menuStore.addWindow("/staff", "Сотрудники");
    }

    if (!this.rootStore.menuStore.allWindows[`/staff/id=${id}`]) {
      this.rootStore.menuStore.addTabWindow(`/staff/id=${id}`, "Загрузка...");
      delete this.openedAllStaffForEdit[id];
    }

    if (
      Object.keys(this.openedAllStaffForEdit).length &&
      this.openedAllStaffForEdit[id]
    ) {
      if (Object.values(this.openedAllStaffForEdit[id]).length) {
        this.selectedOneForEdit = this.openedAllStaffForEdit[id]["record"];

        this.setSelectedCompanyId(
          id,
          this.selectedCompanyId[id]
            ? this.selectedCompanyId[id]
            : Object.keys(this.selectedOneForEdit["company"])[0]
        );

        this.setLastStatus(
          id,
          this.selectedCompanyId[id]
            ? this.selectedCompanyId[id]
            : Object.keys(this.selectedOneForEdit["company"])[0]
        );
      } else {
        this.error = true;
      }
      this.isLoading = false;
    } else this.getOneOfStaff(id);
  };

  updateField = async (
    id: string,
    values:
      | {
          [key: string]: string | string[] | number;
        }
      | { [key: string]: string }[],
    type?: LoadingTypes
  ) => {
    if (type && this[`isLoading${startCase(type)}`] !== undefined) {
      this[`isLoading${startCase(type)}`] = true;
    } else this.isLoading = true;

    const formData: {
      [key: string]: string | number;
    } = {};

    Object.entries(values).forEach(([key, value]) => {
      if (value !== null) {
        switch (key) {
          case "company":
            if (!(value as string[]).length) {
              formData["company[]"] = "";
            } else {
              Array.isArray(value) &&
                value.forEach((companyID, index) => {
                  formData[`company[${index}]`] =
                    (this.initialCompanyList[id].includes(companyID) ||
                      this.selectedCompanyId[id] === companyID) &&
                    companyID;
                });
            }
            break;
          case "hasSnils":
          case "companies":
            return;
          default:
            if (!Array.isArray(value)) {
              formData[key] = value;
            } else {
              value.forEach((element, index) => {
                formData[`${key}[${index}]`] = element;
              });
            }

            break;
        }
      }
    });

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        currentClass: "staff",
        method: "update",
        body: { ...formData, id: id }
      });

      if (data["result"]) {
        await this.getOneOfStaff(id, type || undefined);
        this.rootStore.staffOneStore.setRebootStaff(id, true);
        this.isLoadingSettings = false;
      } else {
        await this.getOneOfStaff(id);
        runInAction(() => {
          this.isLoading = false;
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  addOVData = async (
    rid: string,
    key: string,
    staff_guid: string,
    start_date: string,
    value: string,
    start_time?: string
  ) => {
    const formData = {
      rid,
      key,
      start_date,
      value: value ?? "",
      start_time: start_time ?? "",
      company: this.selectedCompanyId[rid],
      staff_code: this.selectedTypeOfJob[rid] || "",
      staff_guid
    };

    if (!formData["start_time"]) {
      delete formData["start_time"];
    }

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "addOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOneOfStaff(rid);
        this.rootStore.staffOneStore.setRebootStaff(rid, true);
      } else {
        await this.getOneOfStaff(rid);
        runInAction(() => {
          this.isLoading = false;
          this.errorsMessage[rid] = data;
          this.errorsMessage[rid]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  updateOVData = async (rid: string, id: string, value: string) => {
    const formData = {
      id,
      value
    };

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "updateOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOneOfStaff(rid);
        this.rootStore.staffOneStore.setRebootStaff(rid, true);
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  deleteOVData = async (rid: string, id: string) => {
    const formData = { id };

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "deleteOVData",
        body: formData
      });

      if (data["result"]) {
        this.getOneOfStaff(rid);
        this.rootStore.staffOneStore.setRebootStaff(rid, true);
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  getDataURLtoFile = async (dataurl: string, filename: string) => {
    const array = dataurl.split(",");
    const mime = array[0].match(/:(.*?);/)[1];
    const response = await fetch(dataurl);
    const blob = await response.blob();

    return new File([blob], filename, { type: mime });
  };

  setUploadPhoto = async (id: string, file: File) => {
    this.isLoading = true;

    try {
      const data: ApiResponse<{ hash: string; src: string }> =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "core",
          currentClass: "files",
          method: "uploadImage",
          params: { module: "core", hash: id },
          body: {
            file: file
          }
        });

      if (data["code"] === 200 && Object.values(data["result"]).length) {
        runInAction(() => {
          if (id) {
            this.getOneOfStaff(this.selectedOneForEdit["id"]);
            this.rootStore.staffOneStore.setRebootStaff(
              this.selectedOneForEdit["id"],
              true
            );
          } else {
            const newPhotoAlt: string[] = [];
            Object.values(this.selectedOneForEdit["photo_alt"]).map(
              (photo: { src: string | number; hash: string }) => {
                if (photo["src"] !== -1) {
                  newPhotoAlt.push(photo["hash"]);
                }
              }
            );
            newPhotoAlt.push(data["result"]["hash"]);

            this.updateField(this.selectedOneForEdit["id"], {
              photo_alt: newPhotoAlt
            });
          }
        });
      } else {
        runInAction(() => {
          this.errorsMessage[id]["message"] = data["message"];
          this.errorsMessage[id]["from"] = "photo";
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  makeAltPhotoTheMain = async (alt_photo_hash: string) => {
    this.isLoading = true;

    try {
      const data: { code: number; hash: string } =
        await this.rootStore.apiStore.getData({
          requestMethod: "POST",
          baseClass: "staff",
          method: "pickAltPhotoAsMain",
          body: {
            alt_photo_hash: alt_photo_hash
          }
        });

      if (data["code"] !== 404) {
        runInAction(() => {
          this.getOneOfStaff(this.selectedOneForEdit["id"]);
          this.rootStore.staffOneStore.setRebootStaff(
            this.selectedOneForEdit["id"],
            true
          );
        });
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  setDeletePhoto = async (hash: string) => {
    this.isLoading = true;
    const formData = { module: "core", hash: hash };
    try {
      const data: ApiResponse<number> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "core",
        currentClass: "files",
        method: "deleteAllFilesByHash",
        body: formData
      });
      if (data["result"]) {
        if (hash === this.selectedOneForEdit["id"]) {
          this.rootStore.staffOneStore.setRebootStaff(
            this.selectedOneForEdit["id"],
            true
          );
          this.getOneOfStaff(this.selectedOneForEdit["id"]);
        } else {
          const newPhotoAlt: string[] = [];
          Object.values(this.selectedOneForEdit["photo_alt"]).map(
            (photo: { src: string | number; hash: string }) => {
              if (photo["src"] !== -1 && photo["hash"] !== hash) {
                newPhotoAlt.push(photo["hash"]);
              }
            }
          );

          this.updateField(this.selectedOneForEdit["id"], {
            photo_alt: newPhotoAlt.length ? newPhotoAlt : ""
          });
        }
      } else {
        runInAction(() => {
          this.error = true;
          this.isLoading = false;
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoading = false;
      });
    }
  };

  getWidgetsForOneOfStaff = async (id: string) => {
    this.isLoading = true;

    try {
      const data: {
        widgets: {
          history: History;
          building_staff_events: {
            [key: string]: { [key: string]: { [key: string]: Status } };
          };
        };
        errors?: { [key: string]: string };
      } = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getWidgets",
        params: {
          staff_id: id,
          widgets: ["history", "building_staff_events"]
        }
      });

      if (!data.errors) {
        this.historyData[id] = data.widgets.history;
        this.building_staff_events[id] = data.widgets.building_staff_events;
      } else {
        this.error = true;
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  getOneOfStaff = async (id: string, type?: LoadingTypes) => {
    this.setErrorsMessage(id, {});
    try {
      const data: AllDataForEdit = await this.rootStore.apiStore.getData({
        requestMethod: "GET",
        baseClass: "staff",
        method: "getOne",
        params: {
          staff_id: id,
          action: "edit"
        }
      });

      if (!data.errors) {
        //следующие 4 строки удалить, когда поля инн, снилс и паспортных данных будут приходит корректно
        data["record"]["inn"] = data["record"]["inn"].slice(0, -1);
        data["record"]["snils"] = data["record"]["snils"].slice(0, -1);
        data["record"]["pasp_n"] = data["record"]["pasp_n"].slice(0, -1);
        data["record"]["pasp_code"] = data["record"]["pasp_code"].slice(0, -1);
        this.allData = data;
        runInAction(() => {
          Object.values(data["groups"]).forEach((group) => {
            this.staffGroups[group.id] = group;
          });
          this.staffGroups.appsettings = {
            id: "appsettings",
            title: "Настройки для приложения",
            icon: "appsettings"
          };

          this.staffTableCols = data["columns"];
          this.staffTableParams = data["table_params"];
          this.objectVersionColumns = data["ov_cols"];
          if (this.allData["company"]) {
            const companyData: { [key: string]: ICompany } = {};
            Object.entries(this.allData["company"]).forEach(
              ([key, company]) => {
                if (key && company.id) {
                  companyData[company.id] = company;
                }
              }
            );

            this.allData["company"] = companyData;
            this.allData["record"]["company"] = companyData;
          }

          if (
            data["table_params"] &&
            Object.keys(data["table_params"]).length
          ) {
            this.staffTableParams = data["table_params"];
            this.objectVersionColumns = data["ov_cols"];
            Object.entries(data["table_params"]).forEach(([name, col]) => {
              if (col.isvariable) {
                const param = {};

                Object.entries(col.variable).forEach(([key, variable]) => {
                  param[key] = {
                    ...variable,
                    newname: variable.id || variable.title
                  };
                });

                this.staffTableParams[name].variable = param;
              }
            });
          }

          if (Object.keys(data["selects"]).length) {
            Object.entries(data["selects"]).forEach(([key, value]) => {
              let directory = {};

              if (key === "position") {
                directory = value;
              } else {
                Object.values(value).forEach((item) => {
                  directory[item.id ? item.id : item.title] = {
                    newname: item.id ? item.id : item.title,
                    title: item.title
                  };
                });
              }
              Object.values(directory).length
                ? (this.staffTableParams[key] = {
                    ...this.staffTableParams[key],
                    variable: directory
                  })
                : "";
            });
          }

          Object.values(data["selects"]["company"]).forEach((item) => {
            this.allCompanies[item.id] = item.title;
          });

          this.rootStore.menuStore.updateTabWindow({
            mainPath: `/staff/id=${id}`,
            title: `${
              this.allData["record"]["surname"] !== null
                ? this.allData["record"]["surname"]
                : ""
            } ${
              this.allData["record"]["name"] !== null
                ? this.allData["record"]["name"]
                : ""
            }`
          });
        });
      } else {
        this.allData = {};
        this.rootStore.menuStore.updateTabWindow({
          mainPath: `/staff/id=${id}`,
          title: "Ничего не найдено"
        });
        runInAction(() => {
          this.error = true;
          // hardcode - пока приходит 200 код при ошибке "Не найден сотрудник"
          this.errorText[id] = data.message.head;
        });
      }
      this.getWidgetsForOneOfStaff(id);
      this.openedAllStaffForEdit[id] = this.allData;

      runInAction(() => {
        this.selectedOneForEdit = this.openedAllStaffForEdit[id]["record"]
          ? this.openedAllStaffForEdit[id]["record"]
          : {};

        this.initialCompanyList[id] = Object.keys(
          this.openedAllStaffForEdit[id]["record"]?.["company"]
        );
      });

      if (this.selectedOneForEdit["company"]) {
        this.setSelectedCompanyId(
          id,
          this.selectedCompanyId[id] &&
            this.selectedCompanyId[id] in this.selectedOneForEdit["company"]
            ? this.selectedCompanyId[id]
            : Object.keys(this.selectedOneForEdit["company"])[0]
        );
      }
      Promise.all([this.getWidgetsForOneOfStaff(id)]).then(() => {
        runInAction(() => {
          if (Object.keys(this.allData["company"]).length) {
            this.setLastStatus(
              id,
              this.selectedCompanyId[id]
                ? this.selectedCompanyId[id]
                : Object.keys(this.allData["company"])[0]
            );
          }

          if (type && this[`isLoading${startCase(type)}`] !== undefined) {
            this[`isLoading${startCase(type)}`] = false;
          }
          this.isLoading = false;
        });
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  resetPassword = async (id: string, number: string) => {
    this.errorsMessage = {};
    const bodyCredentials = {
      staff_id: id,
      phone: number
    };

    try {
      const data: ApiResponse<number> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        currentClass: "staff",
        method: "resetPassword",
        body: bodyCredentials
      });

      runInAction(() => {
        if (data["result"] !== -1) {
          if (data.errors) {
            this.errorsMessage[id].message.head = "Что-то пошло не так...";
          }
        }
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
      });
    }
  };

  checkSnils = async (value: string) => {
    this.isLoadingField = "snils";

    try {
      const data: IErrorStaffEdit = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "checkSnils",
        params: {
          snils: value
        }
      });

      runInAction(() => {
        this.errorsMessage[this.rootStore.menuStore.tabId] = data;
        this.isLoadingField = "";
      });
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingField = "";
      });
    }
  };

  setErrorsMessage = (id: string, value: Partial<IErrorStaffEdit>) => {
    this.errorsMessage[id] = value;
  };

  getRequiredCols = (
    cols: string[],
    data: Partial<IRecordsForEdit>
  ): string[] => {
    let requiredCols: string[] = [];

    cols.forEach((col) => {
      if (this.staffTableParams[col]?.required) {
        requiredCols.push(col);
      }
    });

    if (data.snils?.trim()?.length) {
      !requiredCols.includes("snils")
        ? (requiredCols = ["snils", ...requiredCols])
        : "";
    } else {
      requiredCols.includes("snils")
        ? (requiredCols = without(requiredCols, "snils"))
        : "";
    }

    return requiredCols;
  };

  setLastStatus = (id: string, company: string) => {
    this.lastStatus = "";
    if (
      this.building_staff_events[id]?.[company] &&
      Object.values(this.building_staff_events[id][company]).length &&
      Object.values(Object.values(this.building_staff_events[id][company])[0])
        .length
    ) {
      Object.values(
        Object.values(this.building_staff_events[id][company])[0]
      ).forEach((status) => {
        if (
          status["event_start"] &&
          parse(status["event_start"], "yyyy-MM-dd", new Date()) <= new Date()
        )
          return !this.lastStatus?.length
            ? (this.lastStatus = status["type"])
            : null;
      });
    }
  };

  editPhone = async (
    type: LoadingTypes,
    id: string,
    index: number,
    col: "comment" | "number",
    value: number | string,
    anotherColValue?: number | string
  ) => {
    if (this[`isLoading${startCase(type)}`] !== undefined) {
      this[`isLoading${startCase(type)}`] = true;
    }
    this.isLoadingPhone = true;
    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "editPhone",
        body: { id, index, col, value }
      });

      if (anotherColValue !== undefined) {
        this.editPhone(
          type,
          id,
          index,
          col === "comment" ? "number" : "comment",
          anotherColValue
        );
      } else if (data.result) {
        if (anotherColValue === undefined) {
          this.getOneOfStaff(id, type);
          this.rootStore.staffOneStore.setRebootStaff(id, true);
        }
      } else {
        this.getOneOfStaff(id, type);
        runInAction(() => {
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        if (this[`isLoading${startCase(type)}`] !== undefined) {
          this[`isLoading${startCase(type)}`] = false;
        }
      });
    }
  };

  deletePhone = async (id: string, index: number) => {
    this.isLoadingPhone = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "deletePhone",
        body: { id, index }
      });

      if (data.result) {
        await this.getOneOfStaff(id, "phone");
        this.rootStore.staffOneStore.setRebootStaff(id, true);
      } else {
        await this.getOneOfStaff(id);
        runInAction(() => {
          this.isLoading = false;
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingPhone = false;
      });
    }
  };

  addPhone = async (id: string, number: number | string, comment: string) => {
    this.isLoadingPhone = true;

    try {
      const data: ApiResponse<boolean> = await this.rootStore.apiStore.getData({
        requestMethod: "POST",
        baseClass: "staff",
        method: "addPhone",
        body: { id, number, comment }
      });

      if (data.result) {
        await this.getOneOfStaff(id, "phone");
        this.rootStore.staffOneStore.setRebootStaff(id, true);
      } else {
        await this.getOneOfStaff(id, "phone");
        runInAction(() => {
          this.errorsMessage[id] = data;
          this.errorsMessage[id]["from"] = "company";
        });
      }
    } catch (error) {
      runInAction(() => {
        this.error = true;
        this.isLoadingPhone = false;
      });
    }
  };
}
