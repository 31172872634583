import { Button, ButtonSize } from "shared/ui/Button";
import styles from "./formWindowHeader.module.scss";

import StatusIcon from "shared/ui/StatusIcon";

type FormWindowHeaderProps = {
  title: string;
  subtitle: string;
  closeWindowFunc: () => void;
};

const FormWindowHeader = ({
  title,
  subtitle,
  closeWindowFunc
}: FormWindowHeaderProps) => {
  return (
    <div className={styles.header}>
      <div>
        <div className={styles.header__title}>{title}</div>
        {subtitle ? (
          <div className={styles.header__subtitle}>{subtitle}</div>
        ) : (
          ""
        )}
      </div>

      <Button size={ButtonSize.S} onClick={closeWindowFunc}>
        <StatusIcon icon="iconclose" color="bw-white" />
      </Button>
    </div>
  );
};

export default FormWindowHeader;
