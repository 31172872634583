import styles from "./buildingOneInfoUsers.module.scss";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useStores } from "stores";

import { ReactComponent as IconExpand } from "shared/assets/images/mainIcons/iconExpand.svg";

const BuildingOneInfoUsers = () => {
  const { buildingOneStore } = useStores();
  const [isOpenedUsers, setIsOpenedUsers] = useState(false);

  return (
    <div className={styles.row}>
      <div className={styles.field}>
        <div className={`${styles.title} ${styles.titleWithBtn}`}>
          <p>{buildingOneStore.buildingCols.users?.["title"]}</p>
          {Object.values(buildingOneStore.selectedOne.users).length > 3 ? (
            <div
              className={`${styles.openedBtn} ${
                isOpenedUsers ? styles.openedBtn_open : ""
              }`}
              id="BuildingOneInfoUsers_openedBtn"
              onClick={() => setIsOpenedUsers(!isOpenedUsers)}
            >
              {isOpenedUsers ? <p>Скрыть</p> : <p>Показать всех</p>}{" "}
              <IconExpand />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={styles.value}>
          <span className={styles.chips}>
            {buildingOneStore.selectedOne.users &&
            buildingOneStore.variables?.users ? (
              Object.values(buildingOneStore.selectedOne.users).map(
                (item, ind) => {
                  if (
                    ind >= 3 &&
                    Object.values(buildingOneStore.selectedOne.users).length >
                      3 &&
                    !isOpenedUsers
                  )
                    return;

                  return (
                    <span key={item} className={styles.chips__one}>
                      {buildingOneStore.variables.users?.[item]?.title || item}
                    </span>
                  );
                }
              )
            ) : (
              <br />
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default observer(BuildingOneInfoUsers);
