import styles from "./aregisterForm.module.scss";
import { observer } from "mobx-react-lite";
import { useEffect, useState, Fragment } from "react";
import { useStores } from "stores/index";
import { useNavigate, useParams } from "react-router-dom";

import LoadedComponent from "widgets/LoadedComponent";
import { Formik } from "formik";
import { getValidationSchema } from "./validation";

import { Input } from "shared/ui/Inputs/Input";
import Switch from "shared/ui/Inputs/Switch";
import Select from "shared/ui/Inputs/Select";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";

import AregisterFormFieldsGroup from "./AregisterFormFieldsGroup";
import AregisterFormSize from "./AregisterFormSize";
import AregisterFormPanel from "./AregisterFormPanel";
import SuccessModal from "shared/ui/Modals/SuccessModal";

import iconArrow from "shared/assets/images/mainIcons/iconArrow.svg";

import { Application } from "stores/AregisterModule/types/Application";
import { Formula } from "stores/AregisterModule/types/Formula";
import { Button, ButtonTheme } from "shared/ui/Button";

type AregisterFormProps = {
  type: string;
  initialValues?: Partial<Application>;
};

// Поля, которые блокируются, пока не будет выбран проект
const blockedFields = [
  "contracts",
  "master",
  "type_work",
  "type",
  "species",
  "sub_work",
  "brigadier",
  "coordinates"
];

// Поля, которые не копируются из родительской заявки на монтаж в заявку на демонтаж
const pairBlockedFields = [
  "internal_num",
  "date_start",
  "date_end",
  "contracts",
  "master",
  "masterless",
  "brigadier",
  "type_work",
  "has_mount_reg",
  "mount_reg"
];

const AregisterForm = ({ type, initialValues }: AregisterFormProps) => {
  const { aregisterAppcreateStore } = useStores();
  const { id } = useParams();
  const [openedListName, setOpenedListName] = useState("");
  const [columns, setColumns] = useState<{ [key: string]: string[] }>({});
  const [savedValues, setSavedValues] = useState<
    { [key: string]: string | number } & {
      custom_fields?: { [key: string]: string | number };
    }
  >({});
  const [initialFormikValues, setInitialFormikValues] = useState<
    Partial<Application>
  >({});
  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);
  const [isLoadingMountReg, setIsLoadingMountReg] = useState(false);

  const changeOpenedWindows = (name: string) => {
    openedListName !== name ? setOpenedListName(name) : setOpenedListName(null);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (type === "edit") {
      aregisterAppcreateStore.getAddRawCalcData(
        initialValues.project as string,
        initialValues.date_start as string
      );
    }
  }, [id]);

  useEffect(() => {
    setColumns({});
    setInitialFormikValues({});

    type === "add" &&
      !Object.values(aregisterAppcreateStore.newAregisterObj).length &&
      aregisterAppcreateStore.setFormFields("add");

    const fields =
      type === "add"
        ? Object.keys(aregisterAppcreateStore.newAregisterObj)
        : Object.keys(initialValues);
    const sortFields: string[] = [];

    fields.forEach((field) => {
      if (
        (field === "internal_num" &&
          fields.includes("date_start") &&
          fields.includes("date_end")) ||
        (field === "date_start" &&
          fields.includes("internal_num") &&
          fields.includes("date_end")) ||
        (field === "date_end" &&
          fields.includes("internal_num") &&
          fields.includes("date_start"))
      ) {
        return (
          !sortFields.includes("internal_num") &&
          sortFields.push("internal_num")
        );
      }

      if (
        (field === "type" && fields.includes("type_work")) ||
        (field === "sub_work" && fields.includes("species")) ||
        [
          "width",
          "height",
          "length",
          "measurements",
          "conditional_cubes",
          "custom_fields",
          "create_report"
        ].includes(field)
      ) {
        return;
      }

      return sortFields.push(field);
    });

    const firstColCount = Math.floor(sortFields.length / 2);

    const firstCol: string[] = [];
    const secondCol: string[] = [];

    sortFields.forEach((key, i) => {
      if (!firstCol.includes(key) && i < firstColCount) {
        return firstCol.push(key);
      } else if (!secondCol.includes(key)) {
        return secondCol.push(key);
      }
    });

    setColumns({ firstCol: firstCol, secondCol: secondCol });

    setInitialFormikValues(
      type === "add" ? aregisterAppcreateStore.newAregisterObj : initialValues
    );

    if (
      type === "add" &&
      Object.values(aregisterAppcreateStore.newAregisterValues).length
    ) {
      setSavedValues(aregisterAppcreateStore.newAregisterValues);
    }

    if (
      type === "edit" &&
      aregisterAppcreateStore.openedAllAregisterValues[id]
    ) {
      setSavedValues(aregisterAppcreateStore.openedAllAregisterValues[id]);
    }
  }, [id]);

  useEffect(() => {
    setIsLoadingCustomFields(aregisterAppcreateStore.isLoadingCustomFields);
  }, [aregisterAppcreateStore.isLoadingCustomFields]);

  useEffect(() => {
    setIsLoadingMountReg(
      aregisterAppcreateStore.isLoadingField === "mount_reg"
    );
  }, [aregisterAppcreateStore.isLoadingField]);

  return (
    <LoadedComponent
      error={aregisterAppcreateStore.error}
      isLoading={aregisterAppcreateStore.isLoading}
    >
      <>
        {Object.values(aregisterAppcreateStore.cols).length &&
        Object.values(initialFormikValues).length ? (
          <Formik
            initialValues={initialFormikValues}
            onSubmit={() => {
              setOpenedListName("success_window");
            }}
            validateOnBlur
            validateOnChange
            validationSchema={getValidationSchema(
              Object.keys(
                type === "add"
                  ? aregisterAppcreateStore.newAregisterObj
                  : initialValues
              ),
              aregisterAppcreateStore.getRequiredFields(
                Object.keys(
                  type === "add"
                    ? aregisterAppcreateStore.newAregisterObj
                    : initialValues
                )
              )
            )}
            enableReinitialize
          >
            {({
              values,
              initialValues,
              dirty,
              errors,
              touched,
              handleReset,
              handleChange,
              setFieldValue,
              setFieldTouched
            }) => {
              let isTouched = false;

              const project = values.project
                ? aregisterAppcreateStore.params["project"].variable?.[
                    values.project
                  ]
                : null;

              const fillFormFromPairAregister = () => {
                if (
                  // Если в values есть поле mount_reg (заявка на монтаж)
                  values.mount_reg &&
                  // А также получена выбранная заявка на монтаж
                  aregisterAppcreateStore.aregisters[
                    values.mount_reg as string
                  ] &&
                  // И в ней есть данные
                  Object.values(
                    aregisterAppcreateStore.aregisters[
                      values.mount_reg as string
                    ]
                  ).length
                ) {
                  // Проходимся по все всей заявке на монтаж
                  Object.keys(values).forEach((name) => {
                    // Исключая поля, которые не копируются в заявку на демонтаж
                    if (!pairBlockedFields.includes(name)) {
                      // Есть поле имеет id (элементы каких-то списков)
                      if (
                        aregisterAppcreateStore.aregisters[
                          values.mount_reg as string
                        ][name]?.id
                      ) {
                        // Присваиваем в поля на демонтаж id из заявки на монтаж
                        setFieldValue(
                          name,
                          aregisterAppcreateStore.aregisters[
                            values.mount_reg as string
                          ][name].id
                        );
                      } else {
                        // Если id нет, то присваиваем просто значение
                        setFieldValue(
                          name,
                          // Из-за того, что Textarea выдает ошибку, если значение поля null
                          // проверяем является ли поле комментарием и значение null
                          name.includes("comment") &&
                            !aregisterAppcreateStore.aregisters[
                              values.mount_reg as string
                            ][name]
                            ? // И присваем значение - пустая строка
                              ""
                            : aregisterAppcreateStore.aregisters[
                                values.mount_reg as string
                              ][name]
                        );
                      }
                      setFieldTouched(name);
                    }
                  });
                }
              };

              // функция проверяет наличие ошибки в расчете размеров в зависимости от настройки check_all
              const checkSizeErrors = (formula: Partial<Formula>) => {
                if (formula && values.project && values["size"] !== null) {
                  // есть check_all === true, то если есть ошибка хотя бы в одном поле, то возвращаем true (ошибка)

                  // для полей размеров при check_all === true проверка нахождения значения в разрешенном диапазоне
                  // осуществляется в самом поле при изменении значения
                  if (formula.check_all) {
                    return Boolean(
                      errors["length"] || errors["width"] || errors["height"]
                    );
                  } else {
                    // есть check_all === false, то true нужно возвращать, если ошибки есть во всех полях

                    // для полей размеров при check_all === false проверка нахождения значения в разрешенном диапазоне
                    // осуществляется в этой функции ниже, т.к. ошибка будет только в том случае, если все поля нарушают органичения диапазона

                    // поля из формулы для проверки значения
                    const allFieldsForCheck: string[] = [];
                    // имена проверенных полей с ошибкой
                    const checkedFields: string[] = [];

                    // проходим по свойствам формулы и если для этой формулы есть ограничения диапазона
                    // записываем имя поля в allFieldsForCheck
                    Object.entries(formula).forEach(([name, value]) => {
                      // имена полей размеров
                      const fields = ["length", "width", "height"];

                      // проходимся по всем именам полей
                      fields.forEach((oneOfField) => {
                        // и если для поля есть min или max ограничение
                        if (
                          (name === `max_${oneOfField}` ||
                            name === `min_${oneOfField}`) &&
                          value
                        ) {
                          // записываем в allFieldsForCheck, если такое поле ещё не записано в массив
                          !allFieldsForCheck.includes(oneOfField) &&
                            allFieldsForCheck.push(oneOfField);
                        }
                      });
                    });

                    // далее проходимся по массиву имен полей,  значения которых нужно проверить
                    allFieldsForCheck.forEach((fieldForCheck) => {
                      // и если есть значение поля
                      if (values[fieldForCheck]) {
                        // проверяем это значение на соответствие диапазону значений
                        if (
                          (formula[`max_${fieldForCheck}`] &&
                            +values[fieldForCheck] >
                              formula[`max_${fieldForCheck}`]) ||
                          (formula?.[`min_${fieldForCheck}`] &&
                            +values[fieldForCheck] <
                              formula[`min_${fieldForCheck}`])
                        ) {
                          // если есть ошибка - записываем проверенное значение в checkedFields
                          checkedFields.push(fieldForCheck);
                        }
                      }
                    });

                    // если длина массива всех полей для проверки и длина массива проверенных полей с ошибкой совпадают
                    // возвращаем true (ошибка), иначе false
                    return allFieldsForCheck.length
                      ? allFieldsForCheck.length === checkedFields.length
                      : false;
                  }
                }
              };

              if (Object.values(savedValues).length && !isTouched) {
                Promise.all([
                  Object.entries(savedValues).forEach(([key, value]) =>
                    setFieldValue(key, value)
                  )
                ]).then(() => {
                  setTimeout(() => setSavedValues({}), 500);
                });
              } else if (isTouched) {
                type === "add" &&
                  aregisterAppcreateStore.setNewAregisterValues(values);
                type === "edit" &&
                  aregisterAppcreateStore.setOpenedAllAregisterValues(
                    values,
                    id
                  );
              }

              if (dirty || (Object.values(touched).length && !isTouched)) {
                isTouched = true;
              }

              return (
                <>
                  <div className={styles.formContainer}>
                    {Object.entries(columns).length
                      ? Object.entries(columns).map(([column, fields]) => {
                          return (
                            <div key={column}>
                              {fields.map((key) => {
                                if (
                                  project?.custom &&
                                  Object.values(project.custom).find(
                                    (value) => value["title"] === key
                                  )
                                )
                                  return;

                                switch (key) {
                                  case "internal_num":
                                    return (
                                      <AregisterFormFieldsGroup
                                        key={key}
                                        keys={
                                          ("date_start" in values &&
                                            "date_end" in values) ||
                                          "date_start" in values
                                            ? [key, "date_start"]
                                            : "date_end" in values
                                            ? [key, "date_end"]
                                            : [key]
                                        }
                                        values={values}
                                        openedListName={openedListName}
                                        changeOpenedWindows={
                                          changeOpenedWindows
                                        }
                                        blockedFields={blockedFields}
                                        type={type}
                                      />
                                    );
                                  case "type_work":
                                  case "type":
                                    if (key === "type" && "type_work" in values)
                                      return;
                                    return (
                                      <AregisterFormFieldsGroup
                                        key={key}
                                        keys={
                                          key === "type_work" &&
                                          "type" in values
                                            ? [key, "type"]
                                            : [key]
                                        }
                                        values={values}
                                        openedListName={openedListName}
                                        changeOpenedWindows={
                                          changeOpenedWindows
                                        }
                                        blockedFields={blockedFields}
                                        type={type}
                                      />
                                    );
                                  case "species":
                                  case "sub_work":
                                    if (
                                      key === "sub_work" &&
                                      "species" in values
                                    )
                                      return;
                                    if (
                                      values.type_work &&
                                      aregisterAppcreateStore.params[
                                        "type_work"
                                      ].variable?.[values.type_work]?.custom
                                        .dismantling &&
                                      !("has_mount_reg" in values) &&
                                      type !== "edit"
                                    ) {
                                      setFieldValue("has_mount_reg", 0);
                                    }
                                    return (
                                      <div key={key}>
                                        <AregisterFormFieldsGroup
                                          keys={
                                            key === "species" &&
                                            "sub_work" in values
                                              ? [key, "sub_work"]
                                              : [key]
                                          }
                                          values={values}
                                          openedListName={openedListName}
                                          changeOpenedWindows={
                                            changeOpenedWindows
                                          }
                                          blockedFields={blockedFields}
                                          type={type}
                                        />
                                        {type === "add" &&
                                        values.type_work &&
                                        aregisterAppcreateStore.params[
                                          "type_work"
                                        ].variable[values.type_work]?.custom
                                          .dismantling ? (
                                          <div key="has_mount_reg">
                                            <label className={styles.switch}>
                                              <Switch
                                                name="has_mount_reg"
                                                onChange={() => {
                                                  if (
                                                    !(
                                                      "mount_reg" in
                                                      initialValues
                                                    )
                                                  ) {
                                                    setFieldValue(
                                                      "mount_reg",
                                                      null
                                                    );
                                                    setFieldTouched(
                                                      "mount_reg"
                                                    );
                                                  } else {
                                                    delete values["mount_reg"];
                                                  }
                                                }}
                                              />
                                              Есть заявка на монтаж
                                            </label>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        {values["has_mount_reg"] ? (
                                          <LoadedComponent
                                            isLoading={isLoadingMountReg}
                                          >
                                            <div className={styles.mountField}>
                                              <Select
                                                name="mount_reg"
                                                label={
                                                  values.mount_reg
                                                    ? aregisterAppcreateStore
                                                        .pairsList[
                                                        values.project as string
                                                      ][values.mount_reg]?.title
                                                    : "Заявка на монтаж"
                                                }
                                                title="Заявка на монтаж"
                                                options={
                                                  aregisterAppcreateStore
                                                    .pairsList[
                                                    values.project as string
                                                  ]
                                                    ? aregisterAppcreateStore
                                                        .pairsList[
                                                        values.project as string
                                                      ]
                                                    : {}
                                                }
                                                onClick={(option) => {
                                                  aregisterAppcreateStore.getOneOfAregister(
                                                    option.id as string
                                                  );
                                                }}
                                                isFloating
                                                valueName="id"
                                              />
                                              <Button
                                                id="AregisterForm_FillFromPair"
                                                theme={ButtonTheme.SECONDARY}
                                                onClick={() => {
                                                  fillFormFromPairAregister();
                                                }}
                                              >
                                                <img
                                                  src={iconArrow}
                                                  className={styles.iconArrow}
                                                />
                                                Заполнить форму
                                              </Button>
                                            </div>
                                          </LoadedComponent>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    );
                                  case "date_start":
                                  case "date_end":
                                    if ("internal_num" in values) return;
                                    if (
                                      key === "date_end" &&
                                      "date_start" in values
                                    )
                                      return;
                                    return (
                                      <AregisterFormFieldsGroup
                                        key={key}
                                        keys={
                                          key === "date_start" &&
                                          "date_end" in values
                                            ? [key, "date_end"]
                                            : [key]
                                        }
                                        values={values}
                                        openedListName={openedListName}
                                        changeOpenedWindows={
                                          changeOpenedWindows
                                        }
                                        blockedFields={blockedFields}
                                        type={type}
                                      />
                                    );
                                  case "size":
                                    return (
                                      <Fragment key={key}>
                                        {values["manual_size"] ? (
                                          <div className={styles.field}>
                                            <div className={styles.sizes}>
                                              {
                                                aregisterAppcreateStore.cols[
                                                  "measurements"
                                                ].title
                                              }
                                            </div>
                                            <Input
                                              name="size"
                                              label="Сумма"
                                              onChange={handleChange}
                                            />
                                          </div>
                                        ) : (
                                          <AregisterFormSize
                                            requiredFields={aregisterAppcreateStore.getRequiredFields(
                                              Object.keys(values)
                                            )}
                                            values={values}
                                            checkSizeErrors={checkSizeErrors}
                                          />
                                        )}
                                        {values.project &&
                                        aregisterAppcreateStore.projects[
                                          values.project
                                        ]?.allow_manual_size === 1 ? (
                                          <Fragment>
                                            <div className={styles.field}>
                                              <label className={styles.switch}>
                                                <Switch
                                                  name="manual_size"
                                                  onChange={(e) => {
                                                    setFieldValue(
                                                      "size",
                                                      e.target.checked &&
                                                        initialValues.manual_size
                                                        ? initialValues.size
                                                        : !e.target.checked &&
                                                          initialValues.size
                                                        ? initialValues.size
                                                        : null
                                                    );
                                                    setFieldTouched(
                                                      "size",
                                                      false,
                                                      false
                                                    );
                                                  }}
                                                />
                                                {
                                                  aregisterAppcreateStore.cols[
                                                    "manual_size"
                                                  ].title
                                                }
                                              </label>
                                            </div>
                                          </Fragment>
                                        ) : (
                                          ""
                                        )}
                                      </Fragment>
                                    );

                                  case "project":
                                    return (
                                      <AregisterFormFieldsGroup
                                        key={key}
                                        keys={[key]}
                                        values={values}
                                        openedListName={openedListName}
                                        changeOpenedWindows={
                                          changeOpenedWindows
                                        }
                                        blockedFields={blockedFields}
                                        type={type}
                                      />
                                    );
                                  case "kc":
                                    if (!values.no_kc) {
                                      return (
                                        <AregisterFormFieldsGroup
                                          key={key}
                                          keys={[key]}
                                          values={values}
                                          openedListName={openedListName}
                                          changeOpenedWindows={
                                            changeOpenedWindows
                                          }
                                          blockedFields={blockedFields}
                                          type={type}
                                        />
                                      );
                                    }
                                    break;
                                  case "act_hours":
                                    return (
                                      <Fragment key={key}>
                                        {values.project &&
                                        aregisterAppcreateStore.projects[
                                          values.project
                                        ]?.allow_manual_hours === 1 ? (
                                          <>
                                            <div className={styles.field}>
                                              <Input
                                                name="manual_hours"
                                                label={
                                                  aregisterAppcreateStore.cols[
                                                    "manual_hours"
                                                  ].title
                                                }
                                                onChange={handleChange}
                                                disabled={
                                                  !values["allow_manual_hours"]
                                                }
                                              />
                                            </div>

                                            <div className={styles.field}>
                                              <label className={styles.switch}>
                                                <Switch
                                                  name="allow_manual_hours"
                                                  onChange={(e) => {
                                                    if (
                                                      values.manual_hours &&
                                                      !e.target.checked
                                                    ) {
                                                      setFieldValue(
                                                        "manual_hours",
                                                        null
                                                      );
                                                      setFieldTouched(
                                                        "manual_hours"
                                                      );
                                                    }
                                                  }}
                                                />
                                                Использовать
                                              </label>
                                            </div>
                                          </>
                                        ) : null}
                                        <AregisterFormFieldsGroup
                                          key={key}
                                          keys={[key]}
                                          values={values}
                                          openedListName={openedListName}
                                          changeOpenedWindows={
                                            changeOpenedWindows
                                          }
                                          blockedFields={blockedFields}
                                          type={type}
                                        />
                                      </Fragment>
                                    );
                                  case "custom_fields":
                                  case "manual_hours":
                                  case "manual_size":
                                    return;
                                  default:
                                    return (
                                      <AregisterFormFieldsGroup
                                        key={key}
                                        keys={[key]}
                                        values={values}
                                        openedListName={openedListName}
                                        changeOpenedWindows={
                                          changeOpenedWindows
                                        }
                                        blockedFields={blockedFields}
                                        type={type}
                                      />
                                    );
                                }
                              })}
                            </div>
                          );
                        })
                      : null}
                  </div>

                  <LoadedComponent isLoading={isLoadingCustomFields}>
                    {values.custom_fields &&
                    Object.keys(values.custom_fields).length ? (
                      <div className={styles.subFields}>
                        <div className={styles.subFields__heading}>
                          Дополнительные поля
                        </div>
                        <div className={styles.subFields__fields}>
                          {Object.keys(values.custom_fields).map((key) => {
                            return (
                              <AregisterFormFieldsGroup
                                key={key}
                                keys={[`custom_fields.${key}`]}
                                values={values}
                                openedListName={openedListName}
                                changeOpenedWindows={changeOpenedWindows}
                                blockedFields={blockedFields}
                                type={type}
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </LoadedComponent>

                  <AregisterFormPanel
                    type={type}
                    checkSizeErrors={checkSizeErrors}
                  />
                  {type === "edit" ? (
                    <SuccessModal
                      successIcon
                      show={openedListName === "success_window"}
                      onHide={() => {
                        handleReset();
                        changeOpenedWindows("success_window");
                      }}
                      title="Изменения сохранены"
                    />
                  ) : type === "add" ? (
                    <ClarificationModal
                      successIcon
                      show={openedListName === "success_window"}
                      onHide={() => {
                        handleReset();
                        changeOpenedWindows("success_window");
                      }}
                      title="Заявка создана"
                      btnWithCrossTitle="В общий список"
                      btnWithCrossOnClick={() => {
                        navigate("/aregister");
                      }}
                      btnBlueOnClick={() => {
                        // при разработке добавить navigate к созданной заявке и удалить handleReset()
                        handleReset();
                        changeOpenedWindows("success_window");
                      }}
                      btnBlueTitle="К заявке"
                      btnWithCrossBtnImg
                    />
                  ) : null}
                </>
              );
            }}
          </Formik>
        ) : null}
      </>
    </LoadedComponent>
  );
};

export default observer(AregisterForm);
