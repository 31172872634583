import styles from "./buildingOneStaffWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useState, useEffect } from "react";

import { Formik } from "formik";

import StaffOneStatusesForm from "features/StaffOneStatusesForm";
import FormWindow from "shared/ui/FormWindow";
import BuildingOneStaffWindowTabs from "./BuildingOneStaffWindowTabs";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "shared/utils/validation/validationStaffForm";

import { Staff } from "stores/BuildingModule/types/BuildingStaffType";
import { InitialValuesForStatus } from "stores/BuildingModule/buildingOne/buildingOneStaff";

type BuildingOneStaffWindowProps = {
  setOpenWindow: (el: boolean) => void;
  staff_data: Staff;
};

const BuildingOneStaffWindow = ({
  setOpenWindow,
  staff_data
}: BuildingOneStaffWindowProps) => {
  const { buildingOneStore, buildingOneStaffStore } = useStores();
  const [openedListName, setOpenedListName] = useState("");
  const [requiredFields, setRequiredFields] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    setRequiredFields(
      buildingOneStaffStore.statusesGroups[buildingOneStaffStore.selectedStatus]
        ?.required_column
        ? buildingOneStaffStore.statusesGroups[
            buildingOneStaffStore.selectedStatus
          ].required_column
        : {}
    );
  }, [
    buildingOneStaffStore.statusesGroups[buildingOneStaffStore.selectedStatus]
      ?.required_column
  ]);

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  const getFieldsForValidation = () => {
    let fields = Object.keys(
      buildingOneStaffStore.statusesInitialValues[
        buildingOneStaffStore.selectedStatus
      ]
    );

    if (
      buildingOneStaffStore.eventTypes[buildingOneStaffStore.selectedStatus]
        ?.custom?.close_withholding &&
      buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id]?.result
    ) {
      fields = [...fields, "money", "date", "without_edu"];
    }
    return fields;
  };

  const getInitialValues = () => {
    const values = {
      ...buildingOneStaffStore.statusesInitialValues[
        buildingOneStaffStore.selectedStatus
      ],
      company: buildingOneStore.selectedOne.company as string
    };

    if ("dismiss_position" in values) {
      values["dismiss_position"] = staff_data.position;
    }

    if ("dismiss_object" in values) {
      values["dismiss_object"] = buildingOneStore.selectedOne.title;
    }

    if (
      buildingOneStaffStore.eventTypes[buildingOneStaffStore.selectedStatus]
        ?.custom?.close_withholding &&
      buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id]?.result
    ) {
      values.money =
        buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id].sum;
      values.date =
        buildingOneStaffStore.openedAllEventAllowEdu[staff_data.staff_id].date;
      values.without_edu = 1;
    }
    return values;
  };

  return (
    <div className={styles.background}>
      {Object.values(buildingOneStaffStore.staffTableCols).length &&
      Object.values(buildingOneStaffStore.statusesInitialValues).length ? (
        <div className={styles.window}>
          <Formik
            enableReinitialize
            initialValues={getInitialValues()}
            validateOnBlur
            validateOnChange
            validateOnMount
            validationSchema={getValidationSchema(
              getFieldsForValidation(),
              Object.keys(requiredFields)
            )}
            isLoadingForModal={buildingOneStaffStore.isLoadingForForm}
            onSubmit={(values: InitialValuesForStatus) => {
              buildingOneStaffStore.addNewStaffEvent(values, staff_data["uid"]);
            }}
          >
            {({ isValid, dirty }) => (
              <FormWindow
                title="Изменение статуса"
                subtitle={staff_data.name}
                setOpenWindow={() => {
                  setOpenWindow(false);
                  buildingOneStaffStore.setSelectedStatus("", "");
                }}
                saveBtnTitle="Сохранить"
                saveBtnDisabledValue={!isValid || !dirty}
                addBtnTitle="Отмена"
                addBtnImg={<IconClose />}
                isScroll
                isLoadingForModal={buildingOneStaffStore.isLoadingForForm}
              >
                <>
                  <BuildingOneStaffWindowTabs staff_id={staff_data.staff_id} />

                  <div className={styles.company}>
                    <p className={styles.company__title}>
                      {buildingOneStore.buildingCols.company?.title}
                    </p>
                    <p>
                      {
                        buildingOneStore.variables.company[
                          buildingOneStore.selectedOne.company
                        ]?.title
                      }
                    </p>
                  </div>
                  <StaffOneStatusesForm
                    staff_id={staff_data.staff_id}
                    selectedStatus={buildingOneStaffStore.selectedStatus}
                    company={buildingOneStore.selectedOne.company}
                    cols={buildingOneStaffStore.staffTableCols}
                    boolCols={
                      buildingOneStaffStore.statusesGroups[
                        buildingOneStaffStore.selectedStatus
                      ].bool_column
                    }
                    requiredCols={requiredFields}
                    setRequiredCols={setRequiredFields}
                    openedListName={openedListName}
                    changeOpenedWindows={changeOpenedWindows}
                    params={
                      buildingOneStaffStore.eventTypes[
                        buildingOneStaffStore.selectedStatus
                      ]
                    }
                    isLoadingAllowEdu={buildingOneStaffStore.isLoadingAllowEdu}
                    retention={
                      buildingOneStaffStore.openedAllEventAllowEdu[
                        staff_data.staff_id
                      ]
                    }
                    updateRetention={
                      buildingOneStaffStore.allUpdatedEventAllowEdu[
                        staff_data.staff_id
                      ]
                    }
                    checkEventAllowEdu={
                      buildingOneStaffStore.updateEventAllowEdu
                    }
                    minDateForDefaultUser={
                      buildingOneStaffStore.eventTypes[staff_data.event.type]
                        ?.custom?.allow_event_start_bool
                        ? new Date(staff_data.event.max_event_start)
                        : null
                    }
                    showRetentionBlock
                  />
                </>
              </FormWindow>
            )}
          </Formik>
        </div>
      ) : null}
    </div>
  );
};

export default observer(BuildingOneStaffWindow);
