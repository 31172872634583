import styles from "./clarificationModal.module.scss";
import "./index.scss";
import { ReactComponent as IconCloseWithoutFill } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import iconOK from "shared/assets/images/mainIcons/iconOK.svg";
import iconError from "shared/assets/images/mainIcons/iconError.svg";
import { Modal } from "react-bootstrap";
import StatusIcon from "shared/ui/StatusIcon";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

type ClarificationModalProps = {
  title: string;
  show: boolean;
  subtitle?: string;
  successIcon?: boolean;
  successBtn?: boolean;
  errorIcon?: boolean;
  onHide?: () => void;
  btnWithCrossBtnImg?: boolean;
  btnWithCrossTitle?: string;
  btnWithCrossOnClick: () => void;
  btnBlueOnClick: () => void;
  btnBlueTitle?: string;
  withoutExitBtn?: boolean;
};

const ClarificationModal = ({
  title,
  show,
  subtitle,
  onHide,
  successIcon,
  successBtn,
  errorIcon,
  btnBlueOnClick,
  btnBlueTitle,
  btnWithCrossBtnImg,
  btnWithCrossTitle,
  btnWithCrossOnClick,
  withoutExitBtn
}: ClarificationModalProps) => {
  return (
    <Modal show={show} onHide={onHide} dialogClassName="modal-90w" centered>
      <Modal.Body>
        {!withoutExitBtn ? (
          <div
            className={styles.closeCross}
            onClick={() => onHide()}
            id="clarificationmodal_closeWindow"
          >
            <StatusIcon icon="iconclose" color="bw-gray5" />
          </div>
        ) : (
          ""
        )}

        <div
          className={`${styles.modalBody} ${
            !successIcon || !errorIcon ? styles.modalBody_withoutIcon : ""
          } ${withoutExitBtn ? styles.modalBody_withoutExitBtn : ""}`}
        >
          <div>
            <div className={styles.modalBodyTitle}>{title}</div>
            {subtitle ? (
              <div className={styles.modalBodySubTitle}>{subtitle}</div>
            ) : (
              ""
            )}
          </div>

          {successIcon ? <img src={iconOK} alt="success" /> : ""}
          {errorIcon ? <img src={iconError} alt="error" /> : ""}
        </div>

        <div
          className={`${styles.btnGroup} ${
            withoutExitBtn ? styles.btnGroup_withoutExitBtn : ""
          }`}
        >
          {btnWithCrossTitle && (
            <Button
              id="clarificationmodal_close"
              type="button"
              onClick={btnWithCrossOnClick}
              theme={
                successIcon || successBtn
                  ? ButtonTheme.SECONDARY
                  : ButtonTheme.CANCELLED
              }
              size={ButtonSize.L}
            >
              {btnWithCrossBtnImg && (!successIcon || !successBtn) ? (
                <IconCloseWithoutFill fill="#D41D3C" />
              ) : (
                ""
              )}
              {btnWithCrossTitle}
            </Button>
          )}

          {btnBlueTitle && (
            <Button
              id="clarificationmodal_cancel"
              type="button"
              onClick={btnBlueOnClick}
              size={ButtonSize.L}
            >
              {btnBlueTitle}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ClarificationModal;
