import { Chips, ChipsVariation } from "shared/ui/Chips";
import styles from "./buttonsTabs.module.scss";

type ButtonsTabs = {
  tabs: { title: string; id: string }[];
  changeActiveTab: (id: string) => void;
  title?: string;
};

type WithSelectedTab = ButtonsTabs & {
  selectedTab: string;
  customSelectedTab?: never;
};

type WithCustomSelectedTab = ButtonsTabs & {
  selectedTab?: never;
  customSelectedTab: (tabId: string) => string;
};

type ButtonsTabsProps = WithSelectedTab | WithCustomSelectedTab;

const ButtonsTabs = ({
  selectedTab,
  customSelectedTab,
  tabs,
  changeActiveTab,
  title
}: ButtonsTabsProps) => {
  return (
    <div className={styles.tabsWrapper}>
      {title ? <p>{title}</p> : ""}
      <div className={styles.tabs}>
        {tabs.map((item) => {
          const handleClick = () => {
            changeActiveTab(item.id);
          };

          return item.id !== undefined ? (
            <Chips
              variation={ChipsVariation.FILTER}
              key={item["id"]}
              onClick={handleClick}
              id={`ButtonsTabs_${item["id"]}`}
              className={`${styles.button} ${
                (customSelectedTab
                  ? customSelectedTab(item["id"])
                  : selectedTab) === item["id"]
                  ? styles.buttonSelected
                  : ""
              }`}
              title={item["title"]}
            />
          ) : null;
        })}
      </div>
    </div>
  );
};

export default ButtonsTabs;
