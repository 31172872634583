import LoadedComponent from "widgets/LoadedComponent";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import {
  //Link,
  useParams
} from "react-router-dom";
import StatusIcon from "shared/ui/StatusIcon";
import Tooltip from "shared/ui/Tooltip";
import { useStores } from "stores";
import styles from "./staffOneSalaryPaymentList.module.scss";
import { ReactComponent as IconFinance } from "shared/assets/images/mainIcons/iconFinance.svg";
import { fileUrl } from "stores/utils/consts";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import { observer } from "mobx-react-lite";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";

//hardcode
const icons = {
  "68770fbae852d14b8870f25cfc371ea669c978e4": { icon: "coins" }, //статус Выплачено
  "8d1fad4ef2f2641fe5f4af6fec90a049a4445300": {
    icon: "like",
    color: "purple"
  }, //статус Подтверждено РП
  e319f656830eef40081824c33b6cb92f8cf516e7: {
    icon: "circlecheck",
    color: "teal"
  }, //статус Создано
  "2f49fbc76296047cbf62f666d027df00adc00b64": {
    icon: "crane",
    color: "deep-purple"
  }, //Ведомости по объектам
  f75c1991700f6bfd9941281f02366804ec1814c2: {
    icon: "persondelete",
    color: "danger"
  } //Ведомости по уволенным
};

const StaffOneSalaryPaymentList = () => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    !(id in staffOneSalaryStore.paymentList) &&
      staffOneSalaryStore.getPaymentList(id);
  }, [id]);

  //Массив компаний, которые присутствуют в таблице "Ведомости сотрудника"
  const availableCompanyForUid =
    staffOneSalaryStore.paymentList[id] &&
    Object.values(staffOneSalaryStore.paymentList[id]).map(
      (payment) => payment["object"]["company_id"]
    );

  //Массив типов, которые присутствуют в таблице "Ведомости сотрудника"
  const availableTypesForUid =
    staffOneSalaryStore.paymentList[id] &&
    Object.values(staffOneSalaryStore.paymentList[id]).map(
      (payment) => payment["type"]
    );

  const handleSetActivePaymentType = (value: string) =>
    staffOneSalaryStore.setSelectedTypeForPaymentList(id, value);

  const handleSetActiveCompany = (value: string) =>
    staffOneSalaryStore.setSelectedCompanyForPaymentList(id, value);

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoadingForPaymentList[id]}
      withoutText
    >
      <>
        {staffOneSalaryStore.paymentList[id] &&
        Object.values(staffOneSalaryStore.paymentList[id]).length &&
        staffOneSalaryStore.currentTitlesForPaymentList.length &&
        Object.values(staffOneSalaryStore.colsForPaymentList).length ? (
          <>
            <div className={styles.tabsContainer}>
              <div className={styles.tabs}>
                <ButtonsTabs
                  selectedTab={
                    staffOneSalaryStore.selectedTypeForPaymentList[id]
                  }
                  tabs={[
                    availableTypesForUid.length > 1 && {
                      title: "Все ведомости",
                      id: "all"
                    },
                    ...Object.values(
                      staffOneSalaryStore.paymentListTypes
                    ).filter((type) =>
                      availableTypesForUid.includes(type["id"])
                    )
                  ]}
                  changeActiveTab={handleSetActivePaymentType}
                  title="Тип ведомости"
                />
              </div>
              <div className={styles.tabs}>
                <ButtonsTabs
                  selectedTab={
                    staffOneSalaryStore.selectedCompanyForPaymentList[id]
                  }
                  tabs={[
                    {
                      title: "Все компании",
                      id: "all"
                    },
                    ...Object.values(staffOneSalaryStore.companiesDict).filter(
                      (company) =>
                        availableCompanyForUid.includes(company["id"])
                    )
                  ]}
                  changeActiveTab={handleSetActiveCompany}
                  title="Компании"
                />
              </div>
              <div className={styles.lastNotes}>Последние 10 записей</div>
            </div>
            <Table>
              <thead>
                <tr>
                  {staffOneSalaryStore.currentTitlesForPaymentList.map(
                    (title) => {
                      return (
                        <th key={title} className={styles.title}>
                          <div className="d-flex align-items-center">
                            <p className="m-0">
                              {
                                staffOneSalaryStore.colsForPaymentList[title]?.[
                                  "title"
                                ]
                              }
                            </p>
                          </div>
                        </th>
                      );
                    }
                  )}
                  <th className={styles.title}></th>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {Object.values(staffOneSalaryStore.paymentList[id])
                  .filter((payment) =>
                    staffOneSalaryStore.selectedTypeForPaymentList[id] !== "all"
                      ? staffOneSalaryStore.selectedTypeForPaymentList[id] ===
                        payment.type
                      : payment
                  )
                  .filter((payment) =>
                    staffOneSalaryStore.selectedCompanyForPaymentList[id] !==
                    "all"
                      ? staffOneSalaryStore.selectedCompanyForPaymentList[
                          id
                        ] === payment["object"]["company_id"]
                      : payment
                  )
                  .map((payment, index) => {
                    return (
                      <React.Fragment key={payment.id}>
                        <tr
                          className={classNames("", {
                            [styles.hoverRow]: !isHovered,
                            [styles.greyRow]: index % 2
                          })}
                        >
                          {staffOneSalaryStore.currentTitlesForPaymentList.map(
                            (title) => {
                              switch (title) {
                                case "object":
                                  return (
                                    <td
                                      key={title}
                                      rowSpan={payment["paymentlist"].length}
                                      className={classNames("", {
                                        [styles.verticalAlignTop]:
                                          payment["paymentlist"].length > 1
                                      })}
                                      data-field="notHover"
                                      onMouseEnter={() => setIsHovered(true)}
                                      onMouseLeave={() => setIsHovered(false)}
                                    >
                                      <a
                                        className={styles.buildingTitle}
                                        href={`${fileUrl}/crm/building/?id=${payment.object.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {payment["object"]["title"]}
                                      </a>
                                      {/* <Link
                                     className={styles.buildingTitle}
                                      id={`StaffOneSalaryPaymentList_redirectTo_${payment.object.id}`}
                                      to={`/building/id=${payment.object.id}`}
                                      target="_blank"
                                    >
                                      {payment["object"]["title"]}
                                    </Link> */}
                                      <div
                                        className={
                                          styles[
                                            `company_${
                                              staffOneSalaryStore.companiesDict[
                                                payment["object"]["company_id"]
                                              ]?.color
                                            }`
                                          ]
                                        }
                                      >
                                        {payment["object"]["company_title"]}
                                      </div>
                                    </td>
                                  );

                                case "type":
                                  return (
                                    <td
                                      key={title}
                                      rowSpan={payment["paymentlist"].length}
                                      className={classNames("", {
                                        [styles.verticalAlignTop]:
                                          payment["paymentlist"].length > 1
                                      })}
                                      data-field="notHover"
                                      onMouseEnter={() => setIsHovered(true)}
                                      onMouseLeave={() => setIsHovered(false)}
                                    >
                                      <div className={styles.statusCell}>
                                        <StatusIcon
                                          icon={icons[payment[title]]["icon"]}
                                          color={
                                            icons[payment[title]]?.["color"]
                                          }
                                        />
                                        <p>
                                          {
                                            staffOneSalaryStore
                                              .paymentListTypes[
                                              payment[title]
                                            ]?.["title"]
                                          }
                                        </p>
                                      </div>
                                    </td>
                                  );

                                case "status":
                                  return (
                                    <td key={title}>
                                      <div className={styles.statusCell}>
                                        <StatusIcon
                                          icon={
                                            icons[
                                              payment["paymentlist"][0][title]
                                            ]["icon"]
                                          }
                                          color={
                                            icons[
                                              payment["paymentlist"][0][title]
                                            ]?.["color"]
                                          }
                                        />
                                        <p>
                                          {
                                            staffOneSalaryStore
                                              .paymentListStatuses[
                                              payment["paymentlist"][0][title]
                                            ]?.["title"]
                                          }
                                        </p>
                                      </div>
                                    </td>
                                  );

                                case "uid":
                                  return (
                                    <td key={title}>
                                      <a
                                        className={styles.link}
                                        rel="noreferrer"
                                        target="_blank"
                                        href={`${fileUrl}/crm/salary/?ps_id=${payment["paymentlist"][0]["id"]}`}
                                      >
                                        <Tooltip text="Перейти к Ведомости">
                                          <div className={styles.uidCell}>
                                            <div className={styles.uidIcon}>
                                              <IconFinance />
                                            </div>
                                            <p>
                                              {payment["paymentlist"][0][title]}
                                            </p>
                                          </div>
                                        </Tooltip>
                                      </a>
                                    </td>
                                  );

                                case "money":
                                  return (
                                    <td
                                      key={title}
                                      className={styles.moneyCell}
                                    >
                                      {numberWithSpaces(
                                        payment["paymentlist"][0][title]
                                      )}
                                    </td>
                                  );

                                default:
                                  return (
                                    <td key={title}>
                                      {getFormattedDate(
                                        payment["paymentlist"][0][title]
                                      )}
                                    </td>
                                  );
                              }
                            }
                          )}
                          <td className={styles.emptyColumn}></td>
                        </tr>
                        {payment["paymentlist"].length > 1
                          ? payment["paymentlist"].map((item, ind) => {
                              if (ind === 0) return;
                              return (
                                <tr
                                  key={item.id}
                                  className={classNames(styles.hoverRow, {
                                    [styles.greyRow]: index % 2
                                  })}
                                >
                                  {staffOneSalaryStore.currentTitlesForPaymentList.map(
                                    (title) => {
                                      switch (title) {
                                        case "object":
                                        case "type":
                                          return;

                                        case "status":
                                          return (
                                            <td key={title}>
                                              <div
                                                className={styles.statusCell}
                                              >
                                                <StatusIcon
                                                  icon={
                                                    icons[item[title]]["icon"]
                                                  }
                                                  color={
                                                    icons[item[title]]?.[
                                                      "color"
                                                    ]
                                                  }
                                                />
                                                <p>
                                                  {
                                                    staffOneSalaryStore
                                                      .paymentListStatuses[
                                                      item[title]
                                                    ]?.["title"]
                                                  }
                                                </p>
                                              </div>
                                            </td>
                                          );

                                        case "uid":
                                          return (
                                            <td key={title}>
                                              <a
                                                className={styles.link}
                                                rel="noreferrer"
                                                target="_blank"
                                                href={`${fileUrl}/crm/salary/?ps_id=${item["id"]}`}
                                              >
                                                <Tooltip text="Перейти к Ведомости">
                                                  <div
                                                    className={styles.uidCell}
                                                  >
                                                    <div
                                                      className={styles.uidIcon}
                                                    >
                                                      <IconFinance />
                                                    </div>
                                                    <p>{item[title]}</p>
                                                  </div>
                                                </Tooltip>
                                              </a>
                                            </td>
                                          );

                                        case "money":
                                          return (
                                            <td
                                              key={title}
                                              className={styles.moneyCell}
                                            >
                                              {numberWithSpaces(item[title])}
                                            </td>
                                          );

                                        default:
                                          return (
                                            <td key={title}>
                                              {getFormattedDate(item[title])}
                                            </td>
                                          );
                                      }
                                    }
                                  )}
                                  <td className={styles.emptyColumn}></td>
                                </tr>
                              );
                            })
                          : ""}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          <div className={styles.withoutData}>Список ведомостей пуст</div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalaryPaymentList);
