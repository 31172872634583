import styles from "./staffOneEditSetting.module.scss";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Form } from "react-bootstrap";
import { Formik } from "formik";

import IconHistory from "shared/assets/images/mainIcons/iconHistory.svg";
import Switch from "shared/ui/Inputs/Switch";
import ButtonsGroupForFormEdit from "shared/ui/ButtonsGroup/ButtonsGroupForFormEdit";
import SuccessModal from "shared/ui/Modals/SuccessModal/index";
import StaffOneEditSettingForm from "./StaffOneEditSettingForm";
import { useParams } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "widgets/LoadedComponent/Error/ErrorFallback";
import { Button, ButtonSize, ButtonTheme } from "shared/ui/Button";

const StaffOneEditSetting = () => {
  const { id } = useParams();
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const { staffOneEditStore } = useStores();

  return (
    <div className="d-flex flex-column">
      <div className={styles.resetBlock}>
        <div>
          <label>Пароль</label>
          <img src={IconHistory} />
        </div>
        {!isOpenForm ? (
          <Button
            theme={ButtonTheme.SECONDARY}
            size={ButtonSize.M}
            id="StaffOneEditSetting_resetBtn"
            onClick={() => {
              setIsOpenForm(true);
            }}
          >
            Установить новый пароль от приложения
          </Button>
        ) : (
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <StaffOneEditSettingForm
              setIsOpenForm={setIsOpenForm}
              setShowSuccessModal={setShowSuccessModal}
            />
          </ErrorBoundary>
        )}
      </div>

      <Formik
        initialValues={{
          leadership: staffOneEditStore.selectedOneForEdit["leadership"]
            ? 1
            : 0,
          polatiapp_access: staffOneEditStore.selectedOneForEdit[
            "polatiapp_access"
          ]
            ? 1
            : 0
        }}
        validateOnBlur
        validateOnChange
        onSubmit={async (values) => {
          await staffOneEditStore.updateField(
            staffOneEditStore.selectedOneForEdit["id"],
            values
          );
        }}
        enableReinitialize
      >
        {({ setFieldTouched, handleSubmit, handleReset, dirty }) => {
          return (
            <Form>
              {["leadership", "polatiapp_access"].map((title) => {
                return (
                  <label key={title} className={styles.hasHide}>
                    <Switch
                      name={title}
                      onChange={() => {
                        setFieldTouched(title);
                      }}
                    />
                    {staffOneEditStore.staffTableCols[title]?.title}
                  </label>
                );
              })}

              <div className={styles.buttonsGroup}>
                <ButtonsGroupForFormEdit
                  blueBtnTitle="Сохранить"
                  blueBtnDisabled={!dirty}
                  blueBtnOnClick={handleSubmit}
                  grayBtnTitle="Отмена"
                  grayBtnDisabled={!dirty}
                  grayBtnOnClick={handleReset}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <SuccessModal
        show={showSuccessModal}
        onHide={() => {
          setShowSuccessModal(false);
        }}
        title={
          staffOneEditStore.errorsMessage?.[id]?.message?.head
            ? staffOneEditStore.errorsMessage[id].message.head
            : "Пароль сброшен"
        }
        successIcon={
          staffOneEditStore.errorsMessage?.[id]?.message?.head ? false : true
        }
      />
    </div>
  );
};

export default observer(StaffOneEditSetting);
