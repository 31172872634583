import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useStores } from "stores";
import styles from "./staffOneSalaryPremium.module.scss";
import CircleCheck from "shared/assets/images/iconStatus/CircleCheck.svg";
import Coins from "shared/assets/images/iconStatus/Coins.svg";
import { useEffect } from "react";
import ButtonsTabs from "shared/ui/Tabs/ButtonsTabs";
import LoadedComponent from "widgets/LoadedComponent";
// import { Link } from "react-router-dom";
import { fileUrl } from "stores/utils/consts";
import { numberWithSpaces } from "shared/utils/helpers/numberWithSpaces";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";

//hardcode
const icons = {
  "8cde3376531f47b26b07c209eb3ee911b4d14df0": { icon: Coins }, //статус Начислено (Подтверждено)
  "8c24bcb9faf90639fa0cbb7ce53e2c3e44d32a78": {
    icon: CircleCheck,
    color: "teal"
  } //статус Рассчитано
};

const StaffOneSalaryPremium = () => {
  const { staffOneSalaryStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneSalaryStore.premium) && staffOneSalaryStore.getPremium(id);
  }, [id]);

  //Массив компаний, которые присутствуют в таблице "Премии"
  const availableCompanyForUid = Object.values(
    staffOneSalaryStore.premium[id] || {}
  ).map((premium) => premium["object"]["company_id"]);

  const handleSetActiveStatus = (value: string) =>
    staffOneSalaryStore.setSelectedStatusForPremium(id, value);

  const handleSetActiveCompany = (value: string) =>
    staffOneSalaryStore.setSelectedCompanyForPremium(id, value);

  return (
    <LoadedComponent
      isLoading={staffOneSalaryStore.isLoadingForPremium[id]}
      withoutText
    >
      <>
        {staffOneSalaryStore.premium[id] &&
        Object.values(staffOneSalaryStore.premium[id]).length &&
        staffOneSalaryStore.currentTitlesForPremium.length &&
        Object.values(staffOneSalaryStore.colsForPremium).length ? (
          <>
            <div className={styles.tabsContainer}>
              <div className={styles.tabs}>
                <ButtonsTabs
                  selectedTab={staffOneSalaryStore.selectedStatusForPremium[id]}
                  tabs={[
                    {
                      title: "Все статусы",
                      id: "all"
                    },
                    ...Object.values(staffOneSalaryStore.premiumStatusSelects)
                  ]}
                  changeActiveTab={handleSetActiveStatus}
                  title="Статус"
                />
              </div>
              <div className={styles.tabs}>
                <ButtonsTabs
                  selectedTab={
                    staffOneSalaryStore.selectedCompanyForPremium[id]
                  }
                  tabs={[
                    {
                      title: "Все компании",
                      id: "all"
                    },
                    ...Object.values(staffOneSalaryStore.companiesDict).filter(
                      (company) =>
                        availableCompanyForUid.includes(company["id"])
                    )
                  ]}
                  changeActiveTab={handleSetActiveCompany}
                  title="Компании"
                />
              </div>
              <div className={styles.lastNotes}>Последние 10 записей</div>
            </div>
            <Table>
              <thead>
                <tr>
                  {staffOneSalaryStore.currentTitlesForPremium.map((title) => {
                    return (
                      <th key={title} className={styles.title}>
                        <div className="d-flex align-items-center">
                          <p className="m-0">
                            {
                              staffOneSalaryStore.colsForPremium[title]?.[
                                "title"
                              ]
                            }
                          </p>
                        </div>
                      </th>
                    );
                  })}
                  <th className={styles.title}></th>
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {Object.values(staffOneSalaryStore.premium[id])
                  .filter((premium) =>
                    staffOneSalaryStore.selectedStatusForPremium[id] !== "all"
                      ? staffOneSalaryStore.selectedStatusForPremium[id] ===
                        premium.status
                      : premium
                  )
                  .filter((premium) =>
                    staffOneSalaryStore.selectedCompanyForPremium[id] !== "all"
                      ? staffOneSalaryStore.selectedCompanyForPremium[id] ===
                        premium["object"]["company_id"]
                      : premium
                  )
                  .sort(
                    (a, b) =>
                      new Date(b["last_update"]).valueOf() -
                      new Date(a["last_update"]).valueOf()
                  )
                  .map((premium, index) => {
                    return (
                      <tr
                        key={premium.id}
                        className={classNames("", {
                          [styles.greyRow]: index % 2
                        })}
                      >
                        {staffOneSalaryStore.currentTitlesForPremium.map(
                          (title) => {
                            switch (title) {
                              case "object":
                                return (
                                  <td key={title}>
                                    <a
                                      className={styles.buildingTitle}
                                      href={`${fileUrl}/crm/building/?id=${premium.object.id}`}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {premium[title]["title"]}
                                    </a>
                                    {/* <Link
                                    className={styles.buildingTitle}
                                    to={`../../building/id=${premium.object.id}/timesheet`}
                                    target="_blank"
                                  >
                                    {premium[title]["title"]}
                                  </Link> */}
                                    <div
                                      className={
                                        styles[
                                          `company_${
                                            staffOneSalaryStore.companiesDict[
                                              premium[title]["company_id"]
                                            ]?.color
                                          }`
                                        ]
                                      }
                                    >
                                      {premium[title]["company_title"]}
                                    </div>
                                  </td>
                                );
                              case "last_update":
                                return (
                                  <td key={title}>
                                    {getFormattedDate(
                                      premium[title].slice(0, 10)
                                    )}
                                  </td>
                                );
                              case "status":
                                return (
                                  <td key={title}>
                                    <div className={styles.statusCell}>
                                      <img
                                        src={icons[premium[title]]["icon"]}
                                        className={`status_${
                                          icons[premium[title]]?.["color"]
                                        }`}
                                      />
                                      <p>
                                        {
                                          staffOneSalaryStore
                                            .premiumStatusSelects[
                                            premium[title]
                                          ]?.["title"]
                                        }
                                      </p>
                                    </div>
                                  </td>
                                );
                              case "sum_premium":
                                return (
                                  <td key={title}>
                                    {Object.entries(premium["staff"]).map(
                                      ([key, value]) => {
                                        return (
                                          <div
                                            key={key}
                                            className={styles.sumPremiumItem}
                                          >
                                            <div>
                                              {value["fixed"] ? "FIX" : "ЧЧ"}
                                            </div>
                                            <div>
                                              {numberWithSpaces(
                                                value["sum_premium"]
                                              )}
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </td>
                                );

                              default:
                                return <td key={title}>{premium[title]}</td>;
                            }
                          }
                        )}
                        <td className={styles.emptyColumn}></td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </>
        ) : (
          <div className={styles.withoutData}>Список премий пуст</div>
        )}
      </>
    </LoadedComponent>
  );
};

export default observer(StaffOneSalaryPremium);
