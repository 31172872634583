import styles from "./successModal.module.scss";
import iconOK from "shared/assets/images/mainIcons/iconOK.svg";
import iconError from "shared/assets/images/mainIcons/iconError.svg";
import { Modal } from "react-bootstrap";
import StatusIcon from "shared/ui/StatusIcon";

type SuccessModalProps = {
  show: boolean;
  onHide: () => void;
  title: string;
  successIcon: boolean;
  direction?: "column" | "row";
};

const SuccessModal = ({
  show,
  onHide,
  title,
  successIcon,
  direction
}: SuccessModalProps) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Body className={styles.modal}>
        <div
          className={styles.iconClose}
          onClick={() => onHide()}
          id="successmodal_close"
        >
          <StatusIcon icon="iconclose" color="bw-$bw-gray5" />
        </div>
        <div
          className={`${styles.modalBody} ${
            direction === "row" ? styles.modalBody_row : ""
          }`}
        >
          <div className={styles.modalBodyTitle}>{title}</div>
          <img src={successIcon ? iconOK : iconError} alt="icon" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SuccessModal;
