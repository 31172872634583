import styles from "./staffOneEditFormCompanies.module.scss";
import { Field, useFormikContext } from "formik";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import Checkboxes from "shared/ui/Inputs/Checkboxes";

import { isEqual } from "lodash";
import { ICompany } from "stores/StaffModule/types/RecordsForEdit";
import { useParams } from "react-router-dom";
import { Chips, ChipsVariation } from "shared/ui/Chips";
import { classNames } from "shared/utils/helpers/classNames";

type StaffOneEditFormCompaniesProps = {
  values: { companies: { [key: string]: ICompany }; company: string[] };
  openedListName: string | null;
  changeOpenedWindows: (arg: string | null) => void;
  disabled: boolean;
};

const StaffOneEditFormCompanies = ({
  values,
  openedListName,
  changeOpenedWindows,
  disabled
}: StaffOneEditFormCompaniesProps) => {
  const { staffOneEditStore } = useStores();
  const { setFieldValue } = useFormikContext();
  const { id } = useParams();

  useEffect(() => {
    if (!values["company"].includes(staffOneEditStore.selectedCompanyId[id])) {
      staffOneEditStore.setSelectedCompanyId(
        id,
        Object.keys(staffOneEditStore.selectedOneForEdit["company"])[0]
      );
    }
  }, [values["company"].length]);

  useEffect(() => {
    if (
      values["company"].length &&
      !isEqual(values["company"], Object.keys(values["companies"]))
    ) {
      const tempObj = {};
      let selectedId = "";

      values["company"].forEach((key) => {
        let match = false;
        const provSelectedCompanyId = () => {
          if (Object.keys(tempObj)[0]) {
            selectedId =
              staffOneEditStore.selectedCompanyId[id] ??
              Object.keys(tempObj)[0];
          } else selectedId = key;
        };
        if (Object.keys(values["companies"]).length) {
          for (const item of Object.entries(values["companies"])) {
            if (key === item[0]) {
              tempObj[key] = item[1];
              match = false;
              break;
            } else {
              match = true;
            }
          }
        } else {
          match = true;
        }
        provSelectedCompanyId();
        if (match) {
          selectedId = key;
          tempObj[key] = {
            employments: {
              0: {
                date_of_employment_of: "",
                status: "",
                trud_dog: "",
                position: "",
                position_history: [],
                trud_dog_date: "",
                employment: "",
                employment_type: "",
                object_of_employment: "",
                salary: "",
                salary_history: [],
                employment_number: 1,
                premium_price: "",
                premium_price_history: [],
                dept: "",
                dept_history: [],
                employment_place: "",
                employment_place_history: [],
                invest: "",
                invest_history: []
              }
            }
          };
          staffOneEditStore.setNewCompanyData(selectedId, tempObj[selectedId]);
        }
      });

      setFieldValue("companies", tempObj);

      if (selectedId.length) {
        values["company"].length <
          Object.keys(staffOneEditStore.selectedOneForEdit["company"]).length &&
          staffOneEditStore.setCurrentCompanyList(id, values["company"]);
        staffOneEditStore.setSelectedCompanyId(id, selectedId);
        tempObj[selectedId]?.["employments"] &&
          staffOneEditStore.setSelectedTypeOfJob(
            id,
            Object.keys(tempObj[selectedId]["employments"])[0]
          );
      }
    }
  }, [values["company"]]);

  return (
    <>
      <Field
        className={classNames("", { [styles.disabled]: disabled })}
        name="company"
        values={values["company"]}
        as={Checkboxes}
        label={staffOneEditStore.staffTableCols["company"]["title"]}
        options={staffOneEditStore.allCompanies}
        isMenuOpened={openedListName === "companies"}
        setIsMenuOpened={() => {
          changeOpenedWindows("companies");
        }}
        disabled={disabled}
      />
      <div className="d-flex gap-2">
        {values["companies"] &&
          Object.keys(values["companies"]).map((key) => {
            const handleSetSelectedCompany = () =>
              staffOneEditStore.setSelectedCompanyId(id, key, true);
            return (
              <Chips
                variation={ChipsVariation.FILTER}
                key={key}
                id={`StaffOneEditFormCompanies_${key}`}
                className={classNames("", {
                  [styles.selectedGrayBtn]:
                    staffOneEditStore.selectedCompanyId[id] === key,
                  [styles.grayBtn]:
                    staffOneEditStore.selectedCompanyId[id] !== key
                })}
                onClick={handleSetSelectedCompany}
                title={staffOneEditStore.allCompanies[key]}
              />
            );
          })}
      </div>
      <div className="d-flex gap-2">
        {values["companies"][staffOneEditStore.selectedCompanyId[id]] &&
          values["companies"][staffOneEditStore.selectedCompanyId[id]][
            "employments"
          ] &&
          Object.entries(
            values["companies"][staffOneEditStore.selectedCompanyId[id]][
              "employments"
            ]
          )
            .sort((a, b) => {
              if (a[1].trud_dog.start_date === b[1].trud_dog.start_date) {
                return 0;
              } else if (a[1].trud_dog.start_date < b[1].trud_dog.start_date) {
                return 1;
              } else return -1;
            })
            .map(([key, values]) => {
              const handleSetSelectedTypeOfJob = () =>
                staffOneEditStore.setSelectedTypeOfJob(id, key);

              return key !== "id" ? (
                <Chips
                  variation={ChipsVariation.FILTER}
                  key={key}
                  id={`StaffOneEditFormCompanies_selectJob_${key}`}
                  className={classNames("", {
                    [styles.selectedGrayBtn]:
                      staffOneEditStore.selectedTypeOfJob[id] === key,
                    [styles.grayBtn]:
                      staffOneEditStore.selectedTypeOfJob[id] !== key
                  })}
                  onClick={handleSetSelectedTypeOfJob}
                >
                  <p className={styles.workPlace}>
                    {values["employment_title"]
                      ? values["employment_title"]
                      : ""}
                  </p>
                  <p className={styles.workStatus}>
                    {key} <span>{values["status"]}</span>
                  </p>
                </Chips>
              ) : (
                ""
              );
            })}
      </div>
    </>
  );
};

export default observer(StaffOneEditFormCompanies);
