import LoadedComponent from "widgets/LoadedComponent";
import { observer } from "mobx-react-lite";
import { MutableRefObject, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "shared/ui/Cards/Card";
import { useStores } from "stores";
import styles from "./staffOneSalaryMetrics.module.scss";

type StaffOneSalaryMetricsProps = {
  refsForBlock: MutableRefObject<HTMLDivElement[]>;
};

const StaffOneSalaryMetrics = ({
  refsForBlock
}: StaffOneSalaryMetricsProps) => {
  const { staffOneSalaryStore, menuStore } = useStores();
  const { id } = useParams();

  useEffect(() => {
    !(id in staffOneSalaryStore.financeMetricsList) &&
      staffOneSalaryStore.getFinanceMetrics(id);
  }, [id]);

  return (
    <div className={styles.loaderContainer}>
      <LoadedComponent
        isLoading={staffOneSalaryStore.isLoadingForFinanceMetrics[id]}
        withoutText
      >
        <>
          {staffOneSalaryStore.financeMetricsList[id] &&
          Object.values(staffOneSalaryStore.financeMetricsList[id]).length ? (
            <div className={styles.cards}>
              {Object.entries(staffOneSalaryStore.financeMetricsList[id]).map(
                ([key, metric]) => {
                  return (
                    <Card
                      key={key}
                      data={Object.values(metric.metrics)}
                      color={metric["color"]}
                      center
                      onClick={() => {
                        // раскрываем выбранный блок
                        staffOneSalaryStore.setConditionBlockList(
                          id,
                          Object.values(metric.metrics)[0]["table_title"],
                          true
                        );
                        
                        // скроллимся к выбранному блоку
                        menuStore.scrollbarRef.current.view.scroll({
                          top:
                            refsForBlock["current"][
                              Object.values(metric.metrics)[0]["table_title"]
                            ]?.offsetTop - 100,
                          left: 0,
                          behavior: "smooth"
                        });
                      }}
                    />
                  );
                }
              )}
            </div>
          ) : (
            ""
          )}
        </>
      </LoadedComponent>
    </div>
  );
};

export default observer(StaffOneSalaryMetrics);
