import styles from "./aregisterOneMasscopyForm.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useFormikContext } from "formik";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";

import AregisterOneMasscopyFormColumns from "./AregisterOneMasscopyFormColumns";
import AregisterOneMasscopyFormTable from "./AregisterOneMasscopyFormTable";
import AregisterOneMasscopyFormPanel from "./AregisterOneMasscopyFormPanel";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";

import { Application } from "stores/AregisterModule/types/Application";

type AregisterOneMasscopyFormProps = {
  values: {
    reload: number;
    original: Partial<Application>;
    copies: Partial<Application>[];
    massfill: Partial<Application>;
  };
  tableType: string;
};

const AregisterOneMasscopyForm = ({
  values,
  tableType
}: AregisterOneMasscopyFormProps) => {
  const { id } = useParams();
  const { aregisterOneMasscopyStore, aregisterAppcreateStore } = useStores();

  const { dirty, setValues, setFieldValue, handleReset } = useFormikContext();
  const [openedModal, setOpenedModal] = useState(false);
  const [openedListName, setIsOpenedListName] = useState("");
  const [highlightIndex, setHighlightIndex] = useState<number>(null);
  const [highlightRowIndex, setHighlightRowIndex] = useState(-1);
  const [focusIndex, setFocusIndex] = useState<number>(null);
  const [focusRowIndex, setFocusRowIndex] = useState<number>(null);

  const [editedCell, setEditedCell] = useState("");

  const ref = useRef<HTMLDivElement>();

  useOnClickOutside({
    ref,
    handler: () => changeFocusIndex()
  });

  useEffect(() => {
    if (tableType === "row") {
      setHighlightRowIndex(-1);
    }
  }, [tableType]);

  const changeFocusIndex = () => {
    setFocusIndex(null);
    setFocusRowIndex(null);
  };

  const changeOpenedListName = (name: string) => {
    openedListName !== name
      ? setIsOpenedListName(name)
      : setIsOpenedListName("");
  };

  const checkCustomFields = () => {
    return aregisterAppcreateStore.projects[values.original.project]?.custom
      ?.custom_fields &&
      Object.entries(
        aregisterAppcreateStore.projects[values.original.project].custom
          .custom_fields
      ).length
      ? true
      : false;
  };

  const handleEscPress = (name: string, secondName?: string) => {
    if (secondName === "custom_fields") {
      setFieldValue(editedCell, null);
    } else if (secondName) {
      setFieldValue(name, null);
    } else {
      setFieldValue(
        editedCell,
        aregisterOneMasscopyStore.editedNullFieldsForMasscopy.includes(name)
          ? null
          : values.original[name] || null
      );
    }

    changeOpenedListName("");
    changeFocusIndex();
    setEditedCell("");
  };

  useEffect(() => {
    aregisterOneMasscopyStore.dirty[id] &&
      setValues(aregisterOneMasscopyStore.values[id]);
  }, [id]);

  useEffect(() => {
    if (dirty && !aregisterOneMasscopyStore.dirty[id]) {
      aregisterOneMasscopyStore.setDirty(id, dirty);
    }
  }, [dirty]);

  useEffect(() => {
    if (dirty) {
      aregisterOneMasscopyStore.setValues(id, values);
    }
  }, [values]);

  return (
    <>
      <div className="position-relative">
        <div className={styles.legend}>
          <div className={styles.legend__yellow}>
            — обязательные к заполнению поля
          </div>
          <div className={styles.legend__blue}>
            — дополнительные поля выбранного проекта
          </div>
        </div>
      </div>

      <div className={styles.forms}>
        {tableType === "column" ? (
          <AregisterOneMasscopyFormColumns
            values={values}
            isOpenedMassFill={Object.values(values.massfill).length > 0}
            setIsOpenedMassFill={() => {
              !Object.values(values.massfill).length
                ? setFieldValue(
                    "massfill",
                    aregisterAppcreateStore.openedAllInitialValuesForMassCopy[
                      id
                    ]
                  )
                : setFieldValue("massfill", {});
            }}
            openedListName={openedListName}
            changeOpenedListName={changeOpenedListName}
            highlightIndex={highlightIndex}
            setHighlightIndex={setHighlightIndex}
            focusIndex={focusIndex}
            setFocusIndex={setFocusIndex}
            focusRowIndex={focusRowIndex}
            setFocusRowIndex={setFocusRowIndex}
            checkCustomFields={checkCustomFields}
            focusedRef={ref}
            highlightRowIndex={highlightRowIndex}
            setHighlightRowIndex={setHighlightRowIndex}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            handleEscPress={handleEscPress}
          />
        ) : tableType === "row" ? (
          <AregisterOneMasscopyFormTable
            values={values}
            isOpenedMassFill={Object.values(values.massfill).length > 0}
            setIsOpenedMassFill={() => {
              !Object.values(values.massfill).length
                ? setFieldValue(
                    "massfill",
                    aregisterAppcreateStore.openedAllInitialValuesForMassCopy[
                      id
                    ]
                  )
                : setFieldValue("massfill", {});
            }}
            openedListName={openedListName}
            changeOpenedListName={changeOpenedListName}
            highlightIndex={highlightIndex}
            setHighlightIndex={setHighlightIndex}
            focusIndex={focusIndex}
            setFocusIndex={setFocusIndex}
            focusRowIndex={focusRowIndex}
            setFocusRowIndex={setFocusRowIndex}
            changeFocusIndex={changeFocusIndex}
            checkCustomFields={checkCustomFields}
            focusedRef={ref}
            editedCell={editedCell}
            setEditedCell={setEditedCell}
            handleEscPress={handleEscPress}
          />
        ) : null}
        <AregisterOneMasscopyFormPanel
          tableType={tableType}
          setOpenedModal={setOpenedModal}
          values={values}
        />
        <ClarificationModal
          show={openedModal}
          onHide={() => {
            setOpenedModal(!openedModal);
            handleReset();
          }}
          title="Копии заявки созданы"
          successIcon
          btnWithCrossTitle="Копировать ещё"
          btnWithCrossOnClick={() => {
            setOpenedModal(!openedModal);
            handleReset();
          }}
          btnBlueOnClick={() => {
            setOpenedModal(!openedModal);
            handleReset();
          }}
          btnBlueTitle="К заявке"
        />
      </div>
    </>
  );
};

export default observer(AregisterOneMasscopyForm);
