import styles from "./aregisterOneMasscopyFormColumns.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import { useFormikContext } from "formik";

import Select from "shared/ui/Inputs/Select";
import { Input } from "shared/ui/Inputs/Input";
import DatePickerField from "shared/ui/Inputs/DatePickerField";
import Switch from "shared/ui/Inputs/Switch";
import Tooltip from "shared/ui/Tooltip";
import AregisterOneMasscopyFormAddBlock from "../AregisterOneMasscopyFormAddBlock";
import AregisterOneMasscopyFormFormula from "../AregisterOneMasscopyFormFormula";
import AregisterOneMasscopyFormSendBtn from "../AregisterOneMasscopyFormSendBtn";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import { ReactComponent as IconDash } from "shared/assets/images/iconStatus/Dash.svg";

import { parse } from "date-fns";

import { Application } from "stores/AregisterModule/types/Application";
import { getFormattedDate } from "shared/utils/helpers/getFormattedDate";
import { classNames } from "shared/utils/helpers/classNames";
import { ViewField } from "shared/ui/ViewField";

type AregisterOneMasscopyFormColumnsProps = {
  values: {
    reload: number;
    original: Partial<Application>;
    copies: Partial<Application>[];
    massfill: Partial<Application>;
  };
  isOpenedMassFill: boolean;
  setIsOpenedMassFill: (arg: boolean) => void;
  openedListName: string;
  changeOpenedListName: (name: string) => void;
  highlightIndex: number;
  setHighlightIndex: (index: number) => void;
  focusIndex: number;
  setFocusIndex: (index: number) => void;
  focusRowIndex: number;
  setFocusRowIndex: (index: number) => void;
  focusedRef: React.MutableRefObject<HTMLDivElement>;
  highlightRowIndex: number;
  setHighlightRowIndex: (index: number) => void;
  checkCustomFields: () => boolean;
  editedCell: string;
  setEditedCell: (name: string) => void;
  handleEscPress: (name: string, secondName?: string) => void;
};

const AregisterOneMasscopyFormColumns = ({
  values,
  isOpenedMassFill,
  setIsOpenedMassFill,
  openedListName,
  changeOpenedListName,
  highlightIndex,
  setHighlightIndex,
  focusIndex,
  setFocusIndex,
  focusRowIndex,
  setFocusRowIndex,
  checkCustomFields,
  focusedRef,
  highlightRowIndex,
  setHighlightRowIndex,
  editedCell,
  setEditedCell,
  handleEscPress
}: AregisterOneMasscopyFormColumnsProps) => {
  const { id } = useParams();
  const { menuStore, aregisterAppcreateStore } = useStores();
  const { setFieldValue, setFieldTouched, initialValues } = useFormikContext();

  return (
    <div className={styles.forms}>
      <div
        className={`${styles.stickyCols} ${
          menuStore.scroll["scrollLeft"] ? styles.stickyCols_shadow : ""
        }`}
      >
        <div
          className={`${styles.titles} ${
            menuStore.isScroll ? styles.shadow : ""
          }`}
        >
          <AregisterOneMasscopyFormAddBlock
            copies={values.copies}
            isOpenedMassFill={isOpenedMassFill}
            setIsOpenedMassFill={setIsOpenedMassFill}
          />
          {Object.keys(
            aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id]
          ).map((title, i) => {
            const field = aregisterAppcreateStore.cols[title];
            const param = aregisterAppcreateStore.params[title];

            if (
              [
                "length",
                "width",
                "height",
                "size",
                "conditional_cubes",
                "manual_size",
                "no_kc",
                "allow_manual_hours",
                "allow_manual_size"
              ].includes(title)
            )
              return;

            if (title === "manual_hours") {
              return aregisterAppcreateStore.openedAllInitialValuesForMassCopy[
                id
              ]?.project &&
                aregisterAppcreateStore.projects[
                  aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id]
                    .project
                ]?.allow_manual_hours === 1 ? (
                <div
                  key={`${title}_title`}
                  className={`${styles.titles__oneTitle} ${
                    highlightRowIndex === i || focusRowIndex === i
                      ? styles.highlighted
                      : ""
                  }`}
                  onMouseEnter={() => {
                    setHighlightRowIndex(i);
                  }}
                  onMouseLeave={() => {
                    setHighlightRowIndex(-1);
                  }}
                >
                  {field?.title}
                </div>
              ) : null;
            }

            return field?.title && title !== "custom_fields" ? (
              <div
                key={`${title}_title`}
                className={`${styles.titles__oneTitle} ${
                  param?.required ? styles.titles__oneTitle_required : ""
                } ${
                  title === "measurements" &&
                  aregisterAppcreateStore.openedAllInitialValuesForMassCopy[id]
                    ?.project &&
                  aregisterAppcreateStore.projects[
                    aregisterAppcreateStore.openedAllInitialValuesForMassCopy[
                      id
                    ].project
                  ]?.allow_manual_size === 1
                    ? styles.titles__oneTitle_size_big
                    : title === "measurements"
                    ? styles.titles__oneTitle_size
                    : ""
                } ${title === "kc" ? styles.titles__oneTitle_kc : ""} ${
                  highlightRowIndex === i || focusRowIndex === i
                    ? styles.highlighted
                    : ""
                }`}
                onMouseEnter={() => {
                  setHighlightRowIndex(i);
                }}
                onMouseLeave={() => {
                  setHighlightRowIndex(-1);
                }}
              >
                {field?.title}
              </div>
            ) : null;
          })}
          {checkCustomFields()
            ? Object.keys(
                aregisterAppcreateStore.projects[values.original.project].custom
                  .custom_fields
              ).map((customTitle, i) => {
                return (
                  <Tooltip
                    key={`${customTitle}_title`}
                    text="Дополнительное поле"
                  >
                    <div
                      className={`${styles.titles__oneTitle} ${
                        styles.titles__oneTitle_required
                      } ${styles.titles__oneTitle_custom} ${
                        highlightRowIndex === i + 100 ||
                        focusRowIndex === i + 100
                          ? styles.highlighted
                          : ""
                      }`}
                      onMouseEnter={() => {
                        setHighlightRowIndex(i + 100);
                      }}
                      onMouseLeave={() => {
                        setHighlightRowIndex(-1);
                      }}
                    >
                      {customTitle}
                    </div>
                  </Tooltip>
                );
              })
            : null}
        </div>

        {/* Оригинальная заявка */}
        <div className={styles.original}>
          {Object.entries(values.original).map(([key, value], i) => {
            const field = aregisterAppcreateStore.cols[key];
            const param = aregisterAppcreateStore.params[key];
            return field?.title ? (
              <Fragment key={key}>
                {i === 0 ? (
                  <div
                    className={`${styles.original__title} ${
                      menuStore.isScroll ? styles.shadow : ""
                    }`}
                  >
                    Оригинал
                  </div>
                ) : null}
                {(() => {
                  switch (key) {
                    case "project":
                      return (
                        <div
                          key={`${key}_field`}
                          className={`${styles.original__value} ${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          }`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          {aregisterAppcreateStore.projects[value as string]
                            ?.title || ""}
                        </div>
                      );
                    case "master":
                    case "author":
                    case "brigadier":
                      return value && Object.values(value).length ? (
                        <div
                          key={`${key}_field`}
                          className={`${styles.original__value} ${
                            styles.link
                          } ${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          }`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          {value === "masterless" ? (
                            "Без мастера"
                          ) : value["fio"] ? (
                            <>
                              <div>{`TH${value["tn"]}`}</div>
                              <Link
                                to={`/staff/id=${value["id"]}`}
                                id={`AregisterOneMasscopyFormColumns_${value["id"]}`}
                                target="_blank"
                              >
                                {value["fio"]}
                              </Link>
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        <div
                          key={`${key}_field`}
                          className={`${styles.original__value} ${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          }`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          {value as string}
                        </div>
                      );

                    case "measurements":
                      return (
                        <div
                          key={`${key}_field`}
                          className={`${styles.original__value} ${
                            styles.size
                          } ${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          } ${
                            aregisterAppcreateStore
                              .openedAllInitialValuesForMassCopy[id]?.project &&
                            aregisterAppcreateStore.projects[
                              aregisterAppcreateStore
                                .openedAllInitialValuesForMassCopy[id].project
                            ]?.allow_manual_size === 1
                              ? styles.size_big
                              : ""
                          }`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          <AregisterOneMasscopyFormFormula
                            values={values.original}
                            type="original"
                          />
                        </div>
                      );

                    case "manual_hours":
                      return values.original.project &&
                        aregisterAppcreateStore.projects[
                          values.original.project
                        ]?.allow_manual_hours === 1 ? (
                        <div
                          key={`${key}_field`}
                          className={`${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          } ${styles.original__value_manualHours}`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          <div
                            className={
                              styles.original__value_manualHours__title
                            }
                          >
                            {field.title}
                          </div>
                          <div
                            className={styles.original__value_manualHours__text}
                          >
                            {values.original.manual_hours ? (
                              values.original.manual_hours
                            ) : (
                              <IconDash className={styles.iconDash} />
                            )}
                          </div>
                        </div>
                      ) : null;

                    case "kc":
                      return (
                        <div
                          key={`${key}_field`}
                          className={`${styles.original__value} ${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          } ${styles.original__value_kc}`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          {value as string}
                        </div>
                      );

                    case "allow_manual_hours":
                    case "allow_manual_size":
                    case "manual_size":
                    case "length":
                    case "width":
                    case "height":
                    case "size":
                    case "conditional_cubes":
                    case "custom_fields":
                    case "no_kc":
                      return;

                    default:
                      if (
                        param?.isvariable ||
                        (param?.variable &&
                          Object.values(param.variable).length)
                      ) {
                        return (
                          <div
                            key={`${key}_field`}
                            className={`${styles.original__value} ${
                              highlightRowIndex === i || focusRowIndex === i
                                ? styles.highlighted
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightRowIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightRowIndex(-1);
                            }}
                          >
                            {param.variable[value as string]?.title || ""}
                          </div>
                        );
                      }
                      return (
                        <div
                          key={`${key}_field`}
                          className={`${styles.original__value} ${
                            highlightRowIndex === i || focusRowIndex === i
                              ? styles.highlighted
                              : ""
                          }`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                        >
                          {field.type === "date"
                            ? getFormattedDate(value as string)
                            : (value as string)}
                        </div>
                      );
                  }
                })()}
              </Fragment>
            ) : null;
          })}
          {checkCustomFields()
            ? Object.entries(
                aregisterAppcreateStore.projects[values.original.project].custom
                  .custom_fields
              ).map(([customTitle, customField], i) => {
                return (
                  <div
                    key={`${customTitle}_field`}
                    className={`${styles.original__value} ${
                      highlightRowIndex === i + 100 || focusRowIndex === i + 100
                        ? styles.highlighted
                        : ""
                    }`}
                    onMouseEnter={() => {
                      setHighlightRowIndex(i + 100);
                    }}
                    onMouseLeave={() => {
                      setHighlightRowIndex(-1);
                    }}
                  >
                    {values.original?.custom_fields?.[customTitle] &&
                    customField.type === "date"
                      ? getFormattedDate(
                          values.original.custom_fields[customTitle] as string
                        )
                      : values.original?.custom_fields?.[customTitle] || ""}
                  </div>
                );
              })
            : null}
        </div>

        {/* Массовое заполнение */}
        {isOpenedMassFill ? (
          <div
            className={`${styles.massfill} ${
              focusIndex === -1 ? styles.massfill_highlighted : ""
            }`}
          >
            {Object.entries(values.massfill).map(([key, value], i) => {
              const field = aregisterAppcreateStore.cols[key];
              const param = aregisterAppcreateStore.params[key];
              return field?.title ? (
                <Fragment key={key}>
                  {i === 0 ? (
                    <div
                      className={`${styles.massfill__title} ${
                        menuStore.isScroll ? styles.shadow : ""
                      }`}
                    >
                      Массовое заполнение
                    </div>
                  ) : null}
                  {(() => {
                    const addMassfill = (key: string) => {
                      values.copies.forEach((_copy, numeric) => {
                        if (key === "date_start") {
                          (values.copies[numeric]["date_end"] &&
                            parse(
                              values.massfill[key] as string,
                              "yyyy-MM-dd",
                              new Date()
                            ) >
                              parse(
                                values.copies[numeric]["date_end"] as string,
                                "yyyy-MM-dd",
                                new Date()
                              )) ||
                          !values.copies[numeric]["date_end"]
                            ? setFieldValue(
                                `copies[${numeric}]["date_end"]`,
                                values.massfill[key]
                              )
                            : "";
                        }

                        if (key === "kc") {
                          setFieldValue(
                            `copies[${numeric}][no_kc]`,
                            values.massfill.no_kc
                          );
                        }

                        if (key === "manual_hours") {
                          setFieldValue(
                            `copies[${numeric}][allow_manual_hours]`,
                            values.massfill.allow_manual_hours
                          );
                        }

                        setFieldValue(
                          `copies[${numeric}][${key}]`,
                          values.massfill[key]
                        );
                      });
                      setFocusIndex(null);
                      setFocusRowIndex(null);
                    };
                    switch (key) {
                      case "project":
                      case "measurements":
                      case "master":
                      case "author":
                      case "brigadier":
                      case "internal_num":
                        return (
                          <div
                            key={`${key}_field`}
                            className={`${styles.field_disabled}  ${
                              key === "measurements" &&
                              values.massfill?.project &&
                              aregisterAppcreateStore.projects[
                                values.massfill.project
                              ]?.allow_manual_size === 1
                                ? styles.field_disabled_size_big
                                : key === "measurements"
                                ? styles.field_disabled_size
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightRowIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightRowIndex(-1);
                            }}
                          >
                            {" "}
                          </div>
                        );

                      case "manual_hours":
                        return values.massfill.project &&
                          aregisterAppcreateStore.projects[
                            values.massfill.project
                          ]?.allow_manual_hours === 1 ? (
                          <div
                            key={`${key}_field`}
                            className={`${styles.field_manualHours_massfill} ${
                              highlightRowIndex === i || focusRowIndex === i
                                ? styles.highlighted
                                : ""
                            } ${
                              focusIndex === -1 && focusRowIndex === i
                                ? styles.focused
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightRowIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightRowIndex(-1);
                            }}
                          >
                            <div
                              id="AregisterOneMasscopyFormColumns_massfill[allow_manual_hours]"
                              onClick={() => {
                                setFieldValue(
                                  "massfill[allow_manual_hours]",
                                  values.massfill.allow_manual_hours ? 0 : 1
                                );
                                setFieldValue(
                                  "massfill[manual_hours]",
                                  values.massfill.manual_hours
                                    ? null
                                    : values.massfill.manual_hours
                                );
                              }}
                              className={
                                styles.field_manualHours_massfill__title
                              }
                            >
                              <div>{field.title}</div>
                              <Switch name={"massfill[allow_manual_hours]"} />
                            </div>
                            <div
                              ref={focusedRef}
                              onClick={(event) => {
                                if (
                                  event.target["localName"] === "path" ||
                                  event.target["localName"] === "svg" ||
                                  event.target["dataset"]["data-type"] ===
                                    "iconSend"
                                )
                                  return;
                                setFocusIndex(-1);
                                setFocusRowIndex(i);
                              }}
                              className={
                                styles.field_manualHours_massfill__input
                              }
                              id={`AregisterOneMasscopyFormColumns_massfill[${key}]`}
                            >
                              <Input
                                name="massfill[manual_hours]"
                                disabled={!values.massfill.allow_manual_hours}
                                autocomplete="off"
                                createRef
                                onClick={() => {
                                  setEditedCell(`massfill[${key}]`);
                                }}
                                onKeyDown={(e: { keyCode: number }) => {
                                  if (
                                    editedCell === `massfill[${key}]` &&
                                    e.keyCode === 27
                                  ) {
                                    handleEscPress(key);
                                  }
                                }}
                              />
                              <AregisterOneMasscopyFormSendBtn
                                name={`massfill[${key}]_sendBtn`}
                                onClick={() => {
                                  addMassfill(key);
                                }}
                                tableType="column"
                              />
                            </div>
                          </div>
                        ) : null;

                      case "kc":
                        return (
                          <div
                            key={`${key}_field`}
                            className={`${styles.field_kc} ${
                              highlightRowIndex === i || focusRowIndex === i
                                ? styles.highlighted
                                : ""
                            } ${
                              focusIndex === -1 && focusRowIndex === i
                                ? styles.focused_kc
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightRowIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightRowIndex(-1);
                            }}
                            ref={focusedRef}
                            onClick={(event) => {
                              if (
                                event.target["localName"] === "path" ||
                                event.target["localName"] === "svg" ||
                                event.target["dataset"]["data-type"] ===
                                  "iconSend"
                              )
                                return;
                              setFocusIndex(-1);
                              setFocusRowIndex(i);
                            }}
                            id={`AregisterOneMasscopyFormColumns_massfill[${key}]`}
                          >
                            <div
                              className={
                                styles.field_kc_massfill__fieldContainer
                              }
                            >
                              <Input
                                name={`massfill[${key}]`}
                                disabled={Boolean(values.massfill.no_kc)}
                                autocomplete="off"
                                createRef
                                onClick={() => {
                                  setEditedCell(`massfill[${key}]`);
                                }}
                                onKeyDown={(e: { keyCode: number }) => {
                                  if (
                                    editedCell === `massfill[${key}]` &&
                                    e.keyCode === 27
                                  ) {
                                    handleEscPress(key);
                                  }
                                }}
                              />
                              <label
                                className={
                                  styles.field_kc_massfill__fieldContainer__switch
                                }
                              >
                                <Switch
                                  name="massfill[no_kc]"
                                  onChange={(e) => {
                                    setFieldValue(
                                      `massfill[${key}]`,
                                      e.target.checked
                                        ? null
                                        : values.original[key]
                                    );
                                  }}
                                />
                                Не в КС
                              </label>
                            </div>
                            <AregisterOneMasscopyFormSendBtn
                              name={`massfill[${key}]_sendBtn`}
                              onClick={() => {
                                addMassfill(key);
                              }}
                              tableType="column"
                            />
                          </div>
                        );
                      case "length":
                      case "width":
                      case "height":
                      case "size":
                      case "conditional_cubes":
                      case "custom_fields":
                      case "manual_size":
                      case "no_kc":
                      case "allow_manual_hours":
                      case "allow_manual_size":
                        return;
                      default:
                        if (
                          param?.isvariable ||
                          (param?.variable &&
                            Object.values(param.variable).length)
                        ) {
                          return (
                            <div
                              key={`${key}_field`}
                              className={`${styles.field_select_massfill} ${
                                highlightRowIndex === i || focusRowIndex === i
                                  ? styles.highlighted
                                  : ""
                              } ${
                                focusIndex === -1 && focusRowIndex === i
                                  ? styles.focused_select
                                  : ""
                              }`}
                              onMouseEnter={() => {
                                setHighlightRowIndex(i);
                              }}
                              onMouseLeave={() => {
                                setHighlightRowIndex(-1);
                              }}
                              ref={focusedRef}
                              onClick={(event) => {
                                if (
                                  (event.target["localName"] === "path" &&
                                    !event.target["classList"]?.["0"]?.includes(
                                      "openBtn"
                                    )) ||
                                  (event.target["localName"] === "svg" &&
                                    !event.target["classList"]?.["0"]?.includes(
                                      "openBtn"
                                    )) ||
                                  event.target["dataset"]["data-type"] ===
                                    "iconSend"
                                )
                                  return;
                                setFocusIndex(-1);
                                setFocusRowIndex(i);
                                setEditedCell(`massfill[${key}]`);
                              }}
                              id={`AregisterOneMasscopyFormColumns_massfill[${key}]`}
                              onKeyDown={(e: { keyCode: number }) => {
                                if (
                                  editedCell === `massfill[${key}]` &&
                                  e.keyCode === 27
                                ) {
                                  handleEscPress(key);
                                }
                              }}
                            >
                              <Select
                                options={aregisterAppcreateStore.setDirectory(
                                  values.massfill.project as string,
                                  key
                                )}
                                name={`massfill[${key}]`}
                                label={
                                  param.variable[value as string]?.title || ""
                                }
                                valueName="id"
                                onClick={() => {
                                  setFocusIndex(null);
                                  setFocusRowIndex(null);
                                }}
                                required
                              />
                              <AregisterOneMasscopyFormSendBtn
                                name={`massfill[${key}]_sendBtn`}
                                onClick={() => {
                                  addMassfill(key);
                                }}
                                tableType="column"
                              />
                            </div>
                          );
                        } else if (
                          field.type === "date" ||
                          field.type === "timestamp"
                        ) {
                          return (
                            <div
                              key={`${key}_field`}
                              className={classNames(styles.massDateCell, {
                                [styles.highlighted]:
                                  highlightRowIndex === i ||
                                  focusRowIndex === i,
                                [styles.focused_date]:
                                  focusIndex === -1 && focusRowIndex === i
                              })}
                              onMouseEnter={() => {
                                setHighlightRowIndex(i);
                              }}
                              onMouseLeave={() => {
                                setHighlightRowIndex(-1);
                              }}
                              ref={focusedRef}
                              onClick={() => {
                                setFocusIndex(-1);
                                setFocusRowIndex(i);
                                setEditedCell(`massfill[${key}]`);
                              }}
                              id={`AregisterOneMasscopyFormColumns_massfill[${key}]`}
                              onKeyDown={(e: { keyCode: number }) => {
                                if (
                                  editedCell === `massfill[${key}]` &&
                                  e.keyCode === 27
                                ) {
                                  handleEscPress(key);
                                }
                              }}
                            >
                              <DatePickerField
                                name={`massfill[${key}]`}
                                title=""
                                isCalendarOpened={
                                  openedListName === `massfill_${key}_${i}`
                                }
                                setIsCalendarOpened={() => {
                                  changeOpenedListName(`massfill_${key}_${i}`);
                                }}
                                onChange={(value) => {
                                  if (key === "date_start") {
                                    (values.massfill.date_end &&
                                      parse(value, "yyyy-MM-dd", new Date()) >
                                        parse(
                                          values.massfill.date_end as string,
                                          "yyyy-MM-dd",
                                          new Date()
                                        )) ||
                                    !values.massfill.date_end
                                      ? setFieldValue(
                                          `massfill[date_end]`,
                                          value
                                        )
                                      : "";
                                  }
                                }}
                                minDate={
                                  key === "date_end" &&
                                  values.massfill.date_start
                                    ? parse(
                                        values.massfill.date_start as string,
                                        "yyyy-MM-dd",
                                        new Date()
                                      )
                                    : null
                                }
                                className={styles.datePickerCustomMass}
                              />
                              <AregisterOneMasscopyFormSendBtn
                                name={`massfill[${key}]_sendBtn`}
                                onClick={() => {
                                  addMassfill(key);
                                }}
                                tableType="column"
                              />
                            </div>
                          );
                        }
                        return (
                          <div
                            key={`${key}_field`}
                            className={`${styles.field_massfill} ${
                              highlightRowIndex === i || focusRowIndex === i
                                ? styles.highlighted
                                : ""
                            } ${
                              focusIndex === -1 && focusRowIndex === i
                                ? styles.focused
                                : ""
                            }`}
                            onMouseEnter={() => {
                              setHighlightRowIndex(i);
                            }}
                            onMouseLeave={() => {
                              setHighlightRowIndex(-1);
                            }}
                            ref={focusedRef}
                            onClick={() => {
                              setFocusIndex(-1);
                              setFocusRowIndex(i);
                            }}
                            id={`AregisterOneMasscopyFormColumns_massfill[${key}]`}
                          >
                            <Input
                              name={`massfill[${key}]`}
                              autocomplete="off"
                              createRef
                              onClick={() => {
                                setEditedCell(`massfill[${key}]`);
                              }}
                              onKeyDown={(e: { keyCode: number }) => {
                                if (
                                  editedCell === `massfill[${key}]` &&
                                  e.keyCode === 27
                                ) {
                                  handleEscPress(key);
                                }
                              }}
                            />
                            <AregisterOneMasscopyFormSendBtn
                              name={`massfill[${key}]_sendBtn`}
                              onClick={() => {
                                addMassfill(key);
                              }}
                              tableType="column"
                            />
                          </div>
                        );
                    }
                  })()}
                </Fragment>
              ) : null;
            })}

            {checkCustomFields()
              ? Object.entries(
                  aregisterAppcreateStore.projects[values.original.project]
                    .custom.custom_fields
                ).map(([customTitle, customField], i) => {
                  const addMassfillCustomFields = (key: string) => {
                    values.copies.forEach((_copy, numeric) => {
                      setFieldValue(
                        `copies[${numeric}][custom_fields][${key}]`,
                        values.massfill.custom_fields[key]
                      );
                    });
                    setFocusIndex(null);
                    setFocusRowIndex(null);
                  };
                  return (
                    <div
                      key={`${customTitle}_customField`}
                      className={`${(() => {
                        switch (customField.type) {
                          case "select":
                            return styles.field_select_massfill;

                          case "text":
                          default:
                            return styles.field_massfill;
                        }
                      })()} ${
                        highlightRowIndex === i + 100 ||
                        (focusIndex === -1 && focusRowIndex === i + 100)
                          ? styles.highlighted
                          : ""
                      } `}
                      onMouseEnter={() => {
                        setHighlightRowIndex(i + 100);
                      }}
                      onMouseLeave={() => {
                        setHighlightRowIndex(-1);
                      }}
                      ref={focusedRef}
                      id={`AregisterOneMasscopyFormColumns_massfill[custom_fields][${customTitle}]`}
                      onClick={() => {
                        ["date", "select"].includes(customField.type) &&
                          setEditedCell(
                            `massfill[custom_fields][${customTitle}]`
                          );
                        setFocusIndex(-1);
                        setFocusRowIndex(i + 100);
                      }}
                      onKeyDown={(e: { keyCode: number }) => {
                        if (
                          editedCell ===
                            `massfill[custom_fields][${customTitle}]` &&
                          e.keyCode === 27 &&
                          ["date", "select"].includes(customField.type)
                        ) {
                          handleEscPress(customTitle, "custom_fields");
                        }
                      }}
                    >
                      {(() => {
                        const name = `massfill[custom_fields][${customTitle}]`;
                        switch (customField.type) {
                          case "select":
                            return (
                              <Select
                                name={name}
                                label={values[name] as string}
                                title={" "}
                                options={customField.data}
                                valueName="id"
                                isFloating
                              />
                            );

                          case "date":
                            return (
                              <DatePickerField
                                name={name}
                                isCalendarOpened={openedListName === name}
                                setIsCalendarOpened={() => {
                                  changeOpenedListName(name);
                                }}
                                title={" "}
                                bottom
                                className={styles.datePickerCustom}
                              />
                            );

                          case "text":
                          default:
                            {
                              /* TODO: возможно нужно будет править, т.к. сейчас интерфейс недоступен */
                            }
                            return (
                              <Input
                                name={name}
                                autocomplete="off"
                                createRef
                                onClick={() => {
                                  setEditedCell(
                                    `massfill[custom_fields][${customTitle}]`
                                  );
                                }}
                                onKeyDown={(e: { keyCode: number }) => {
                                  if (
                                    editedCell ===
                                      `massfill[custom_fields][${customTitle}]` &&
                                    e.keyCode === 27
                                  ) {
                                    handleEscPress(
                                      customTitle,
                                      "custom_fields"
                                    );
                                  }
                                }}
                              />
                            );
                        }
                      })()}
                      <AregisterOneMasscopyFormSendBtn
                        name={`massfill[${customTitle}]_sendBtn`}
                        onClick={() => {
                          addMassfillCustomFields(customTitle);
                        }}
                        tableType="column"
                      />
                    </div>
                  );
                })
              : null}
          </div>
        ) : null}
      </div>

      {/* Формы копий заявки */}
      {values.copies.length
        ? values.copies.map((copy, numeric) => {
            return (
              <div
                className={numeric % 2 ? styles.column : styles.column_gray}
                key={`${numeric + 1}/${Object.values(values).length}`}
                onMouseEnter={() => {
                  setHighlightIndex(numeric);
                }}
                onMouseLeave={() => {
                  setHighlightIndex(-1);
                }}
              >
                {Object.entries(copy).map(([key, value], i) => {
                  const field = aregisterAppcreateStore.cols[key];
                  const param = aregisterAppcreateStore.params[key];
                  return field?.title ? (
                    <Fragment key={key}>
                      <>
                        {i === 0 ? (
                          <div
                            className={`${styles.column__title} ${
                              highlightIndex === numeric ||
                              highlightRowIndex === i ||
                              focusIndex === numeric ||
                              focusRowIndex === i
                                ? styles.highlighted
                                : ""
                            } ${menuStore.isScroll ? styles.shadow : ""}`}
                          >
                            <div>Копия</div>
                            <div>{`${numeric + 1}/${
                              values.copies.length
                            }`}</div>
                            <IconClose
                              className={styles.column__title__iconClose}
                              id={`AregisterOneMasscopyFormColumns_deleteCopyBtn_${numeric}`}
                              onClick={() => {
                                const oldCopies = values.copies;

                                oldCopies.splice(numeric, 1);

                                setFieldValue(
                                  "copies",
                                  oldCopies.length ? oldCopies : []
                                );

                                if (!oldCopies.length && isOpenedMassFill) {
                                  setFieldValue("massfill", {});
                                }
                              }}
                            />
                          </div>
                        ) : null}
                        {(() => {
                          switch (key) {
                            case "project":
                              return (
                                <div
                                  key={`${key}_field`}
                                  className={`${styles.field} ${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  }`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(numeric);
                                    setFocusRowIndex(i);
                                  }}
                                  id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                >
                                  {/* TODO: возможно нужно будет править, т.к. сейчас интерфейс недоступен */}
                                  <ViewField
                                    title=""
                                    value={
                                      aregisterAppcreateStore.projects[
                                        value as string
                                      ]?.title || ""
                                    }
                                    disabled
                                  />
                                </div>
                              );

                            case "internal_num":
                              return (
                                <div
                                  key={`${key}_field`}
                                  className={`${styles.field} ${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  }`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(numeric);
                                    setFocusRowIndex(i);
                                  }}
                                  id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                >
                                  <Input
                                    name={`copies[${numeric}][${key}]`}
                                    onChange={(e) => {
                                      e.target.value?.length ||
                                      e.target.value?.trim()?.length !== 0
                                        ? aregisterAppcreateStore.checkInternalNum(
                                            `${id}_masscopy`,
                                            values.original.project as string,
                                            e.target.value,
                                            numeric
                                          )
                                        : aregisterAppcreateStore.deleteErrors(
                                            `${id}_masscopy`,
                                            key,
                                            numeric
                                          );
                                    }}
                                    autocomplete="off"
                                    createRef
                                    onClick={() => {
                                      setEditedCell(
                                        `copies[${numeric}][${key}]`
                                      );
                                    }}
                                    onKeyDown={(e: { keyCode: number }) => {
                                      if (
                                        editedCell ===
                                          `copies[${numeric}][${key}]` &&
                                        e.keyCode === 27
                                      ) {
                                        handleEscPress(key);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            case "master":
                            case "brigadier":
                              return (
                                <div
                                  key={`${key}_field`}
                                  className={`${styles.field_select} ${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  }`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                  onClick={() => {
                                    setEditedCell(`copies[${numeric}][${key}]`);
                                  }}
                                  id={`AregisterOneMasscopyFormTable_copies[${numeric}][${key}]_cell`}
                                  onKeyDown={(e: { keyCode: number }) => {
                                    if (
                                      editedCell ===
                                        `copies[${numeric}][${key}]` &&
                                      e.keyCode === 27
                                    ) {
                                      handleEscPress(key);
                                    }
                                  }}
                                >
                                  <Select
                                    options={aregisterAppcreateStore.setDirectory(
                                      copy.project as string,
                                      key,
                                      id,
                                      numeric
                                    )}
                                    name={`copies[${numeric}][${key}]`}
                                    label={
                                      param.variable[value as string]?.title ||
                                      ""
                                    }
                                    valueName="id"
                                    required
                                  />
                                </div>
                              );

                            case "measurements":
                              return (
                                <div
                                  key={`${key}_field`}
                                  className={`${styles.field} ${styles.size} ${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  } ${
                                    copy.project &&
                                    aregisterAppcreateStore.projects[
                                      copy.project as string
                                    ].allow_manual_size === 1
                                      ? styles.size_bigInputs
                                      : ""
                                  }`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(numeric);
                                    setFocusRowIndex(i);
                                  }}
                                  id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                >
                                  <AregisterOneMasscopyFormFormula
                                    values={copy}
                                    type="edit"
                                    numeric={numeric}
                                    onClick={(name: string) => {
                                      setEditedCell(name);
                                    }}
                                    onKeyDown={(name: string, key: string) => {
                                      if (editedCell === name) {
                                        handleEscPress(name, key);
                                      }
                                    }}
                                  />
                                </div>
                              );
                            case "manual_hours":
                              return copy.project &&
                                aregisterAppcreateStore.projects[copy.project]
                                  ?.allow_manual_hours === 1 ? (
                                <div
                                  key={`${key}_field`}
                                  className={`${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  } ${styles.field_manualHours}`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                >
                                  <div
                                    id={`AregisterOneMasscopyFormColumns_copies[${numeric}][allow_manual_hours]`}
                                    onClick={() => {
                                      setFieldValue(
                                        `copies[${numeric}][allow_manual_hours]`,
                                        copy.allow_manual_hours ? 0 : 1
                                      );
                                      setFieldValue(
                                        `copies[${numeric}][manual_hours]`,
                                        copy.manual_hours
                                          ? null
                                          : copy.manual_hours
                                      );
                                    }}
                                    className={styles.field_manualHours__title}
                                  >
                                    <div>{field.title}</div>
                                    <Switch
                                      name={`copies[${numeric}][allow_manual_hours]`}
                                    />
                                  </div>
                                  <div
                                    ref={focusedRef}
                                    onClick={() => {
                                      setFocusIndex(numeric);
                                      setFocusRowIndex(i);
                                    }}
                                    id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                    className={styles.field_manualHours__input}
                                  >
                                    <Input
                                      name={`copies[${numeric}][${key}]`}
                                      disabled={!copy.allow_manual_hours}
                                      autocomplete="off"
                                      createRef
                                      onClick={() => {
                                        setEditedCell(
                                          `copies[${numeric}][${key}]`
                                        );
                                      }}
                                      onKeyDown={(e: { keyCode: number }) => {
                                        if (
                                          editedCell ===
                                            `copies[${numeric}][${key}]` &&
                                          e.keyCode === 27
                                        ) {
                                          handleEscPress(key);
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ) : null;

                            case "kc":
                              return (
                                <div
                                  key={`${key}_field`}
                                  className={`${styles.field_kc} ${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  }`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(numeric);
                                    setFocusRowIndex(i);
                                  }}
                                  id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                >
                                  <div
                                    className={styles.field_kc__fieldContainer}
                                  >
                                    <Input
                                      name={`copies[${numeric}][${key}]`}
                                      disabled={Boolean(copy.no_kc)}
                                      autocomplete="off"
                                      createRef
                                      onClick={() => {
                                        setEditedCell(
                                          `copies[${numeric}][${key}]`
                                        );
                                      }}
                                      onKeyDown={(e: { keyCode: number }) => {
                                        if (
                                          editedCell ===
                                            `copies[${numeric}][${key}]` &&
                                          e.keyCode === 27
                                        ) {
                                          handleEscPress(key);
                                        }
                                      }}
                                    />
                                    <label
                                      className={
                                        styles.field_kc__fieldContainer__switch
                                      }
                                    >
                                      <Switch
                                        name={`copies[${numeric}][no_kc]`}
                                        onChange={(e) => {
                                          setFieldValue(
                                            `copies[${numeric}][${key}]`,
                                            e.target.checked
                                              ? null
                                              : values.original[key]
                                          );
                                        }}
                                      />
                                      Не в КС
                                    </label>
                                  </div>
                                </div>
                              );

                            case "allow_manual_hours":
                            case "allow_manual_size":
                            case "manual_size":
                            case "length":
                            case "width":
                            case "height":
                            case "size":
                            case "conditional_cubes":
                            case "custom_fields":
                            case "no_kc":
                              return;
                            default:
                              if (
                                param?.isvariable ||
                                (param?.variable &&
                                  Object.values(param.variable).length)
                              ) {
                                return (
                                  <div
                                    key={`${key}_field`}
                                    className={`${styles.field_select} ${
                                      highlightIndex === numeric ||
                                      highlightRowIndex === i ||
                                      focusIndex === numeric ||
                                      focusRowIndex === i
                                        ? styles.highlighted
                                        : ""
                                    }`}
                                    onMouseEnter={() => {
                                      setHighlightRowIndex(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHighlightRowIndex(-1);
                                    }}
                                    ref={focusedRef}
                                    onClick={() => {
                                      setFocusIndex(numeric);
                                      setFocusRowIndex(i);
                                      setEditedCell(
                                        `copies[${numeric}][${key}]`
                                      );
                                    }}
                                    id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                    onKeyDown={(e: { keyCode: number }) => {
                                      if (
                                        editedCell ===
                                          `copies[${numeric}][${key}]` &&
                                        e.keyCode === 27
                                      ) {
                                        handleEscPress(key);
                                      }
                                    }}
                                  >
                                    <Select
                                      options={aregisterAppcreateStore.setDirectory(
                                        copy.project as string,
                                        key
                                      )}
                                      name={`copies[${numeric}][${key}]`}
                                      label={
                                        key === "contracts" &&
                                        copy[key] ===
                                          initialValues["copies"]?.[numeric]?.[
                                            key
                                          ]
                                          ? param.variable["default_contract"]
                                              .title
                                          : param.variable[value as string]
                                              ?.title || ""
                                      }
                                      valueName="id"
                                      required
                                    />
                                  </div>
                                );
                              } else if (
                                field.type === "date" ||
                                field.type === "timestamp"
                              ) {
                                return (
                                  <div
                                    key={`${key}_field`}
                                    className={`${
                                      highlightIndex === numeric ||
                                      highlightRowIndex === i ||
                                      focusIndex === numeric ||
                                      focusRowIndex === i
                                        ? styles.highlighted
                                        : ""
                                    }`}
                                    onMouseEnter={() => {
                                      setHighlightRowIndex(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHighlightRowIndex(-1);
                                    }}
                                    ref={focusedRef}
                                    onClick={() => {
                                      setFocusIndex(numeric);
                                      setFocusRowIndex(i);
                                      setEditedCell(
                                        `copies[${numeric}][${key}]`
                                      );
                                    }}
                                    id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                    onKeyDown={(e: { keyCode: number }) => {
                                      if (
                                        editedCell ===
                                          `copies[${numeric}][${key}]` &&
                                        e.keyCode === 27
                                      ) {
                                        handleEscPress(key);
                                      }
                                    }}
                                  >
                                    <DatePickerField
                                      name={`copies[${numeric}][${key}]`}
                                      title={""}
                                      isCalendarOpened={
                                        openedListName === `${key}_${numeric}`
                                      }
                                      setIsCalendarOpened={() => {
                                        changeOpenedListName(
                                          `${key}_${numeric}`
                                        );
                                      }}
                                      onChange={(value) => {
                                        if (key === "date_start") {
                                          (values.copies[numeric]["date_end"] &&
                                            parse(
                                              value,
                                              "yyyy-MM-dd",
                                              new Date()
                                            ) >
                                              parse(
                                                values.copies[numeric][
                                                  "date_end"
                                                ] as string,
                                                "yyyy-MM-dd",
                                                new Date()
                                              )) ||
                                          !values.copies[numeric]["date_end"]
                                            ? setFieldValue(
                                                `copies[${numeric}]["date_end"]`,
                                                value
                                              )
                                            : "";
                                        }

                                        if (
                                          ["date_start", "date_end"].includes(
                                            key
                                          )
                                        ) {
                                          setFieldValue(
                                            `copies[${numeric}][master]]`,
                                            "masterless"
                                          );
                                          setFieldValue(
                                            `copies[${numeric}][brigadier]]`,
                                            null
                                          );
                                          new Promise(() =>
                                            aregisterAppcreateStore.getMastersAndGangers(
                                              values.original.project as string,
                                              values.copies[numeric][
                                                "date_start"
                                              ] as string,
                                              values.copies[numeric][
                                                "date_end"
                                              ] as string,
                                              `copies_${id}_${numeric}`
                                            )
                                          ).then(() => {
                                            setTimeout(() => {
                                              setFieldTouched(
                                                `copies[${numeric}][master]]`
                                              );
                                              setFieldTouched(
                                                `copies[${numeric}][brigadier]]`
                                              );
                                            }, 500);
                                          });
                                        }
                                      }}
                                      minDate={
                                        key === "date_end" &&
                                        values.copies[numeric].date_start
                                          ? parse(
                                              values.copies[numeric]
                                                .date_start as string,
                                              "yyyy-MM-dd",
                                              new Date()
                                            )
                                          : null
                                      }
                                      className={styles.datePickerCustom}
                                    />
                                  </div>
                                );
                              }
                              return (
                                <div
                                  key={`${key}_field`}
                                  className={`${styles.field} ${
                                    highlightIndex === numeric ||
                                    highlightRowIndex === i ||
                                    focusIndex === numeric ||
                                    focusRowIndex === i
                                      ? styles.highlighted
                                      : ""
                                  }`}
                                  onMouseEnter={() => {
                                    setHighlightRowIndex(i);
                                  }}
                                  onMouseLeave={() => {
                                    setHighlightRowIndex(-1);
                                  }}
                                  ref={focusedRef}
                                  onClick={() => {
                                    setFocusIndex(numeric);
                                    setFocusRowIndex(i);
                                  }}
                                  id={`AregisterOneMasscopyFormColumns_copies[${numeric}][${key}]`}
                                >
                                  <Input
                                    name={`copies[${numeric}][${key}]`}
                                    autocomplete="off"
                                    createRef
                                    onClick={() => {
                                      setEditedCell(
                                        `copies[${numeric}][${key}]`
                                      );
                                    }}
                                    onKeyDown={(e: { keyCode: number }) => {
                                      if (
                                        editedCell ===
                                          `copies[${numeric}][${key}]` &&
                                        e.keyCode === 27
                                      ) {
                                        handleEscPress(key);
                                      }
                                    }}
                                  />
                                </div>
                              );
                          }
                        })()}
                      </>
                    </Fragment>
                  ) : null;
                })}
                {checkCustomFields()
                  ? Object.entries(
                      aregisterAppcreateStore.projects[values.original.project]
                        .custom.custom_fields
                    ).map(([customTitle, customField], i) => {
                      return (
                        <div
                          key={`${customTitle}_customField`}
                          className={`${(() => {
                            switch (customField.type) {
                              case "select":
                                return styles.field_select;

                              case "text":
                              default:
                                return styles.field;
                            }
                          })()} ${
                            highlightIndex === numeric ||
                            highlightRowIndex === i + 100 ||
                            focusIndex === numeric ||
                            focusRowIndex === i + 100
                              ? styles.highlighted
                              : ""
                          }`}
                          onMouseEnter={() => {
                            setHighlightRowIndex(i + 100);
                          }}
                          onMouseLeave={() => {
                            setHighlightRowIndex(-1);
                          }}
                          ref={focusedRef}
                          onClick={() => {
                            setFocusIndex(numeric);
                            setFocusRowIndex(i + 100);
                            ["date", "select"].includes(customField.type) &&
                              setEditedCell(
                                `copies[${numeric}][custom_fields][${customTitle}]`
                              );
                          }}
                          id={`AregisterOneMasscopyFormColumns_copies[${numeric}][custom_fields][${customTitle}]`}
                          onKeyDown={(e: { keyCode: number }) => {
                            if (
                              editedCell ===
                                `copies[${numeric}][custom_fields][${customTitle}]` &&
                              e.keyCode === 27 &&
                              ["date", "select"].includes(customField.type)
                            ) {
                              handleEscPress(customTitle, "custom_fields");
                            }
                          }}
                        >
                          {(() => {
                            const name = `copies[${numeric}][custom_fields][${customTitle}]`;
                            switch (customField.type) {
                              case "select":
                                return (
                                  <Select
                                    name={name}
                                    label={values[name] as string}
                                    title={" "}
                                    options={customField.data}
                                    valueName="id"
                                    isFloating
                                  />
                                );

                              case "date":
                                return (
                                  <DatePickerField
                                    name={name}
                                    isCalendarOpened={openedListName === name}
                                    setIsCalendarOpened={() => {
                                      changeOpenedListName(name);
                                    }}
                                    className={styles.datePickerCustom}
                                    title={" "}
                                    bottom
                                  />
                                );

                              case "text":
                              default:
                                {
                                  /* TODO: возможно нужно будет править, т.к. сейчас интерфейс недоступен */
                                }
                                return (
                                  <Input
                                    name={name}
                                    autocomplete="off"
                                    createRef
                                    onClick={() => {
                                      setEditedCell(
                                        `copies[${numeric}][custom_fields][${customTitle}]`
                                      );
                                    }}
                                    onKeyDown={(e: { keyCode: number }) => {
                                      if (
                                        editedCell ===
                                          `copies[${numeric}][custom_fields][${customTitle}]` &&
                                        e.keyCode === 27
                                      ) {
                                        handleEscPress(
                                          customTitle,
                                          "custom_fields"
                                        );
                                      }
                                    }}
                                  />
                                );
                            }
                          })()}
                        </div>
                      );
                    })
                  : null}
              </div>
            );
          })
        : null}
    </div>
  );
};

export default observer(AregisterOneMasscopyFormColumns);
