import { observer } from "mobx-react-lite";
import { useState, useEffect, useRef } from "react";
import { useStores } from "stores/index";
import { useDropzone } from "react-dropzone";
import { Formik } from "formik";
import Switch from "shared/ui/Inputs/Switch";
import FormWindow from "shared/ui/FormWindow";
import styles from "./staffOneEditPhotoAltGallery.module.scss";
import StaffOneEditPhotoAltCropper from "../StaffOneEditPhotoAltCropper/index";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";

import { ReactComponent as IconBack } from "shared/assets/images/mainIcons/iconBack/iconBackWithoutStroke.svg";
import { ReactComponent as IconNext } from "shared/assets/images/mainIcons/iconBack/iconBackWithoutStroke.svg";
import { ReactComponent as IconImage } from "shared/assets/images/mainIcons/iconImage.svg";
import { ReactComponent as IconAddWithoutFill } from "shared/assets/images/mainIcons/iconAdd/iconAddWithoutFill.svg";
import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";
import iconStar from "shared/assets/images/mainIcons/iconIndicatorStar.svg";
import StatusIcon from "shared/ui/StatusIcon";

type StaffOneEditPhotoAltGalleryProps = {
  loadedImages: { hash: string; isMain: boolean; src: string }[];
  cropData: string;
  setCropData: (cropData: string) => void;
  imageIndex: number;
  setImageIndex: (imageIndex: number) => void;
  galleryState: string;
  setGalleryState: (galleryState: string) => void;
  userId: string;
};

interface IFile extends File {
  preview?: string;
  path?: string;
}

const StaffOneEditPhotoAltGallery = ({
  loadedImages,
  cropData,
  setCropData,
  imageIndex,
  setImageIndex,
  galleryState,
  setGalleryState,
  userId
}: StaffOneEditPhotoAltGalleryProps) => {
  const cropperRef = useRef(undefined);
  const { staffOneEditStore } = useStores();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [editingFromViewing, setEditingFromViewing] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/*": []
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
      setGalleryState("editing");
    }
  });

  const closeWindow = () => {
    if (cropData) {
      setShowDeleteModal(true);
    } else {
      setGalleryState("closed");
    }
  };

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <div className={styles.modalWindow}>
      <Formik
        initialValues={{
          isMain:
            loadedImages[imageIndex] !== undefined &&
            loadedImages[imageIndex]["isMain"]
              ? 1
              : 0
        }}
        validateOnBlur
        validateOnChange
        onSubmit={() => {
          return;
        }}
        enableReinitialize
      >
        {() => {
          return (
            <FormWindow
              title={
                galleryState === "uploading"
                  ? "Загрузка фото"
                  : galleryState === "editing"
                  ? "Редактирование фото"
                  : "Просмотр фото"
              }
              setOpenWindow={() => setGalleryState("closed")}
              optionalCloseFunc={closeWindow}
              isNotHaveButtons
              ignorOnClickOutside
              isScroll
            >
              <div className="w-100 h-100">
                <div className={styles.grayBackgroundContainer}>
                  {galleryState === "uploading" && (
                    <>
                      <div className={styles.addPlaceBorder}>
                        <section className="container">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <div className={styles.iconAddWrapper}>
                              <div className={styles.iconAdd}>
                                <IconImage
                                  className={styles.iconAddFotoDefault}
                                />
                                <IconAddWithoutFill
                                  className={styles.iconAddWithoutFill}
                                />
                              </div>
                            </div>
                            <input {...getInputProps()} />
                            <p className={styles.addPlaceText}>
                              Перетащите изображение или загрузите с компьютера
                            </p>
                            <div className="d-flex flex-column">
                              <em>
                                Только файлы с расширениями .jpg, .jpeg, .png
                              </em>
                              <em>Объём файла не должен превышать 20МБ</em>
                            </div>
                          </div>
                        </section>
                      </div>
                      <div className={styles.uploadBtnBox}>
                        <button
                          type="button"
                          onClick={open}
                          className={styles.uploadBtn}
                          id="StaffOneEditPhotoAltGallery_uploadPhoto"
                        >
                          Загрузить с компьютера
                        </button>
                      </div>
                    </>
                  )}
                  {galleryState === "editing" && (
                    <>
                      <StaffOneEditPhotoAltCropper
                        file={
                          imageIndex !== null
                            ? loadedImages[imageIndex]["src"]
                            : files[0]?.preview
                        }
                        ref={cropperRef}
                      />
                      {editingFromViewing ? (
                        <div className={styles.blackBtnsBox}>
                          <button
                            type="button"
                            className={styles.cancelBtn}
                            onClick={() => {
                              setGalleryState("viewing");
                              setEditingFromViewing(false);
                              setCropData(null);
                            }}
                            id="StaffOneEditPhotoAltGallery_closeEditing"
                          >
                            <IconClose className={styles.iconClose} />
                            Выйти без сохранения
                          </button>
                          <button
                            type="button"
                            className={styles.saveBtn}
                            onClick={async () => {
                              const lastElement = loadedImages[imageIndex][
                                "src"
                              ]
                                .split("/")
                                .pop();
                              const array = lastElement.split("?");
                              const fileName = array[0];
                              const imgData =
                                cropperRef.current?.getCropImage();
                              staffOneEditStore.setUploadPhoto(
                                loadedImages.length === 0 ||
                                  loadedImages[imageIndex]?.isMain
                                  ? userId
                                  : "",
                                await staffOneEditStore.getDataURLtoFile(
                                  imgData,
                                  fileName
                                )
                              );
                              setImageIndex(imageIndex);
                            }}
                            id="StaffOneEditPhotoAltGallery_saveImage"
                          >
                            Сохранить
                          </button>
                        </div>
                      ) : (
                        <div className={styles.blackBtnsBox}>
                          <button
                            type="button"
                            className={styles.cancelBtn}
                            onClick={() => {
                              cropData
                                ? setShowDeleteModal(true)
                                : setGalleryState("closed");
                            }}
                            id="StaffOneEditPhotoAltGallery_cancel"
                          >
                            <IconClose className={styles.iconClose} />
                            Отмена
                          </button>
                          <button
                            type="button"
                            className={styles.saveBtn}
                            onClick={async () => {
                              const imgData =
                                cropperRef.current?.getCropImage();

                              const file = imgData
                                ? await staffOneEditStore.getDataURLtoFile(
                                    imgData,
                                    files[0].path
                                  )
                                : files[0];

                              staffOneEditStore.setUploadPhoto(
                                loadedImages.length === 0 ||
                                  loadedImages[imageIndex]?.isMain
                                  ? userId
                                  : "",
                                file
                              );
                              setImageIndex(imageIndex);
                            }}
                            id="StaffOneEditPhotoAltGallery_savePhoto"
                          >
                            Сохранить и добавить
                          </button>
                        </div>
                      )}
                    </>
                  )}
                  {galleryState === "viewing" && (
                    <>
                      <div className={styles.prevNextBtnBox}>
                        <button
                          type="button"
                          className={`${styles.prevBtn} ${
                            imageIndex <= 0 ? styles.prevBtn__disabled : ""
                          }`}
                          onClick={() => setImageIndex(imageIndex - 1)}
                          disabled={imageIndex <= 0}
                          id="StaffOneEditPhotoAltGallery_previosPhoto"
                        >
                          <IconBack alt="Back" />
                        </button>
                        <div className={styles.galleryPhotoBackground}>
                          <div className={styles.iconsGalleryBox}>
                            <StaffOneEditPhotoAltCropper
                              file={loadedImages[imageIndex]["src"]}
                              galleryState={galleryState}
                              imageIndex={imageIndex}
                              ref={cropperRef}
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className={`${styles.nextBtn} ${
                            imageIndex >= loadedImages.length - 1
                              ? styles.nextBtn__disabled
                              : ""
                          }`}
                          onClick={() => setImageIndex(imageIndex + 1)}
                          disabled={imageIndex >= loadedImages.length - 1}
                          id="StaffOneEditPhotoAltGallery_nextPhoto"
                        >
                          <IconNext alt="Next" />
                        </button>
                      </div>
                    </>
                  )}
                </div>

                {galleryState === "viewing" && (
                  <div className={styles.manageBox}>
                    <div className={styles.leftCol}>
                      <label className={styles.switch}>
                        <Switch
                          name="isMain"
                          onChange={() => {
                            staffOneEditStore.makeAltPhotoTheMain(
                              loadedImages[imageIndex]["hash"]
                            );
                          }}
                        />
                        Фото профиля
                      </label>
                      {/* hardcode */}
                      <div className={styles.photoInfo}>
                        <p>
                          <span>Название изображения:</span> –{/* 12345.jpeg */}
                        </p>
                        <p>
                          <span>Загружено:</span> – {/* 23.02.2022. 17:08 */}
                        </p>
                        <p>
                          <span>Ответственный:</span> –
                          {/* Иванов Иван Иванович,
                          менеджер */}
                        </p>
                      </div>
                    </div>
                    <div className={styles.rightCol}>
                      {/* <button
                        type="button"
                        className={styles.manageBoxBtns}
                        onClick={() => {
                          setGalleryState("editing");
                          setEditingFromViewing(true);
                          setCropData(null);
                        }}
                        id="StaffOneEditPhotoAltGallery_editPhoto"
                      >
                        <StatusIcon icon="iconedit" color="bw-gray5" />{" "}
                        Редактировать
                      </button> */}
                      {/* <Tooltip
                        color="#9c9c9d"
                        backgroundColor="#F2F9FF"
                        borderColor="#F2F9FF"
                        placement="left"
                        text="Метод загрузки файлов еще не готов"
                      >
                        <button
                          type="button"
                          className={styles.manageBoxBtns}
                          id="StaffOneEditPhotoAltGallery_downloadPhoto"
                        >
                          <Download /> Скачать
                        </button>
                      </Tooltip> */}
                      <button
                        type="button"
                        className={styles.manageBoxBtns}
                        id="StaffOneEditPhotoAltGallery_deletePhoto"
                        onClick={() => {
                          staffOneEditStore.setDeletePhoto(
                            loadedImages[imageIndex]["hash"]
                          );
                        }}
                      >
                        <StatusIcon icon="iconclose" color="bw-gray5" /> Удалить
                      </button>
                    </div>
                  </div>
                )}
                {galleryState === "editing" && (
                  <div className={styles.imageNameBox}>
                    <p>
                      <span>Название изображения:</span> 12345.jpeg
                    </p>
                  </div>
                )}
                {galleryState === "viewing" ? (
                  <div className={styles.bottomPreviewGallery}>
                    {loadedImages.map(
                      (
                        photo: {
                          src: string | number;
                          hash: string;
                          isMain: boolean;
                        },
                        index
                      ) => {
                        if (photo["src"] !== -1)
                          return (
                            <div
                              className={styles.photoWrapper}
                              key={photo["hash"]}
                            >
                              <img
                                className={`${styles.iconAddPhoto} ${
                                  index === imageIndex
                                    ? styles.iconAddPhoto__disabled
                                    : ""
                                }`}
                                src={photo["src"] as string}
                                onClick={() => {
                                  setImageIndex(index);
                                }}
                              />
                              {photo["isMain"] ? (
                                <img
                                  src={iconStar}
                                  className={styles.iconStar}
                                  alt="Main"
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          );
                      }
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </FormWindow>
          );
        }}
      </Formik>
      <ClarificationModal
        show={showDeleteModal}
        onHide={() => {
          setGalleryState("closed");
        }}
        title="Закрыть окно без сохранения данных?"
        btnWithCrossTitle="Закрыть"
        btnWithCrossOnClick={() => {
          setShowDeleteModal(false);
          setGalleryState("closed");
          setCropData(null);
        }}
        btnBlueOnClick={() => {
          setShowDeleteModal(false);
        }}
        btnBlueTitle="Отмена"
        btnWithCrossBtnImg
      />
    </div>
  );
};

export default observer(StaffOneEditPhotoAltGallery);
