import styles from "./staffOneStatusesWindow.module.scss";
import { observer } from "mobx-react-lite";
import { useStores } from "stores/index";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Formik } from "formik";

import FormWindow from "shared/ui/FormWindow";
import Select from "shared/ui/Inputs/Select";
import StaffOneStatusesTabs from "./StaffOneStatusesTabs";
import StaffOneStatusesForm from "features/StaffOneStatusesForm";

import { ReactComponent as IconClose } from "shared/assets/images/mainIcons/iconsClose/iconCloseWithoutFill.svg";

import { getValidationSchema } from "shared/utils/validation/validationStaffForm";
import { classNames } from "shared/utils/helpers/classNames";
import { isEqual } from "lodash";
import { Status } from "stores/StaffModule/types/Status";
import { ViewField } from "shared/ui/ViewField";

type StaffOneStatusesWindowType = {
  selectedStatus?: { [key: string]: string | number };
};

const StaffOneStatusesWindow = ({
  selectedStatus
}: StaffOneStatusesWindowType) => {
  const { staffOneStatusesStore } = useStores();

  const [openedListName, setOpenedListName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [requiredFields, setRequiredFields] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    setRequiredFields(
      staffOneStatusesStore.openedWindow === "add" &&
        staffOneStatusesStore.statusesGroupsForAdd[
          staffOneStatusesStore.selectedStatus
        ]?.required_column
        ? staffOneStatusesStore.statusesGroupsForAdd[
            staffOneStatusesStore.selectedStatus
          ].required_column
        : staffOneStatusesStore.requiredColumnForEdit[
            staffOneStatusesStore.selectedStatus
          ]?.required_column
        ? staffOneStatusesStore.requiredColumnForEdit[
            staffOneStatusesStore.selectedStatus
          ].required_column
        : {}
    );
  }, [
    staffOneStatusesStore.statusesGroupsForAdd[
      staffOneStatusesStore.selectedStatus
    ]?.required_column,
    staffOneStatusesStore.requiredColumnForEdit[
      staffOneStatusesStore.selectedStatus
    ]?.required_column
  ]);

  const { id } = useParams();

  const changeOpenedWindows = (name: string) => {
    openedListName === name ? setOpenedListName("") : setOpenedListName(name);
  };

  useEffect(() => {
    isLoading !== staffOneStatusesStore.isLoadingForForm &&
      setIsLoading(staffOneStatusesStore.isLoadingForForm);
  }, [staffOneStatusesStore.isLoadingForForm]);

  const getFieldsForValidation = () => {
    let fields = Object.keys(
      staffOneStatusesStore.initialValuesForAdd[
        staffOneStatusesStore.selectedStatus
      ]
    );

    if (
      staffOneStatusesStore.statusesParams[staffOneStatusesStore.selectedStatus]
        ?.custom?.close_withholding &&
      staffOneStatusesStore.openedAllEventAllowEdu[id]?.result
    ) {
      fields = [...fields, "money", "date", "without_edu"];
    }
    return fields;
  };

  const getInitialValues = () => {
    const values = {
      ...(staffOneStatusesStore.openedWindow === "edit"
        ? staffOneStatusesStore.getInitialValuesForEdit(selectedStatus)
        : staffOneStatusesStore.initialValuesForAdd[
            staffOneStatusesStore.selectedStatus
          ]),
      company:
        staffOneStatusesStore.openedWindow === "edit"
          ? staffOneStatusesStore.companiesList[selectedStatus["company"]]?.[
              "id"
            ]
          : staffOneStatusesStore.companiesList[
              staffOneStatusesStore.selectedCompany
            ]
          ? staffOneStatusesStore.companiesList[
              staffOneStatusesStore.selectedCompany
            ]["id"]
          : null
    };

    if ("dismiss_position" in values) {
      values["dismiss_position"] =
        staffOneStatusesStore.actualPositions[id][
          staffOneStatusesStore.selectedCompany
        ];
    }

    if ("dismiss_object" in values) {
      values["dismiss_object"] =
        staffOneStatusesStore.statusesList[
          staffOneStatusesStore.selectedCompany
        ]?.[0]?.[0]?.["building_title"];
    }

    if (
      staffOneStatusesStore.statusesParams[staffOneStatusesStore.selectedStatus]
        ?.custom?.close_withholding &&
      staffOneStatusesStore.openedAllEventAllowEdu[id]?.result
    ) {
      values.money = staffOneStatusesStore.openedAllEventAllowEdu[id].sum;
      values.date = staffOneStatusesStore.openedAllEventAllowEdu[id].date;
      values.without_edu = 1;
    }
    return values;
  };

  return (
    <>
      {Object.values(staffOneStatusesStore.statusesCols).length &&
      Object.values(staffOneStatusesStore.initialValuesForAdd).length ? (
        <div className={`${styles.window} ${styles.loader}`} data-window={true}>
          <Formik
            enableReinitialize
            initialValues={getInitialValues()}
            validateOnBlur
            validateOnChange
            validationSchema={getValidationSchema(
              getFieldsForValidation(),
              Object.keys(requiredFields)
            )}
            onSubmit={(values: Status) => {
              Promise.all([
                staffOneStatusesStore.openedWindow === "add"
                  ? staffOneStatusesStore.addNewStaffEvent(
                      {
                        uid: id,
                        type: staffOneStatusesStore.selectedStatus,
                        ...values
                      },
                      id
                    )
                  : staffOneStatusesStore.openedWindow === "edit"
                  ? staffOneStatusesStore.updateStaffEvent(
                      {
                        event_id: staffOneStatusesStore.selectedRow,
                        ...values
                      },
                      id,
                      staffOneStatusesStore.getInitialValuesForEdit(
                        selectedStatus
                      )
                    )
                  : null
              ]).then(() => {
                if (
                  !Object.values(staffOneStatusesStore.errorsMessage).length
                ) {
                  staffOneStatusesStore.setOpenedWindow("");
                  staffOneStatusesStore.setSelectedRow("");
                }
              });
            }}
          >
            {({ handleReset, isValid, dirty }) => {
              // последний статус в выбранной компании
              const lastStatusForStaffModule =
                staffOneStatusesStore.statusesList[
                  staffOneStatusesStore.selectedCompany
                ] &&
                Object.values(
                  staffOneStatusesStore.statusesList[
                    staffOneStatusesStore.selectedCompany
                  ]
                ).length &&
                Object.values(
                  Object.values(
                    staffOneStatusesStore.statusesList[
                      staffOneStatusesStore.selectedCompany
                    ]
                  )[0]
                )?.[0];

              // минимальная дата начала при добавлении статуса для тех пользователей, чей доступ не позволяет ставить любые даты
              const minDateForDefaultUser =
                !staffOneStatusesStore.statusesParams[
                  lastStatusForStaffModule?.["type"]
                ]?.["custom"]["allow_event_start_bool"] &&
                staffOneStatusesStore.openedWindow !== "edit" &&
                new Date(lastStatusForStaffModule?.["event_start"]);

              return (
                <FormWindow
                  title={
                    staffOneStatusesStore.openedWindow === "add"
                      ? "Добавление статуса"
                      : "Редактирование статуса"
                  }
                  subtitle={
                    staffOneStatusesStore.openedWindow === "edit"
                      ? staffOneStatusesStore.statusesParams[
                          staffOneStatusesStore.selectedStatus
                        ].title
                      : ""
                  }
                  setOpenWindow={() =>
                    staffOneStatusesStore.setOpenedWindow("")
                  }
                  saveBtnTitle="Сохранить"
                  saveBtnDisabledValue={
                    isEqual(requiredFields, { event_start: 1 }) &&
                    staffOneStatusesStore.openedWindow === "add"
                      ? !isValid
                      : !isValid || !dirty
                  }
                  cancelBtnTitle={
                    staffOneStatusesStore.openedWindow === "edit"
                      ? "Удалить запись"
                      : ""
                  }
                  cancelBtnDisabled={
                    staffOneStatusesStore.openedWindow === "edit" &&
                    "delete" in staffOneStatusesStore.errorsMessage
                  }
                  cancelBtnOnClick={() => {
                    staffOneStatusesStore.deleteStaffEvent(
                      { event_id: staffOneStatusesStore.selectedRow },
                      id
                    );
                  }}
                  cancelBtnImg={<IconClose />}
                  addBtnTitle="Отмена"
                  addBtnImg={<IconClose />}
                  optionalCloseFunc={() => {
                    staffOneStatusesStore.setSelectedRow("");
                  }}
                  errors={Object.values(staffOneStatusesStore.errorsMessage)}
                  isLoadingForModal={
                    isLoading || staffOneStatusesStore.isLoading
                  }
                  isScroll
                >
                  <>
                    {staffOneStatusesStore.openedWindow === "add" &&
                    staffOneStatusesStore.selectedCompany !== "all" ? (
                      <StaffOneStatusesTabs
                        changeSelectedStatus={(key: string) => {
                          handleReset();
                          staffOneStatusesStore.setSelectedStatus(key);
                        }}
                      />
                    ) : (
                      ""
                    )}

                    <div
                      className={classNames(styles.objectAndCompanyBlock, {
                        [styles.selectCompany]:
                          staffOneStatusesStore.openedWindow === "add" &&
                          staffOneStatusesStore.selectedCompany !== "all"
                      })}
                    >
                      <Select
                        name="company"
                        title="Компания"
                        options={staffOneStatusesStore.companiesList}
                        disabled={staffOneStatusesStore.openedWindow !== "add"}
                        isFloating
                        valueName="id"
                        onClick={(option) => {
                          staffOneStatusesStore.setSelectedCompany(
                            option["id"] as string
                          );
                        }}
                        required
                      />

                      {staffOneStatusesStore.openedWindow == "edit" ? (
                        <ViewField
                          title="Объект"
                          value={selectedStatus.building_title}
                          disabled
                        />
                      ) : (
                        ""
                      )}
                    </div>

                    {(staffOneStatusesStore.selectedCompany === "all" &&
                      staffOneStatusesStore.openedWindow == "edit") ||
                    staffOneStatusesStore.selectedCompany !== "all" ? (
                      <StaffOneStatusesForm
                        staff_id={id}
                        selectedStatus={staffOneStatusesStore.selectedStatus}
                        company={staffOneStatusesStore.selectedCompany}
                        cols={staffOneStatusesStore.statusesCols}
                        boolCols={
                          staffOneStatusesStore.statusesGroupsForAdd[
                            staffOneStatusesStore.selectedStatus
                          ].bool_column
                        }
                        requiredCols={requiredFields}
                        setRequiredCols={setRequiredFields}
                        openedListName={openedListName}
                        changeOpenedWindows={changeOpenedWindows}
                        showRetentionBlock={
                          staffOneStatusesStore.openedWindow !== "edit"
                        }
                        params={
                          staffOneStatusesStore.statusesParams[
                            staffOneStatusesStore.selectedStatus
                          ]
                        }
                        isLoadingAllowEdu={
                          staffOneStatusesStore.isLoadingAllowEdu
                        }
                        retention={
                          staffOneStatusesStore.openedAllEventAllowEdu[id]
                        }
                        updateRetention={
                          staffOneStatusesStore.allUpdatedEventAllowEdu[id]
                        }
                        checkEventAllowEdu={
                          staffOneStatusesStore.updateEventAllowEdu
                        }
                        minDateForDefaultUser={minDateForDefaultUser}
                      />
                    ) : (
                      ""
                    )}
                  </>
                </FormWindow>
              );
            }}
          </Formik>
        </div>
      ) : null}
    </>
  );
};

export default observer(StaffOneStatusesWindow);
