import styles from "./formWindow.module.scss";
import { useEffect, useRef, useState } from "react";

import { useFormikContext } from "formik";

import FormWindowHeader from "./FormWindowHeader/index";
import ClarificationModal from "shared/ui/Modals/ClarificationModal";
import ButtonsGroupForSettings from "shared/ui/ButtonsGroup/ButtonsGroupForSettings";

import { Errors } from "stores/utils/types/ErrorsType";
import ErrorMessage from "shared/ui/ErrorMessage";
import Scrollbars from "react-custom-scrollbars-2";
import { useOnClickOutside } from "shared/utils/hooks/useOnClickOutside";
import LoadedComponent from "widgets/LoadedComponent";
import { maxHeight } from "shared/utils/constants/maxHeight";

type FormWindowProps = {
  title: string;
  children: JSX.Element;
  additionalChildren?: JSX.Element;
  setOpenWindow: (el: boolean) => void;

  subtitle?: string;
  isScroll?: boolean;
  optionalCloseFunc?: () => void;

  saveBtnTitle?: string;
  saveBtnOnClickFunc?: () => void;
  saveBtnDisabledValue?: boolean;

  addBtnTitle?: string;
  addBtnOnClickFunc?: () => void;
  addBtnImg?: JSX.Element;
  addBtnDisabledValue?: boolean;

  cancelBtnTitle?: string;
  cancelBtnDisabled?: boolean;
  cancelBtnOnClick?: () => void;
  cancelBtnImg?: JSX.Element;

  errors?: Partial<Errors["message"]>[];
  isLoadingForModal?: boolean;
  isNotHaveButtons?: boolean;
  ignorOnClickOutside?: boolean;
  fromOverlayWindow?: boolean;
  hasBackBtn?: boolean;
  modalTitle?: string;
  withoutSendData?: boolean;
};

const FormWindow = ({
  title,
  children,
  additionalChildren,
  setOpenWindow,

  subtitle,
  isScroll,

  optionalCloseFunc,

  saveBtnTitle,
  saveBtnOnClickFunc,
  saveBtnDisabledValue,

  addBtnTitle,
  addBtnOnClickFunc,
  addBtnImg,
  addBtnDisabledValue,

  cancelBtnTitle,
  cancelBtnOnClick,
  cancelBtnDisabled,
  cancelBtnImg,

  errors,
  isLoadingForModal,
  isNotHaveButtons,
  ignorOnClickOutside,
  fromOverlayWindow,

  hasBackBtn,
  withoutSendData
}: FormWindowProps) => {
  const [openModal, setOpenModal] = useState(false);
  const [isCloseByBackBtn, setIsCloseByBackBtn] = useState(false);
  const { dirty, handleSubmit, handleReset } =
    !withoutSendData && useFormikContext();
  const ref = useRef();

  const closeWindow = () => {
    if (!withoutSendData) {
      if (dirty) {
        setOpenModal(true);
      } else {
        setOpenWindow(false);
        optionalCloseFunc && optionalCloseFunc();
        handleReset();
      }
    } else {
      setOpenWindow(false);
      optionalCloseFunc && optionalCloseFunc();
    }
  };

  useOnClickOutside({
    ref,
    handler: (event) => {
      if (!ignorOnClickOutside) {
        // условие ниже не позволяет закрывать окно при клике на выпадающий список
        if (
          event.target?.["dataset"]["list"] === "true" ||
          event.target?.["dataset"]["type"] === "list-search" ||
          (typeof event.target?.["childNodes"]?.[0] === "string" &&
            event.target?.["childNodes"]?.[0]?.includes("itemsScrollBoard")) ||
          event.target?.["parentNode"]?.["dataset"]["list"] === "true"
        )
          return;

        if (
          event.target?.["firstChild"]?.["className"]?.length ||
          fromOverlayWindow
        ) {
          (event.target["firstChild"]?.["className"]?.includes("window") ||
            fromOverlayWindow) &&
            closeWindow();
        }
      }
    }
  });

  const onKeyDown = ({ key }: KeyboardEvent) => {
    if (key === "Escape") {
      closeWindow();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  });

  const getContent = () => {
    return (
      <>
        {children}
        {errors && Object.values(errors).length ? (
          <div className={styles.errors}>
            {Object.values(errors).map((error) => {
              return <ErrorMessage key={error.head} errors={error} />;
            })}
          </div>
        ) : null}
        {isNotHaveButtons ? (
          ""
        ) : (
          <div className={styles.buttons}>
            <ButtonsGroupForSettings
              saveBtnTitle={saveBtnTitle}
              saveBtnDisabled={saveBtnDisabledValue}
              saveBtnOnClick={() => {
                saveBtnOnClickFunc ? saveBtnOnClickFunc() : handleSubmit();
              }}
              addBtnTitle={addBtnTitle}
              addBtnOnClick={() => {
                if (dirty) {
                  setOpenModal(true);
                  hasBackBtn && setIsCloseByBackBtn(true);
                } else {
                  !withoutSendData && handleReset();

                  if (addBtnOnClickFunc && hasBackBtn)
                    return addBtnOnClickFunc();

                  setOpenWindow(false);
                  optionalCloseFunc && optionalCloseFunc();
                }
              }}
              addBtnDisabled={addBtnDisabledValue}
              addBtnImg={addBtnImg}
              cancelBtnTitle={cancelBtnTitle}
              cancelBtnDisabled={cancelBtnDisabled}
              cancelBtnOnClick={cancelBtnOnClick}
              cancelBtnImg={cancelBtnImg}
            />
          </div>
        )}
        {additionalChildren ? additionalChildren : null}
      </>
    );
  };

  return (
    <div className={styles.window} ref={ref}>
      <FormWindowHeader
        title={title}
        subtitle={subtitle}
        closeWindowFunc={!ignorOnClickOutside ? closeWindow : optionalCloseFunc}
      />
      {isScroll ? (
        <Scrollbars
          width="100%"
          autoHeight
          autoHeightMax={`calc(${maxHeight} - 160px)`}
          autoHide
          className={styles.body}
          autoHideTimeout={1000}
          autoHideDuration={200}
        >
          {getContent()}
        </Scrollbars>
      ) : (
        <div className={styles.body}>{getContent()}</div>
      )}
      <LoadedComponent isLoading={isLoadingForModal} />
      <ClarificationModal
        show={openModal}
        onHide={() => {
          setOpenModal(false);
        }}
        title={"Закрыть окно без сохранения данных?"}
        btnWithCrossTitle={"Закрыть"}
        btnWithCrossOnClick={() => {
          setOpenModal(false);
          !isCloseByBackBtn
            ? setOpenWindow(false)
            : addBtnOnClickFunc && addBtnOnClickFunc();
          addBtnTitle === "Отмена" && optionalCloseFunc && optionalCloseFunc();

          handleReset();
          setIsCloseByBackBtn(false);
        }}
        btnBlueOnClick={() => {
          setOpenModal(false);
        }}
        btnBlueTitle="Отмена"
        btnWithCrossBtnImg
      />
    </div>
  );
};

export default FormWindow;
