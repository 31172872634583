import styles from "./tooltip.module.scss";
import { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  hide,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useId,
  useInteractions,
  FloatingPortal,
  Placement,
  OffsetOptions
} from "@floating-ui/react";

import iconQuestionSmall from "shared/assets/images/mainIcons/iconQuestionSmall.svg";
import { classNames } from "shared/utils/helpers/classNames";

export type TooltipProps = {
  children?: JSX.Element;
  text: string | JSX.Element;
  placement?: Placement;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  withoutFlip?: boolean;
  offsetCustom?: OffsetOptions;
  className?: { container?: string; tooltip?: string; icon?: string };
};

const Tooltip = ({
  children,
  text,
  placement,
  color,
  backgroundColor,
  borderColor,
  withoutFlip,
  offsetCustom,
  className
}: TooltipProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // Настройки тултипа в строгом порядке, т.к. выполняются по порядку
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen, // Открыт ли тултип
    onOpenChange: setIsOpen, // Функция, изменяющая состояние тултипа
    placement: placement || "top", // Положение тултипа
    // whileElementsMounted - функция, которая вызывается при монтировании ссылочных и плавающих элементов
    // возвращает функцию очистки, вызываемую при их размонтировании.
    // autoUpdate - функция, которая гарантирует, что плавающий элемент остается привязанным к ссылочному элементу
    whileElementsMounted: autoUpdate,
    // промежуточные настройки, тоже выполняются по порядку
    middleware: [
      // Модификатор размещения, который перемещает плавающий элемент вдоль указанных осей.
      // Нужно для добавления расстояния между ссылкой и плавающим элементом
      offset(offsetCustom || 5),
      // Оптимизатор видимости, который меняет расположение плавающего элемента
      // чтобы он оставался в поле зрения с возможностью перевернуть его в любое место.
      !withoutFlip &&
        flip({
          // Разрешить ли возврат к противоположной оси, если ни одно размещение вдоль предпочтительной оси размещения не подходит,
          // и если да, то какое боковое направление вдоль этой оси выбрать. При необходимости он вернется в другое направление.
          // Возможные значения - start и end, для нас  выбран start
          // приоритет размещения плавающего элемента - 'right', 'left', 'top', 'bottom'
          fallbackAxisSideDirection: "start"
        }),
      // Оптимизатор видимости, который сдвигает плавающий элемент по указанным осям, чтобы он оставался в поле зрения.
      !withoutFlip && shift(),
      // Поставщик данных, который позволяет скрывать плавающий элемент в соответствующих ситуациях.
      hide()
    ]
  });

  // Слушатели событий для изменения состояния тултипа
  const hover = useHover(context, { move: false }); // слушатель событий наведения
  const focus = useFocus(context); // слушатель событий фокуса
  // слушатель закрытия/скрытия  тултипа
  const dismiss = useDismiss(context, {
    // параметр, позволяющий скрывать тултип при скролле без перемещения мыши
    ancestorScroll: true
  });
  // добавляет базовые реквизиты средства чтения с экрана к ссылке и плавающим элементам
  const role = useRole(context, { role: "tooltip" });
  const id = useId();

  // Объединяем всё в в геттеры, чтобы передать слушатели ссылке (children) и высплывающему сообщению
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        id={id}
        role="button"
        className={classNames("", {}, [className?.container])}
      >
        {children || (
          <img src={iconQuestionSmall} className={className?.icon} />
        )}
      </div>
      <FloatingPortal>
        {isOpen && text && (
          <div
            className={classNames(styles.tooltip, {}, [
              styles[`color_${color || "bw-gray7"}`],
              styles[`background_${backgroundColor || "bw-white"}`],
              styles[`border_${borderColor || color || "bw-gray5"}`],
              className?.tooltip
            ])}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
            id={`tooltip_${id}`}
          >
            {text}
          </div>
        )}
      </FloatingPortal>
    </>
  );
};

export default Tooltip;
